import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { domainReportSelector, fetchReport } from "slices/domainreport";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Link,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { TypeSummary } from "../common/type/TypeSummary";
import { DetailsCard } from "../common/type/DetailsCard";
import { PhishingTypeCard } from "../common/type/PhishingTypeCard";
import { TypeDetail } from "../common/type/TypeDetail";
import { TimeLine } from "../common/type/TimeLine";
import { useStyles } from "../common/sharedStyles";
import { ReportType } from "../../../models/report/reportType";
import { SubReportType } from "../../../models/report/type/SubReportType";
import { ParsedError, parseErrorTitle } from "../common/ParsedError";

import { useTranslation } from "react-i18next";

export type DomainReportProps = { domain: string };

export function DomainReport({ domain }: DomainReportProps) {
  const classes = useStyles();

  const [retry, setRetry] = useState(0);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [detailsCardRef, setDetailsCardRef] = useState(useRef().current);

  const [show, setRealShow] = useState(SubReportType.SUMMARY);
  const [showSubView, setShowSubView] = React.useState(null);
  const setShow = (show) => {
    setRealShow(show);
    navigate(location.pathname + "#" + show);
  };

  React.useEffect(() => {
    if (!location.hash) {
      setRealShow(SubReportType.SUMMARY);
    } else {
      // make sure that it only changes when valid e.g. user can't enter #FAKE
      setRealShow(
        Object.values<string>(SubReportType).includes(location.hash.substr(1))
          ? SubReportType[location.hash.substr(1)]
          : SubReportType.SUMMARY
      );
    }
  }, [location]);

  // initialize the redux hook
  const dispatch = useDispatch();

  const { responseData, reportType, error, quick } =
    useSelector(domainReportSelector);

  // will run on first mount
  useEffect(() => {
    dispatch(fetchReport(ReportType.DOMAIN, domain));
  }, [dispatch, domain, retry]);

  if (
    !error &&
    (!responseData || !reportType || reportType !== ReportType.DOMAIN)
  ) {
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  }

  if (show === SubReportType.SUMMARY)
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <DetailsCard
              type={responseData.type}
              value={responseData.value}
              data={responseData}
              setShow={setShow}
              setShowSubView={setShowSubView}
              ref={setDetailsCardRef}
              quick={quick}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TimeLine data={responseData} />
          </Grid>
        </Grid>
        <Card>
          <CardHeader title={t("Summary")} className={classes.ch} />
          <CardContent>
            <TypeSummary data={responseData} setShow={setShow} />
          </CardContent>
        </Card>
      </>
    );
  else
    return (
      <>
        <DetailsCard
          type={responseData.type}
          value={responseData.value}
          data={responseData}
          setShow={setShow}
          setShowSubView={setShowSubView}
          quick={quick}
        />
        <Card>
          <CardHeader
            title={
              <>
                <Link
                  className={classes.linkInHeader}
                  onClick={() => setShow(SubReportType.SUMMARY)}
                >
                  {t("Summary")}
                </Link>{" "}
                <ArrowForwardIosIcon /> {t("sys." + show.toUpperCase())}
              </>
            }
            className={classes.ch}
          />
          <CardContent>
            <TypeDetail
              data={responseData}
              show={show}
              showSubView={showSubView}
              setShowSubView={setShowSubView}
            />
            <PhishingTypeCard show={show} responseData={responseData} />
          </CardContent>
        </Card>
      </>
    );
}
