import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BorealisQueryJSON } from "components/beaver/helpers/ServerProperties";

export type borealisState = {
  query: string;
  modules: string;
  mode: string;
  report: any;
  error: object;
};

export const slice = createSlice({
  name: "borealis",
  initialState: {
    query: null,
    modules: null,
    mode: null,
    report: null,
    error: null,
  } as borealisState,
  reducers: {
    setQuery: (state, { payload }) => {
      state.query = payload;
    },
    setModules: (state, { payload }) => {
      state.modules = payload;
    },
    setMode: (state, { payload }) => {
      state.mode = payload;
    },
    setReport: (state, { payload }) => {
      state.report = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export const { setQuery, setModules } = slice.actions;

export default slice.reducer;

export const borealisSelector = (state) => state.borealis;

export const fetchBorealis =
  (query: string, modules: string, mode: string, setLoading: Function) =>
  // note you can use getState here to get the state e.g. for comparison purposes
  async (dispatch) => {
    // clear out the report and error so that you get the loading on the page
    dispatch(slice.actions.setReport(null));
    dispatch(slice.actions.setError(null));
    setLoading(true);

    // set the query / modules / mode in the redux state
    dispatch(slice.actions.setQuery(query));
    dispatch(slice.actions.setModules(modules));
    dispatch(slice.actions.setMode(mode));

    const user = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : {};

    axios
      .get(
        BorealisQueryJSON(
          encodeURIComponent(encodeURIComponent(query)),
          modules ? modules.split(",") : null,
          mode ? mode === "OFFLINE" : null,
        ),
        {
          auth:
            user && user.server && !user.server.includes("localhost")
              ? {
                  username: user ? user.username : null,
                  password: user
                    ? user.onetime
                      ? window
                          // eslint-disable-next-line no-eval
                          .atob(eval(window.atob(user.onetime)))
                          .substr(13)
                      : null
                    : null,
                }
              : null,
        }
      )
      .then(
        (resp) => {
          dispatch(
            slice.actions.setReport({
              ...resp.data,
              requestURL: resp.request.responseURL,
            })
          );
          setLoading(false);
        },
        (e) => {
          console.log(e);
          dispatch(
            slice.actions.setError(
              e.response
                ? JSON.stringify(e.response)
                : JSON.stringify({
                    status: e.message.match(/\d+/g)
                      ? parseInt(e.message.match(/\d+/g)[0])
                      : 500,
                    message: e.message,
                    data: JSON.stringify(e),
                  })
            )
          );
          setLoading(false);
        }
      )
      .catch((e) => {
        console.log(e);
        dispatch(
          slice.actions.setError(
            e.response
              ? JSON.stringify(e.response)
              : JSON.stringify({
                  status: e.message.match(/\d+/g)
                    ? parseInt(e.message.match(/\d+/g)[0])
                    : 500,
                  message: e.message,
                  data: JSON.stringify(e),
                })
          )
        );
        setLoading(false);
      });
  };
