import React from "react";
import Axios from "axios";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { SimpleStats } from "components/beaver/helpers/ServerProperties";
import { prettifyDateTime } from "components/beaver/helpers/DateHelper";

const user = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser"))
  : {};

export function BeaverStats({ setError }: { setError?: Function }) {
  const [stats, setStats] = React.useState(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    Axios.get(SimpleStats(), {
      auth:
        user && user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    })
      .then((res) => setStats(res.data))
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          "not_in_beaver_group" === err.response.data.error
        ) {
          // if this happens, redirect to the not in group page
          if (setError) {
            setError(JSON.stringify(err.response));
          }
        } else {
          // if there's no response, then we have a 500 error so send the message
          setError(
            JSON.stringify({
              status: err.message.match(/\d+/g)
                ? parseInt(err.message.match(/\d+/g)[0])
                : 500,
              message: err.message,
              data: JSON.stringify(err),
            })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!stats || !stats.sampleTotal) return null;

  return (
    <>
      <Paper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={5}>
                  {t("Statistics")} ({t("last update")}:{" "}
                  {moment(stats.day, "YYYYMMDD").format("LL")} )
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t("Samples")}:</TableCell>
                <TableCell>
                  <NumericFormat
                    value={stats.sampleTotal}
                    thousandSeparator={true}
                    displayType="text"
                  />
                </TableCell>
                <TableCell>{t("DYNAMIC")}</TableCell>
                <TableCell>{t("STATIC")}</TableCell>
                <TableCell>{t("SIGNATURE")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Samples uniques")}:</TableCell>
                <TableCell>
                  <NumericFormat
                    value={stats.sampleTotalUnique}
                    thousandSeparator={true}
                    displayType="text"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    value={stats.DYNAMIC.sampleTotalUnique}
                    thousandSeparator={true}
                    displayType="text"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    value={stats.STATIC.sampleTotalUnique}
                    thousandSeparator={true}
                    displayType="text"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    value={stats.SIGNATURE.sampleTotalUnique}
                    thousandSeparator={true}
                    displayType="text"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Samples analyzed")}:</TableCell>
                <TableCell>
                  <NumericFormat
                    value={stats.sampleTotalAnalyzed}
                    thousandSeparator={true}
                    displayType="text"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    value={stats.DYNAMIC.sampleTotalAnalyzed}
                    thousandSeparator={true}
                    displayType="text"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    value={stats.STATIC.sampleTotalAnalyzed}
                    thousandSeparator={true}
                    displayType="text"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    value={stats.SIGNATURE.sampleTotalAnalyzed}
                    thousandSeparator={true}
                    displayType="text"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box style={{ fontSize: "0.875rem", marginTop: "2rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
        {stats.appVersion && t("Beaver Backend version ") + stats.appVersion}
        {stats.buildDate && t(" built on ") + prettifyDateTime(stats.buildDate)}
      </Box>
    </>
  );
}
