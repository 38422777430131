import { isExternal, isGeekweek } from "components/beaver/helpers/ServerProperties";
import { Trans } from "react-i18next";

// In the definitions below, public defines instances accessed by external customers (EXT, GWK)
// Restricted defines instances being restricted to CCCS/CSE employees (INT, LAB)
export const ACCESS_REQUEST_EMAIL_PUBLIC = "contact@cyber.gc.ca";
export const ACCESS_REQUEST_EMAIL_RESTRICTED = "innovation@cyber.gc.ca";
export const TECHNICAL_ISSUE_EMAIL_PUBLIC = "analytics.ci@cyber.gc.ca";
export const TECHNICAL_ISSUE_EMAIL_RESTRICTED = "analytics.ci@cyber.gc.ca";
export const COMPLIANCE_ISSUE_EMAIL_PUBLIC = "contact@cyber.gc.ca";
export const COMPLIANCE_ISSUE_EMAIL_RESTRICTED = "innovation@cyber.gc.ca";
  
export function getAccessRequestEmail() {
    if (isExternal() || isGeekweek()) {
        return ACCESS_REQUEST_EMAIL_PUBLIC;
    }

    return ACCESS_REQUEST_EMAIL_RESTRICTED;
}

export function getTechnicalIssueEmail() {
    if (isExternal() || isGeekweek()) {
        return TECHNICAL_ISSUE_EMAIL_PUBLIC;
    }

    return TECHNICAL_ISSUE_EMAIL_RESTRICTED;
}

export function getComplianceIssueEmail() {
    if (isExternal() || isGeekweek()) {
        return COMPLIANCE_ISSUE_EMAIL_PUBLIC;
    }

    return COMPLIANCE_ISSUE_EMAIL_RESTRICTED;
}

export type TransWithEmailProps = {
    i18nKey: string;
  };
export function TransWithEmail({
    i18nKey
  }: TransWithEmailProps) {

    return (
        <Trans 
            i18nKey={i18nKey}
            values={{
              "access-request-email": getAccessRequestEmail(),
              "technical-issue-email": getTechnicalIssueEmail(),
              "compliance-issue-email": getComplianceIssueEmail(),
            }}
            components={{
              "access-request-email": <a href={"mailto:" + getAccessRequestEmail()}>{getAccessRequestEmail()}</a>,
              "technical-issue-email": <a href={"mailto:" + getTechnicalIssueEmail()}>{getTechnicalIssueEmail()}</a>,
              "compliance-issue-email": <a href={"mailto:" + getComplianceIssueEmail()}>{getComplianceIssueEmail()}</a>,
            }}
        />
    )
}