import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Link, Typography, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.dark,
  },
  canada: {
    color: "white",
    backgroundColor: "black",
    padding: "8px 16px",
    width: "100%",
  },
  bottomLinks: {
    paddingRight: theme.spacing(3),
    color: "white",
    display: "inline-block",
  },
  bottomLinks2: {
    color: "white",
    display: "inline-block",
  },
  footImg: {
    filter: "brightness(0) invert(100%)",
    height: "2em",
    verticalAlign: "middle",
  },
  rowList: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
}));

// function ListItemLink(props: any) {
//   return <ListItem button component="a" {...props} />;
// }

export function Footer() {
  const { t } = useTranslation();
  const classes = useStyles();

  const matches = useMediaQuery("(max-width:496px)");

  return (
    <>
      <Box
        className={classes.canada}
        style={
          !localStorage.currentUser || localStorage.currentUser === "null"
            ? { bottom: "0" }
            : null
        }
      >
        <Grid
          container
          justifyContent={matches ? "center" : "space-between"}
          alignItems="center"
        >
          <Grid item>
            <Link underline="none" href={t("footer.link.contactinfo")}>
              <Typography variant="button" className={classes.bottomLinks}>
                {t("footer.text.help")}
              </Typography>
            </Link>
            <Link underline="none" href={t("footer.link.terms")}>
              <Typography variant="button" className={classes.bottomLinks}>
                {t("footer.text.terms")}
              </Typography>
            </Link>
            <Link underline="none" href={t("footer.link.transparancy")}>
              <Typography variant="button" className={classes.bottomLinks2}>
                {t("footer.text.privacy")}
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link underline="none" href={t("footer.link.canada")}>
              <Box textAlign={matches ? "center" : "right"}>
                <img
                  alt="Canada"
                  src="/ng/images/wmms.svg"
                  className={classes.footImg}
                />
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
