import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import { hashModel2 } from "../../../../models/report/md5/hashModel";

import { useStyles } from "../../common/sharedStyles";

import { useTranslation } from "react-i18next";

import { ClipboardValue } from "components/beaver/components/report/common/ClipboardButton";

export type HashVeiwProps = { hashReport: hashModel2 };

export function HashView2({ hashReport }: HashVeiwProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const ssdeepParts =
    hashReport?.fileInfo && hashReport.fileInfo.ssdeep
      ? hashReport.fileInfo.ssdeep.split(":")
      : [];

  return (
    <Box>
      <Card>
        <CardHeader className={classes.ch} title={t("Hash Report")} />
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{t("Analysis Date")}</TableCell>
                  <TableCell>{hashReport?.creationDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Report Version")}</TableCell>
                  <TableCell>{hashReport?.version}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>MD5</TableCell>
                  <TableCell>
                    <ClipboardValue value={hashReport?.md5} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SHA1</TableCell>
                  <TableCell>
                    <ClipboardValue
                      value={
                        hashReport?.fileInfo
                          ? hashReport?.fileInfo.sha1
                          : hashReport?.sha1
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SHA256</TableCell>
                  <TableCell>
                    <ClipboardValue
                      value={
                        hashReport?.fileInfo
                          ? hashReport?.fileInfo.sha256
                          : hashReport?.sha256
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("SSDEEP Block Size")}</TableCell>
                  <TableCell>
                    {ssdeepParts.length
                      ? ssdeepParts[0]
                      : hashReport?.ssdeep_blocksize
                      ? hashReport?.ssdeep_blocksize
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("SSDEEP Hash 1")}</TableCell>
                  <TableCell>
                    {ssdeepParts.length > 1
                      ? ssdeepParts[1]
                      : hashReport?.ssdeep_hash1
                      ? hashReport?.ssdeep_hash1
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("SSDEEP Hash 2")}</TableCell>
                  <TableCell>
                    {ssdeepParts.length > 2
                      ? ssdeepParts[2]
                      : hashReport?.ssdeep_hash2
                      ? hashReport?.ssdeep_hash2
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("File Size")}</TableCell>
                  <TableCell>
                    {hashReport?.fileInfo
                      ? hashReport?.fileInfo.size
                      : hashReport?.file_size}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Result")}</TableCell>
                  <TableCell>{hashReport?.result}</TableCell>
                </TableRow>
                {hashReport?.messages && hashReport.messages.length ? (
                  <TableRow>
                    <TableCell>{t("Message")}</TableCell>
                    <TableCell>
                      {hashReport?.messages[0].type +
                        " " +
                        hashReport?.messages[0].message}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
}
