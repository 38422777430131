import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  useTheme,
  Link,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  XYPlot,
  Highlight,
  HorizontalGridLines,
  MarkSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
} from "react-vis";
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import { TypeReportData } from "../../../../models/report/type/TypeReportData";
import { SimpleTableCardView } from "../SimpleTableView";
import { getTypeDetailWidth, showInTable, renderField, preProcessTableData } from "./TypeDetail";

import { useStyles } from "../sharedStyles";

import { useTranslation } from "react-i18next";
import moment from "moment";
import { Close } from "@material-ui/icons";
import { HelpButton } from "components/beaver/components/report/common/HelpButton";
import {
  TimeLineHelp,
  TimeLineHelpFr,
  TimeLineHelpTitle,
} from "components/beaver/help/TimeLineHelp";

export type TimeLineProps = {
  data: TypeReportData;
  test?: boolean;
};

export interface ModalData {
  date: string;
  type: string;
}

export function TimeLineModalDataView({
  data,
  modalData,
}: {
  data: TypeReportData;
  modalData: ModalData;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const [filteredData, setFilteredData] = React.useState<any[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    setFilteredData(
      // get the actual data.  inside the data.subreports[modalData.type].reports  there are multiple data entries
      // inside those entries, match date, then ensure there's no empty data sets (the last filter)
      data.subreports[modalData.type].reports
        .map((r) => ({
          type: r.type,
          typeName: r.typeName,
          data: r.data.filter(
            (d) =>
              // There are multiple varialbes that can have the date based on @Timeline in backend
              d?.addedOn?.includes(modalData.date) ||
              d?.addedOn === modalData.date ||
              d?.receivedDate === modalData.date ||
              d?.createdDate === modalData.date ||
              d?.date === modalData.date ||
              d?.addedDate === modalData.date ||
              d?.addedOn === modalData.date ||
              d?.receivedOn === modalData.date ||
              d?.notifiedOn === modalData.date ||
              // GEOLOCATION Report uses databaseDate see #662
              ("" + d?.databaseDate).replaceAll("-", "") === modalData.date
          ),
        }))
        .filter((r) => r?.data?.length > 0)
    );
  }, [data, modalData]);

  return (
    <>
      <p>
        Data for{" "}
        <b>
          <Link
            style={{ cursor: "pointer", color: theme.palette.primary.main }}
            onClick={() => navigate(location.pathname + "#" + modalData.type)}
          >
            {t("sys." + modalData.type)}
          </Link>
        </b>{" "}
        on {modalData.date}:
      </p>
      {filteredData && filteredData.length
        ? filteredData.map((f, i) => (
            <Box key={i} mb={2}>
              <SimpleTableCardView
                title={t(f.typeName)}
                headers={Object.keys(f.data[0])
                  .filter((field) => showInTable(field, f.typeName))
                  .filter(
                    (field) =>
                      !(
                        f.typeName === "Cyber Threat Infrastructure (CTI)" &&
                        field === "notificationDate"
                      )
                  )
                  .map((h, j) => {
                    return {
                      title: t(_.startCase(h)).replace(/M(d|D) 5/, "MD5"),
                      field: h,
                      render: (rowData) =>
                        renderField(
                          h,
                          rowData,
                          classes,
                          t,
                          true,
                          () => {},
                          modalData.date
                        ),
                      width: getTypeDetailWidth(h),
                    };
                  })}
                data={preProcessTableData(f.data)}
              />
            </Box>
          ))
        : null}
    </>
  );
}

export function TimeLine({
  data,
  test,
}: TimeLineProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [timelineData, setTimelineData] = useState(null);
  const [timeline, setTimeline] = useState(null);
  const [filteredTimeline, setFilteredTimeline] = useState(null);
  const [area, setArea] = useState({ left: 0, right: 0 });
  const [state, setState] = useState({ value: false });
  const [touchXVals, setTouchXVals] = useState([]);
  const [showModal, setShowModal] = React.useState(-1);
  const [modalData, setModalData] = React.useState<ModalData>(null);

  React.useEffect(() => {
    let timelineResult = getTimeLineData(data);
    setTimelineData(timelineResult);
    setTimeline(timelineResult.timeline);
    setFilteredTimeline(timelineResult.timeline);
  }, [data]);

  const filter = (area) => {
    setFilteredTimeline(
      timeline.filter((val) => val.x > area.left && val.x < area.right)
    );
    setArea({ left: 0, right: 0 });
  };
  // if there is no timeline, then show nothing
  // TODO - should something be shown instead?
  if (!getSystemsWithTimeline(data).length) return null;
  else if (!timeline) return null;
  else
    return (
      <>
      <Modal open={showModal >= 0} onClose={() => setShowModal(-1)}>
        <Paper
          style={{
            overflow: "auto",
            maxHeight: "80vh",
            width: "80vw",
            display: "block",
            margin: "auto",
            marginTop: "7vh",
          }}
        >
          <Box m={2}>
            <Box
              style={{
                float: "right",
                cursor: "pointer",
              }}
              onClick={() => setShowModal(-1)}
            >
              <Close />
            </Box>
            <Box mb={2}>
              <Typography variant="h5">{t("Timeline Details")}</Typography>
            </Box>
            <TimeLineModalDataView data={data} modalData={modalData} />
          </Box>
        </Paper>
      </Modal>
      <Box style={{ height: "100%", minHeight: "400px" }}>
        <Grid container spacing={2} style={{ height: "100%" }}>
          <Grid item style={{ height: "100%", width: "100%" }}>
            <Box mb={2} style={{ height: "100%" }}>
              <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <CardHeader
                  title={t("Timeline")}
                  className={classes.ch}
                  action={
                    <div style={{marginTop: "8px", marginRight: "8px"}}>
                      <HelpButton
                        help={<TimeLineHelp />}
                        help_fr={<TimeLineHelpFr />}
                        title={TimeLineHelpTitle}
                      />
                    </div>
                  }
                />
                <CardContent style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}>
                  <Box mb={2} ml={1} style={{ flex: "1 1 auto", margin: "0" }}>
                  {test ? (
                    <p>No Graph in tests :</p>
                  ) : (
                    <AutoSizer>
                      {({ width, height }) => (<XYPlot
                        height={height} width={width}
                        margin={{ left: 70 }}
                        xType="time"
                        colorType="literal"
                        onMouseLeave={() => setState({ value: false })}
                        onTouchEnd={(e) => {
                          e.preventDefault();
                        
                          if (timelineData.disableZoom) return;
                        
                          // have to verify that it's not just one and not all same val.
                          if (new Set(touchXVals).size > 1) {
                            filter({
                              left: touchXVals.sort(
                                (a, b) => a.getTime() - b.getTime()
                              )[0],
                              right: touchXVals.sort(
                                (a, b) => a.getTime() - b.getTime()
                              )[touchXVals.length - 1],
                            });
                          }
                          setTouchXVals([]);
                        }}
                      >
                        <VerticalGridLines style={{ stroke: "#B7E9ED" }} />
                        <HorizontalGridLines style={{ stroke: "#B7E9ED" }} />
                        {/* x axis ticks formatting */}
                        <XAxis
                          tickPadding={0}
                          tickLabelAngle={-45}
                          style={{
                            fill: theme.palette.text.primary,
                          }}
                        />
                        {/* The y axis ticks formatting */}
                        <YAxis
                          top={-5}
                          tickFormat={(v) =>
                            v === Math.floor(v)
                              ? t(
                                  "sysshort." +
                                    getSystemsWithTimeline(data)[Math.floor(v)]
                                )
                              : ""
                          }
                          style={{
                            fill: theme.palette.text.primary,
                          }}
                        />
                        {/* This shows the actual data points */}
                        <MarkSeries
                          onNearestXY={(value) => {
                            setState({ value });
                            setTouchXVals(touchXVals.concat([value.x]));
                          }}
                          data={filteredTimeline}
                        />
                        {/* following two add the top and bottom lines */}
                        <MarkSeries
                          data={[
                            {
                              x: filteredTimeline[0]
                                ? filteredTimeline[0].x
                                : area.left,
                              y: 0,
                            },
                          ]}
                          style={{ display: "none" }}
                        />
                        <MarkSeries
                          data={[
                            {
                              x: filteredTimeline[0]
                                ? filteredTimeline[0].x
                                : area.left,
                              y: getSystemsWithTimeline(data).length,
                            },
                          ]}
                          style={{ display: "none" }}
                        />
                        {/* This does the zooming in and clicking on points */}
                        {!timelineData.disableZoom && (
                          <Highlight
                            enableY={false}
                            onBrushEnd={(a) => {
                              if (
                                a &&
                                a.left &&
                                a.right &&
                                a.right - a.left > 1 &&
                                a.right > 0
                              ) {
                                setArea(a);
                                filter(a);
                              } else {
                                // it wasn't a filter event, it's a click event so show a dialogue box with details.
                                setModalData({
                                  date: state.value["x"]
                                    .toISOString()
                                    .replaceAll("-", "")
                                    .split("T")[0],
                                  type: getSystemsWithTimeline(data)[
                                    Math.floor(state.value["y"])
                                  ].toUpperCase(),
                                });
                                setShowModal(1);
                                setArea({ left: 0, right: 0 });
                              }
                            }}
                          />
                        )}
                      </XYPlot>
                    )}
                    </AutoSizer>
                  )
                }
                </Box>
                <Box>
                  {!timelineData.disableZoom && (
                    <>
                      <Button
                        disabled={timeline.length === filteredTimeline.length}
                        onClick={() => setFilteredTimeline(timeline)}
                      >
                        {t("Reset Zoom")}
                      </Button>

                      <Box
                        mr={1}
                        component="span"
                        color="text.secondary"
                        hidden={timeline.length !== filteredTimeline.length}
                      >
                        {t("Highlight Graph Area to Zoom In")}
                      </Box>
                    </>
                  )}
                  {state.value ? (
                    <Box component="span">
                      {state.value["x"].toDateString() +
                        " - " +
                        t(
                          "sys." +
                            getSystemsWithTimeline(data)[
                              Math.floor(state.value["y"])
                            ].toUpperCase()
                        )}
                    </Box>
                  ) : null}
                </Box>
              </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
      </>
    );
}

// this is a random number generator
// TODO - Move this to a helper library if / when it is used one more time (very unlikely)
function mulberry32(a) {
  return function () {
    var t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

// NOTE:  the popup is easy enough (react-vis Hint), but posititioning it somewhere reasonable is hard - instead, just show the value when close
// being done for now until a better way to display the timeline can be determined
export function getTimeLineData(data: TypeReportData) {
  let timeline = [];
  let timelineMin: Date = new Date("9999-09-09");
  let timelineMax: Date = new Date("1900-01-01");
  for (let system of data.allowedSystems) {
    // NOTE:  the Math.random() will make the blob look bigger if there are multiple close by
    if (data.subreports[system]) {
      for (var i = 0; i < data.subreports[system].timeline.length; i++) {
        let t = data.subreports[system].timeline[i];
        // using date parsing was causing the date to be off by one day based on timezone, so using moment
        let tdate = moment(t.date, "YYYYMMDD").toDate();
        timeline.push({
          x: tdate,
          y: getSystemsWithTimeline(data).indexOf(system) + mulberry32(i)(),
          color: t.color,
        });
        if (tdate > timelineMax) timelineMax = tdate;
        if (tdate < timelineMin) timelineMin = tdate;
      }
    }
  }

  // if there is only one date, then show +/- 5 days
  if (timelineMin === timelineMax) {
    timeline.push(
      {
        x: moment(timelineMin).add(-5, "days").toDate(),
        y: 0,
        color: "white",
      },
      {
        x: moment(timelineMax).add(5, "days").toDate(),
        y: 0,
        color: "white",
      }
    );
  }

  // debugger;
  return { timeline: timeline, disableZoom: timelineMin === timelineMax };
}

export function getSystemsWithTimeline(data: TypeReportData) {
  return data.allowedSystems;
}
