import { Box, useTheme } from "@material-ui/core";
import WarningOutlined from "@material-ui/icons/WarningOutlined";

export function ErrorLabel({
  text,
}: {
  text?: string;
}) {
  const theme = useTheme();

  return text ? (
    <>
    <Box mb={1} style={{ color: theme.palette.error.main }}>
          <Box>
            <WarningOutlined style={{ verticalAlign: "middle" }}/>{" "}{text}
          </Box>
        </Box>
    </>
  ) : null;
}
