import { useTranslation } from "react-i18next";

import { Box, Typography } from "@material-ui/core";

import useAppLayout from "../../commons/components/hooks/useAppLayout";
import CardCentered from "../../commons/components/layout/pages/CardCentered";

function Logout() {
  const { t } = useTranslation();
  const { hideMenus } = useAppLayout();

  hideMenus();

  const wasSSOLogin = localStorage.getItem("ssoPrefix");

  // There is probably more stuff to be done to properly logout a user...
  setTimeout(() => {
    // have to store it first, you will be making the current user null right after.
    localStorage.removeItem("currentUser");
    localStorage.removeItem("ssoPrefix");

    if (wasSSOLogin) {
      console.log("SSO LOGIN LOCATION: "+wasSSOLogin);
      if (wasSSOLogin.includes("lauth"))
        window.location.replace("/lauth/logout");
      else
        window.location.replace("/auth/logout");
    } else window.location.replace("/ng");
  }, 2000);

  return (
    <CardCentered>
      <Box textAlign="center">
        <Box
          my={"3rem"}
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <img
            alt=""
            src="/ng/images/beaver_banner.png"
            style={{ width: "130%", maxWidth: "100vw" }}
          />
        </Box>
        <Typography>{t("page.logout")}</Typography>
      </Box>
    </CardCentered>
  );
}

export default Logout;
