import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  LinearProgress,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
} from "@material-ui/core";
import StarRateOutlinedIcon from "@material-ui/icons/StarRateOutlined";
// import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import SubdirectoryArrowRightOutlinedIcon from "@material-ui/icons/SubdirectoryArrowRightOutlined";

import {
  SearchResult,
  SearchResultsResponse,
} from "../../models/search/SearchResult";
import { WarningOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    result: {
      backgroundColor: "#f0fff0",
    },
    notexists: {
      backgroundColor: "#fff0f0",
    },
    grey: {
      backgroundColor: "lightgrey",
    },
    bt: {
      color: "black !important",
    },
    trunctext: {
      maxWidth: "27em",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    generated: {},
  })
);

//TODO - instead of checkmarks put the logos greyed out or not, etc. since checkmarks take too much space

type PriorityProps = {
  priority: boolean;
};

function Priority({ priority }: PriorityProps) {
  if (priority) return <StarRateOutlinedIcon />;
  return null;
}

// type SystemWithDataProps = { type: string; data: string[] };

// function SystemWithData({ type, data }: SystemWithDataProps) {
//   if (data && data.includes(type)) return <CheckOutlinedIcon />;
//   return null;
// }

type GeneratedProps = { generated: boolean };

function Generated({ generated }: GeneratedProps) {
  if (generated) return <SubdirectoryArrowRightOutlinedIcon />;
  return null;
}

function isGreen(item: SearchResult) {
  // logically, you would assume that the item.exists determines if the item should be colored green, but this would be incorrect.
  // it seems to be determined that it is green only if it is not 0 results and no systems
  if (
    item.systemsWithData &&
    item.systemsWithData.length === 0 &&
    item.searchResult === "0" &&
    !item.exists
  )
    return false;
  return true;
}

type SearchResultsProps = {
  searching: boolean;
  responsedata: SearchResultsResponse;
};

export function SearchResults({ searching, responsedata }: SearchResultsProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  // there is a message property, errorMessages, and result
  //    the result has exists, generated, inPriority, reportURL, searchResult, searchTerm(value, type), sortNumber, subType, systemsWithData ['DFS', BEAVER, etc.], typeTitle, wildcard
  //    the exists makes it green for sure, generated is with the arrow from the first one, in Piroirity is the star, the url is where to find it (link), typeTitle e.g. domain, etc.
  //    search.html line 55 shows results.
  // TODO - handle the message and errorMessage!
  if (searching) {
    return (
      <>
        <p style={{ textAlign: "center" }}>{t("beaver.search.results.waiting")}</p>
        <LinearProgress />
      </>
    );
  } else if (responsedata) {
    // generate the results in a for loop
    // a result exists
    const results = responsedata.result.map((item, key) => {
      //TODO - hover over the value shows the full value (esp. if truncated e.g. sha256)
      // for classname, it is adding the tag only if it is true, then removing (fitlering) the falses, then joining with spaces
      //TODO - make this view look better / take up less space esp. for mobile view
      return (
        <TableRow
          key={key}
          className={clsx(classes.result, {
            [classes.notexists]:
              item.systemsWithData !== null && !isGreen(item),
            [classes.grey]: item.systemsWithData === null,
            [classes.generated]: item.generated,
          })}
        >
          <TableCell className={classes.bt}>{item.typeTitle}</TableCell>
          <TableCell className={clsx(classes.trunctext, classes.bt)}>
            <Generated generated={item.generated} />
            {["asn"].includes(item.typeTitle) ? (
              item.searchTerm.value
            ) : item.exists || parseInt(item.searchResult) > 0 ? (
              <Link to={item.reportURL}>{item.searchTerm.value}</Link>
            ) : (
              item.searchTerm.value
            )}
          </TableCell>
          <TableCell className={classes.bt}>
            <Priority priority={item.inPriority} />
            {!item.searchResult && (
              <CircularProgress size="1rem" thickness={8} />
            )}
          </TableCell>
          {/* <TableCell className={classes.bt}>
            <SystemWithData data={item.systemsWithData} type="DFS" />
          </TableCell>
          <TableCell className={classes.bt}>
            <SystemWithData data={item.systemsWithData} type="EFS" />
          </TableCell>
          <TableCell className={classes.bt}>
            <SystemWithData data={item.systemsWithData} type="BEAVER" />
          </TableCell>
          <TableCell className={classes.bt}>
            <SystemWithData data={item.systemsWithData} type="BISON" />
          </TableCell>
          <TableCell className={classes.bt}>
            <SystemWithData data={item.systemsWithData} type="MOOSE" />
          </TableCell>
          <TableCell className={classes.bt}>
            <SystemWithData data={item.systemsWithData} type="RACOON" />
          </TableCell> */}
        </TableRow>
      );
    });

    return (
      <>
        {!!responsedata?.errorMessages.length && (
          <Paper>
            <Box p={1} mb={2}>
              {responsedata.errorMessages.map((m, i) => (
                <Box key={i} m={2}>
                  <WarningOutlined />{" "}
                  {m.includes("cannot be searched") ? (
                    <>
                      {t("largesearchresultfront")}
                      {/* can only be a domain or ip, so check if only dots andnumbers*/}
                      <Link
                        to={[
                          m.split(" ")[0].match(/^[0-9.]+$/)
                            ? "/ip"
                            : "/domain",
                          m.split(" ")[0],
                          "report",
                        ].join("/")}
                      >
                        {m.split(" ")[0]}
                      </Link>{" "}
                      {t("largesearchresultback")}
                    </>
                  ) : (
                    { m }
                  )}
                </Box>
              ))}
            </Box>
          </Paper>
        )}
        <TableContainer component={Paper}>
          {!!responsedata?.result.length && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t("beaver.search.results.type")}</TableCell>
                  <TableCell>{t("beaver.search.results.value")}</TableCell>
                  <TableCell>
                    <Tooltip
                      arrow
                      placement="top"
                      title={t("beaver.search.results.in_priority_explain")}
                    >
                      <Box>{t("beaver.search.results.in_priority")}</Box>
                    </Tooltip>
                  </TableCell>
                  {/* <TableCell>{t("sys.DFS")}</TableCell>
              <TableCell>{t("sys.EFS")}</TableCell>
              <TableCell>{t("sys.BEAVER")}</TableCell>
              <TableCell>{t("sys.BISON")}</TableCell>
              <TableCell>{t("sys.MOOSE")}</TableCell>
              <TableCell>{t("sys.RACCOON")}</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>{results}</TableBody>
            </Table>
          )}
        </TableContainer>
      </>
    );
  } else return null;
}
