import { useTheme, IconButton, Tooltip, Button } from "@material-ui/core";
import FilterHdrOutlinedIcon from "@material-ui/icons/FilterHdrOutlined";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function LinkWrap({
  children,
  beaverTooltipText,
  noBeaverLink,
  noBorealisLink,
}: {
  children: any;
  // you can override the tooltip text for "Pivot in Beaver"
  beaverTooltipText?: string;
  noBeaverLink?: boolean;
  noBorealisLink?: boolean;
}) {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <span
      style={
        !noBeaverLink
          ? {
              color: theme.palette.primary.main,
              textDecoration: "underline",
            }
          : null
      }
    >
      {!noBeaverLink &&
      !!children &&
      ["string", "number"].includes(typeof children) ? (
        <Tooltip
          title={t(beaverTooltipText ? beaverTooltipText : "Pivot in BeAVER")}
          arrow
        >
          <Button
            color="primary"
            size="small"
            style={{
              textTransform: "inherit",
              display: "inline",
              fontSize: "inherit",
              padding: 0,
              margin: 0,
              minWidth: 0,
              letterSpacing: "inherit",
              justifyContent: "inherit",
              textAlign: "inherit",
            }}
          >
            {children}
          </Button>
        </Tooltip>
      ) : (
        children
      )}

      {/* There are instances when you defintely don't want the borealis button,
        e.g. if you know it's a tag and tag's can have a dot in it which could be 
        interpreted as a domain */}
      {!noBorealisLink && <BorealisButton>{children}</BorealisButton>}
    </span>
  );
}

// from indicatorvalidate
const borealisRegexes = {
  ip: /^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:(?:\.)|(?:\[\.\]))){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$/,
  domain:
    // eslint-disable-next-line no-useless-escape
    /^((?!-|\.)(([a-zA-Z0-9\*\.\-_])|(\[\.\]))*)((\.)|(\[\.\]))((xn\-\-.{4,20})|([a-zA-Z\*]{1,10}))$/,
  // eslint-disable-next-line no-useless-escape
  url: /^((?:(?:(?:h[txX]|f)[txX]ps?(?:\:\/\/)?)(?:[\w\d]+(?:\:[\w\d]+)?\@)?((?:(?:[^.-](?:(?:[a-zA-Z0-9\.\-_])|(?:\[\.\]))*)(?:(?:\.)|(?:\[\.\]))(?:(?:xn\-\-.{4,20})|(?:[a-zA-Z]{1,10})))|(?:(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:(?:\.)|(?:\[\.\]))){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))(?:\:[\d]{1,5})?(\/[\_\<\>\,\!\|\"\'\:\~\/\w\.\-\?&#=;\[\]%\\\@\)\(\\*+]*)?))$/,
};

export function BorealisButton({
  children,
  iconStyle,
}: {
  children: any;
  iconStyle?: object;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // verify location doesn't start with borealis (not in borealis mode) and then
  // verify that the type is url, ip or domain, and ONLY then show borealis button

  // TODO - add collobration here when ready

  return (
    !!localStorage.getItem("currentUser") &&
    !!JSON.parse(localStorage.getItem("currentUser"))["canSeeBorealis"] &&
    (("" + children).match(borealisRegexes.ip) ||
      ("" + children).match(borealisRegexes.domain) ||
      ("" + children).match(borealisRegexes.url)) && (
      <Tooltip title={t("Pivot in Borealis")} arrow>
        <IconButton
          style={iconStyle}
          size="small"
          color="primary"
          onClick={(e) => {
            // stop the parent from taking over
            e.preventDefault();
            navigate("/borealisReport?query=" + children);
          }}
          // also need the rightclick 'open in new tab'
          // unfortunately, using href will cause warnings in console :(
          onContextMenu={(e) => {
            e.preventDefault();
            window.open("/borealisReport?query=" + children, "_blank");
          }}
        >
          <FilterHdrOutlinedIcon style={iconStyle} />
        </IconButton>
      </Tooltip>
    )
  );
}
