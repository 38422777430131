import { forwardRef, useState } from "react";
import ExportCsv from "@material-table/exporters/csv";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@material-ui/core";
import MaterialTable, { Icons, MTableToolbar } from "@material-table/core";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  OpenInNew,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { useStyles } from "./sharedStyles";
import { HelpToolTip } from "./HelpButton";
import { ErrorLabel } from "./ErrorLabel";

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export interface HeaderType {
  title?: string;
  field?: string;
  icon?: string;
  customFilterAndSearch?: any;
  optional?: boolean;
  enabled?: boolean;
  render?: any;
  width?: string;
  defaultSort?: "asc" | "desc";
}

export type TableCardViewProps = {
  title?: string;
  headers: HeaderType[];
  data: object[];
  treeData?: any;
  pageSize?: number;
  testing?: boolean;
  pageSizeOptions?: number[];
  fixedLayout?: boolean;
  // if this is not null, the table will be selectable, meaning there are checkboxes and you can do the onClick you chose.
  select?: {
    onClick: (event: any, data: any) => void;
    icon: string;
    tooltip: string;
  };
  help?: string;
  showLoadingInsteadOfNoDataWarning?: boolean;
  errorMessage?: string;
};

export function SimpleTableCardView({
  title,
  headers,
  data,
  treeData,
  testing,
  fixedLayout,
  select,
  help,
  showLoadingInsteadOfNoDataWarning,
  errorMessage,
}: TableCardViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const idSynonymField = "__id";

  const [visibleColumns, setVisibleColumns] = useState(
    headers
      ?.filter((header) => !("optional" in header) || header.enabled)
      .map((header) => header.field)
  );

  return (
    <Box className="simpleTableView">
      <Card>
        <CardHeader
          className={classes.ch}
          title={
            <>
              {title}
              {help && <HelpToolTip help={help} />}
            </>
          }
        />
        <CardContent>
          {<ErrorLabel text={errorMessage}/>}
          {data.length ? (
            testing ? (
              <TestingTable headers={headers} data={data} />
            ) : (
              <MaterialTable
                icons={tableIcons}
                columns={headers.filter((header) =>
                  visibleColumns.includes(header.field)
                )}
                data={data.map((d, i) => {
                  d[idSynonymField] = i;
                  return d;
                })}
                parentChildData={treeData}
                title=""
                options={{
                  padding: "dense",

                  /* idSynonym is used to point to a key prop in the data object to allow for performant targeted
                  re-rendering of rows. Since we do cloneDeep() on all passed data objects, we have to ties to state,
                  and can safely ignore dealing with this */
                  idSynonym: idSynonymField,
                  defaultExpanded: true,
                  exportMenu: [
                    {
                      label: "Export CSV",
                      exportFunc: (cols, datas) =>
                        ExportCsv(
                          cols,
                          datas,
                          title
                            ? title.toLowerCase().replace(/\s|\./g, "_")
                            : "data"
                        ),
                    },
                  ],
                  showTitle: false,
                  pageSize: data.length >= 25 ? 25 : data.length,
                  pageSizeOptions:
                    data.length <= 25 ? [data.length] : [5, 10, 25, 50],
                  tableLayout: fixedLayout ? "fixed" : "auto",
                  exportAllData: true,
                  selection: select ? true : false,
                  searchFieldStyle: {
                    padding: "5px 0px 5px 15px",
                    borderRadius: "5px",
                    backgroundColor: theme.palette.action.selected,
                    marginRight: "10px",
                  },
                  searchFieldVariant: "outlined",
                  rowStyle: (rowData) => ({
                    height: "40px",
                    whiteSpace: "nowrap",
                  }),
                }}
                actions={
                  select
                    ? [
                        {
                          icon:
                            "OpenInNew" === select.icon
                              ? OpenInNew
                              : select.icon,
                          tooltip: select.tooltip,
                          position: "toolbarOnSelect",
                          onClick: select.onClick,
                        },
                      ]
                    : []
                }
                localization={i18next.language === "fr" ? frLocalization : {}}
                components={{
                  Container: (props) => <Paper {...props} elevation={0} />,
                  Toolbar: (props) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "20px" }}>
                        {headers?.map(
                          (header, i) =>
                            header.optional && (
                              <div
                                key={"visibility_" + i}
                                className={classes.settingBlock}
                              >
                                <>{t("Show") + " " + header.title}</>
                                <Switch
                                  checked={visibleColumns.includes(
                                    header.field
                                  )}
                                  size="small"
                                  color="primary"
                                  onChange={() =>
                                    setVisibleColumns(
                                      visibleColumns?.includes(header.field)
                                        ? visibleColumns?.filter(
                                            (col) => col !== header.field
                                          )
                                        : visibleColumns?.concat(header.field)
                                    )
                                  }
                                />
                              </div>
                            )
                        )}
                      </div>
                      <div className={classes.tableToolbar}>
                        <MTableToolbar {...props} />
                      </div>
                    </div>
                  ),
                }}
              />
            )
          ) : showLoadingInsteadOfNoDataWarning ? (
            <>
              {t("Loading")} ...
              <LinearProgress />
            </>
          ) : (
            t("No Data to Display")
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

// if you supply a title here, it will show it
// if you want to specify the pageSize default you can also do this
export function SimpleTableView({
  title,
  headers,
  data,
  testing,
  select,
  showLoadingInsteadOfNoDataWarning,
}: TableCardViewProps) {
  const { t } = useTranslation();
  const idSynonymField = "__id";

  return (
    <Box className="simpleTableView">
      {data.length ? (
        testing ? (
          <TestingTable headers={headers} data={data} />
        ) : (
          <MaterialTable
            icons={tableIcons}
            columns={headers}
            data={data.map((d, i) => {
              d[idSynonymField] = i;
              return d;
            })}
            title={title ? title : ""}
            options={{
              padding: "dense",

              /* idSynonym is used to point to a key prop in the data object to allow for performant targeted
              re-rendering of rows. Since we do cloneDeep() on all passed data objects, we have to ties to state,
              and can safely ignore dealing with this */
              idSynonym: idSynonymField,
              exportMenu: [
                {
                  label: "Export CSV",
                  exportFunc: (cols, datas) =>
                    ExportCsv(
                      cols,
                      datas,
                      title
                        ? title.toLowerCase().replace(/\s|\./g, "_")
                        : "data"
                    ),
                },
              ],
              showTitle: !!title,
              pageSize: data.length >= 25 ? 25 : data.length,
              pageSizeOptions:
                data.length <= 25 ? [data.length] : [5, 10, 25, 50],
              exportAllData: true,
              selection: select ? true : false,
            }}
            actions={
              select
                ? [
                    {
                      icon:
                        "OpenInNew" === select.icon ? OpenInNew : select.icon,
                      tooltip: select.tooltip,
                      position: "toolbarOnSelect",
                      onClick: select.onClick,
                    },
                  ]
                : []
            }
            localization={i18next.language === "fr" ? frLocalization : {}}
          />
        )
      ) : showLoadingInsteadOfNoDataWarning ? (
        <>
          {t("Loading")} ...
          <LinearProgress />
        </>
      ) : (
        t("No Data to Display")
      )}
    </Box>
  );
}

export type TestingTableProps = { headers: HeaderType[]; data: object[] };

export function TestingTable({ headers, data }: TestingTableProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((h, i) => (
            <TableCell key={i}>{h.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((d, i) => (
          <TableRow key={i}>
            {headers.map((h, j) => (
              <TableCell key={i + "-" + j}>
                {typeof d[h.field] === "object"
                  ? JSON.stringify(d[h.field])
                  : d[h.field]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

const frLocalization = {
  body: {
    emptyDataSourceMessage: "Pas d'enregistreent à afficher",
    addTooltip: "Ajouter",
    deleteTooltip: "Supprimer",
    editTooltip: "Editer",
    filterRow: {
      filterTooltip: "Filtrer",
    },
    editRow: {
      deleteText: "Voulez-vous supprimer cette ligne?",
      cancelTooltip: "Annuler",
      saveTooltip: "Enregistrer",
    },
  },
  grouping: {
    placeholder: "Tirer l'entête ...",
    groupedBy: "Grouper par:",
  },
  header: {
    actions: "Actions",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsSelect: "lignes",
    labelRowsPerPage: "lignes par page:",
    firstAriaLabel: "Première page",
    firstTooltip: "Première page",
    previousAriaLabel: "Page précédente",
    previousTooltip: "Page précédente",
    nextAriaLabel: "Page suivante",
    nextTooltip: "Page suivante",
    lastAriaLabel: "Dernière page",
    lastTooltip: "Dernière page",
  },
  toolbar: {
    addRemoveColumns: "Ajouter ou supprimer des colonnes",
    nRowsSelected: "{0} ligne(s) sélectionée(s)",
    showColumnsTitle: "Voir les colonnes",
    showColumnsAriaLabel: "Voir les colonnes",
    exportTitle: "Exporter",
    exportAriaLabel: "Exporter",
    exportName: "Exporter en CSV",
    searchTooltip: "Chercher",
    searchPlaceholder: "Chercher",
  },
};
