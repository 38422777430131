import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { TransWithEmail } from "../common/TransWithEmail";

export function TermsAndConditions() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>BEAVER {t("Terms and Conditions")}</title>
        <meta name="description" content={t("page.home.description")} />
      </Helmet>
      <TermsText />
    </>
  );
}

export function TermsText() {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <h2>{t("terms-header")}</h2>
        <p>{t("terms-content-0")}</p>
      </div>
      <div>
        <div>
          <h4>{t("terms-heading-1")}</h4>
          <p>{t("terms-content-1")}</p>
        </div>
        <div>
          <h4>{t("terms-heading-2")}</h4>
          <p>{t("terms-content-2")}</p>
        </div>
        <div>
          <h4>{t("terms-heading-3")}</h4>
          <p>{t("terms-content-3")}</p>
        </div>
        <div>
          <h4>{t("terms-heading-4")}</h4>
          <p><TransWithEmail i18nKey="terms-content-4"/></p>
        </div>
        <div>
          <h4>{t("terms-heading-5")}</h4>
          <p>{t("terms-content-5")}</p>
        </div>
        <div>
          <h4>{t("terms-heading-6")}</h4>
          <p>{t("terms-content-6")}</p>
        </div>
        <div>
          <h4>{t("terms-heading-7")}</h4>
          <p><TransWithEmail i18nKey="terms-content-7"/></p>
        </div>
        <div>
          <p>{t("terms-updated-on")}2020-03-23</p>
        </div>
      </div>
    </div>
  );
}
