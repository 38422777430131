import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from "@material-ui/core";

import {
  FlexibleXYPlot,
  Highlight,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
} from "react-vis";

import { entropyModel } from "../../../../models/report/md5/entropyModel";

import { useStyles } from "../../common/sharedStyles";

import { useTranslation } from "react-i18next";
import _ from "lodash";

interface histogramEntry {
  x: number;
  y: number;
}

// the test mode hides the flexiblexy for the tests
export type EntropyViewProps = { entropyReport: entropyModel; test?: boolean };

export function EntropyView({ entropyReport, test }: EntropyViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [histogram, setHistogram] = React.useState([]);
  const [maxValue, setMaxValue] = React.useState(0);

  React.useEffect(() => {
    // taking array like [0,1,2, ... 255] then maping it with values that way padding any null entries
    let hist = _.range(256).map((i) => ({
      x: i,
      y: entropyReport?.byteHistogramReport.resultElements["" + i]
        ? entropyReport.byteHistogramReport.resultElements["" + i]
        : 0,
    }));
    setHistogram(hist);
    const maxVal = hist.map((h) => h.y).reduce((a, b) => (a > b ? a : b));
    setMaxValue(maxVal);
  }, [entropyReport?.byteHistogramReport.resultElements]);

  return (
    <Box>
      <Card>
        <CardHeader className={classes.ch} title={t("Entropy Report")} />
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{t("Analysis Date")}</TableCell>
                  <TableCell>{entropyReport?.creationDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>MD5</TableCell>
                  <TableCell>{entropyReport?.md5}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Report Version")}</TableCell>
                  <TableCell>{entropyReport?.version}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Tool Version")}</TableCell>
                  <TableCell>{entropyReport?.engineVersion}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Entropy")}</TableCell>
                  <TableCell>{entropyReport?.entropy}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Result")}</TableCell>
                  <TableCell>{entropyReport?.result}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Message")}</TableCell>
                  <TableCell>
                    {entropyReport?.messages && entropyReport.messages.length
                      ? entropyReport.messages[0].type +
                        "  " +
                        entropyReport.messages[0].message
                      : null}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>{" "}
        </CardContent>
      </Card>
      <Box mt={2}>
        {histogram && histogram.length ? (
          <ByteHistogram
            test={test}
            histogram={histogram}
            maxValue={maxValue}
          />
        ) : null}
      </Box>
    </Box>
  );
}

function ByteHistogram({
  histogram,
  test,
  maxValue,
}: {
  histogram: histogramEntry[];
  test?: boolean;
  maxValue: number;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [filteredHistogram, setFilteredHistogram] = useState(histogram);
  const [touchXVals, setTouchXVals] = useState([]);
  const [cur, setCur] = useState(null);

  const filter = (area) => {
    setFilteredHistogram(
      histogram.filter(
        (val: histogramEntry) => val.x > area.left && val.x < area.right
      )
    );
  };

  return (
    <Card>
      <CardHeader className={classes.ch} title={t("Byte Histogram")} />
      <CardContent>
        {test ? (
          <p>No graph in tests :(</p>
        ) : (
          <FlexibleXYPlot
            height={300}
            onTouchEnd={(e) => {
              e.preventDefault();
              // here just use the nearest X
              if (touchXVals.length > 1) {
                filter({
                  left: touchXVals.sort((a, b) => a - b)[0],
                  right: touchXVals.sort((a, b) => a - b)[
                    touchXVals.length - 1
                  ],
                });
              }
              setTouchXVals([]);
            }}
          >
            <VerticalGridLines style={{ stroke: "#B7E9ED" }} />
            <HorizontalGridLines style={{ stroke: "#B7E9ED" }} />
            <XAxis
              style={{
                fill: theme.palette.text.primary,
              }}
            />
            <YAxis
              tickFormat={(v) => {
                if (v > 850000000) return v / 1000000000 + "G";
                else if (v > 850000) return v / 1000000 + "M";
                else if (v > 850) return v / 1000 + "k";
                return v;
              }}
              tickPadding={-1}
              style={{
                fill: theme.palette.text.primary,
              }}
            />
            <VerticalBarSeries
              data={filteredHistogram}
              onNearestX={(pt) => {
                setTouchXVals(touchXVals.concat([pt.x]));
                let c = {
                  x: pt.x,
                  y: pt.y,
                  p: maxValue > 0 ? (pt.y * 100) / maxValue : 0,
                };
                if (c.p > 0) setCur(c);
              }}
            />
            <Highlight
              enableY={false}
              onBrushEnd={(a) => {
                if (
                  a &&
                  a.left &&
                  a.right &&
                  a.right - a.left > 1 &&
                  a.right > 0
                ) {
                  filter(a);
                }
              }}
            />
          </FlexibleXYPlot>
        )}
        <Button
          disabled={histogram.length === filteredHistogram.length}
          onClick={() => setFilteredHistogram(histogram)}
        >
          {t("Reset Zoom")}
        </Button>
        <Box
          component="span"
          color="text.secondary"
          hidden={histogram.length !== filteredHistogram.length}
        >
          {t("Highlight Graph Area to Zoom In")}
        </Box>
        <Box ml={2} display="inline" style={{ fontWeight: "bold" }}>
          {cur && cur.p > 0
            ? [
                String.fromCharCode(cur.x),
                "[",
                cur.x,
                "] ,",
                cur.y,
                "(",
                cur.p.toFixed(1),
                " %)",
              ].join(" ")
            : null}
        </Box>
      </CardContent>
    </Card>
  );
}
