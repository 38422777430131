import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Axios, { Method } from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@material-ui/core";
import {
  isExternal,
  isInternal,
  isLab,
  isLocal,
  isStaging,
  UploadUrl,
  isGeekweek,
} from "../../helpers/ServerProperties";
import { useStyles } from "../report/common/sharedStyles";
import md5 from "md5";
import { getUserForHTTPCall } from "../../../../commons/utilities";

const urlRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

enum ResultStatus {
  ERROR = "error",
  SUCCESS = "success",
}

enum ComponentState {
  ERROR = "error",
  PENDING = "pending",
  SUCCESS = "success",
  IDLE = "idle",
  WARNING = "warning",
}

/**
 * The URL Upload state reducer. This component handles
 * @param state
 * @param action
 */
function urlDataReducer(state, action): URLState {
  if (action.status === "update-urls") {
    return {
      ...state,
      urls: action.urls.split("\n"),
      invalidURLs: [],
      status: ComponentState.IDLE,
      error: null,
    };
  } else if (action.status === "update-sourceid") {
    return {
      ...state,
      sourceID: action.sourceID,
      status: ComponentState.IDLE,
      error: null,
    };
  } else if (action.status === "update-urltype") {
    return {
      ...state,
      urlType: action.urlType,
      status: ComponentState.IDLE,
      error: null,
    };
  } else if (action.status === "submit-urls") {
    //The submission button has been clicked, find valid and invalid URLS, and change the state to pending
    // sanitize, drop any empties, needs to have http:// minimum, so 7 chars minimum length
    const validURLs = state.urls.filter(
      (u) => u.trim() !== "" && u.length > 7 && u.match(urlRegex)
    );

    let invalidURLs = state.urls
      .filter((u) => !validURLs.includes(u))
      .filter((u) => u.trim() !== "");

    return {
      ...state,
      invalidURLs: invalidURLs,
      urls: validURLs,
      status: ComponentState.PENDING,
      data: [],
    };
  } else if (action.status === "clear") {
    action.urls.value = "";
    return {
      ...state,
      urls: [],
      invalidURLs: [],
      status: ComponentState.IDLE,
      error: null,
    };
  } else if (action.status === "one-result") {
    return {
      ...state,
      data: [...state.data, { ...action.data, status: action.result }],
    };
  } else if (action.status === ComponentState.ERROR) {
    return { ...state, status: action.status, error: action.error };
  } else if (action.status === ComponentState.SUCCESS) {
    return { ...state, status: action.status };
  } else if (action.status === ComponentState.WARNING) {
    return { ...state, status: action.status };
  }
  throw new Error(`Invalid State found during upload ${action.status}`);
}

type URLState = {
  urlType: string;
  urls: string[];
  invalidURLs?: string[];
  sourceID: string;
  error?: string;
  status: ComponentState;
  data: any[];
};

export function URLUpload() {
  const classes = useStyles();
  const { t } = useTranslation();

  const urlTextRef = React.useRef();

  const [urlState, urlDispatch] = React.useReducer(urlDataReducer, {
    urlType: "UNKNOWN",
    urls: [],
    invalidURLs: [],
    sourceID: isExternal() ? "mudi" : isLocal() ? "ev" : "muos",
    error: null,
    status: ComponentState.IDLE,
    data: [],
  });
  /**
   *  We listen for state changes here, and set extra error messages based on derived state
   */
  React.useEffect(() => {
    /** This function takes an array of urls, and concurrently submits them to the backend, waiting until all results are
     * retreived. Each result is then dispatched, with success or error keys to be displayed in the UI.
     * @param urlState
     */
    const submitSample = (url: string[]) => {
      Promise.allSettled(
        urlState.urls.map((url) => {
          let data = new URLSearchParams();
          data.append("sourceID", urlState.sourceID);
          data.append("url", url.trim());
          data.append("url_type", urlState.urlType);
          return Axios({
            method: UploadUrl().method as Method,
            url: UploadUrl().url,
            data: data,
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            auth: getUserForHTTPCall(),
          });
        })
      ).then((results) => {
        results.forEach((result) => {
          if (result.status === "fulfilled") {
            urlDispatch({
              status: "one-result",
              data: result.value.data,
              result: ResultStatus.SUCCESS,
            });
          } else if (result.status === "rejected") {
            if (
              result.reason.response.status >= 400 &&
              result.reason.response.status < 500
            ) {
              urlDispatch({
                status: "one-result",
                data: {...JSON.parse(result.reason.response.data), statusCode: result.reason.response.status},
                result: ResultStatus.ERROR,
              });
            } else {
              urlDispatch({
                status: ComponentState.ERROR,
                error: result.reason.message,
              });
            }
          }
        });

        urlDispatch({ status: ComponentState.SUCCESS });
      });
    };

    if (urlState.status === ComponentState.PENDING) {
      if (urlState.urls.length === 0 && urlState.invalidURLs.length === 0) {
        urlDispatch({
          status: ComponentState.ERROR,
          error: '"No URLs to submit"',
        });
      } else if (
        urlState.invalidURLs &&
        urlState.invalidURLs.length > 0 &&
        !urlState.urls
      ) {
        urlDispatch({ status: ComponentState.WARNING });
      } else {
        submitSample(urlState.urls);
      }
    }
  }, [urlState]);

  return (
    <>
      {!isExternal() && (
          <Link to="/upload/url/list" style={{ textDecoration: "none" }}>
            <Button>{t("View Uploaded URLs")}</Button>
          </Link>
      )}
      <Card>
        <Box minHeight={{ sm: "31rem" }}>
          <CardHeader title={t("URL Upload")} className={classes.ch} />
          <CardContent>
            {t("Sample Source")}:
            <br />
            <Select
              name="sourceID"
              id="sourceID"
              value={urlState.sourceID}
              onChange={(event) =>
                urlDispatch({
                  status: "update-sourceid",
                  sourceID: event.target.value,
                })
              }
            >
              {isLocal() || isStaging() ? (
                <MenuItem value="ev">{t("Evaluation")}</MenuItem>
              ) : null}

              {isLab() || isInternal() || isGeekweek() ? (
                <MenuItem value="muos">
                  {t("Manual Upload") + " Open Source"}
                </MenuItem>
              ) : null}
              {isLab() || isInternal() || isGeekweek() ? (
                <MenuItem value="mucc">{t("Manual Upload") + " CCCS"}</MenuItem>
              ) : null}

              {isLab() || isInternal() || isGeekweek() || isExternal() ? (
                <MenuItem value="mudi">
                  {t(
                    "Manual Upload Disclosed Information Without Expectation of Service"
                  )}
                </MenuItem>
              ) : null}
            </Select>
            <Box mt={3}>
              {t("URL(s) - One per line")}:
              <br />
              <TextareaAutosize
                minRows={10}
                placeholder="http(s)://..."
                style={{ width: "90%" }}
                onChange={(event) => {
                  urlDispatch({
                    status: "update-urls",
                    urls: event.target.value,
                  });
                }}
                ref={urlTextRef}
              />
            </Box>
            <Box mt={3}>
              {t("Type")}:
              <br />
              <Select
                name="urlType"
                id="urlType"
                value={urlState.urlType}
                onChange={(event) =>
                  urlDispatch({
                    status: "update-urltype",
                    urlType: event.target.value,
                  })
                }
              >
                <MenuItem value="CPANEL">CPANEL</MenuItem>
                <MenuItem value="PHISHING">PHISHING</MenuItem>
                <MenuItem value="SMISHING">SMISHING</MenuItem>
                <MenuItem value="SAMPLE">SAMPLE</MenuItem>
                <MenuItem value="PHISHING_KIT">PHISHING_KIT</MenuItem>
                <MenuItem value="UNKNOWN">{t("UNKNOWN")}</MenuItem>
              </Select>
            </Box>
            <Box mt={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  urlDispatch({ status: "submit-urls" });
                }}
                disabled={
                  urlState.status !== ComponentState.IDLE &&
                  urlState.status !== ComponentState.SUCCESS
                }
              >
                {t("Submit")}
              </Button>
              <Button
                onClick={() => {
                  urlDispatch({ status: "clear", urls: urlTextRef.current });
                }}
              >
                {t("Clear")}
              </Button>
            </Box>
            {urlState.error ? (
              <Box m={2} style={{ color: "darkred" }}>
                <b>{t("An error occured")}: </b>
                {urlState.error}{" "}
                {urlState.error.match(urlRegex) && (
                  <Link
                    to={[
                      "/url",
                      md5(urlState.error.match(urlRegex)[0]),
                      "report",
                    ].join("/")}
                  >
                    {md5(urlState.error.match(urlRegex)[0])}
                  </Link>
                )}
              </Box>
            ) : null}
            {urlState.status === ComponentState.WARNING ||
            (urlState.status === ComponentState.SUCCESS &&
              urlState.invalidURLs &&
              urlState.invalidURLs.length > 0) ? (
              <Box m={2} style={{ color: "goldenrod" }}>
                <b>{t("Warning")}, </b>
                {t("the following URLs were invalid and not submitted")}:
                <br />
                <b>{urlState.invalidURLs.join(", ")}</b>
              </Box>
            ) : null}
            {urlState.status === ComponentState.PENDING ? (
              <Box m={2}>
                <LinearProgress />
              </Box>
            ) : null}
            {urlState.status === ComponentState.SUCCESS ? (
              <>
                <SubmissionResultBox results={urlState.data} />
              </>
            ) : null}
          </CardContent>
        </Box>
      </Card>
    </>
  );
}

export type SubmissionResultBoxPropType = {
  results: {
    url?: string;
    urlmd5?: string;
    status: string;
    errorMessage?: string;
    statusCode?: number;
  }[];
};

/**
 * This component is used to
 * @param results
 * @constructor
 */
export function SubmissionResultBox({
  results,
}: SubmissionResultBoxPropType) {
  const { t } = useTranslation();

  function getURLMD5(result) {
    if (result.errorMessage) {
      return md5(result.url.match(urlRegex)[0]);
    } else {
      return result.urlmd5;
    }
  }

  return results.length > 0 ? (
    <Box mt={2}>
      <table>
        <thead>
          <tr>
            <th>URL</th>
            <th style={{ paddingLeft: "8px" }}>{t("MD5")}</th>
            <th style={{ paddingLeft: "8px" }}>{t("Status")}</th>
          </tr>
        </thead>
        <tbody>
          {results.map((curData, i) => (
              // name, size, type are available
              <tr key={i}>
                <td>{curData["url"]}</td>
                <td style={{ paddingLeft: "8px" }}>
                  {getURLMD5(curData) ? (
                    <Link to={["/url", getURLMD5(curData), "report"].join("/")}>
                      {getURLMD5(curData)}
                    </Link>
                  ) : null }
                </td>
                <td style={{ paddingLeft: "8px" }}>
                  {curData.status == ResultStatus.ERROR ? (
                    <b style={{ color: curData.statusCode == 409 ? "darkblue" : "darkred" }}>
                      {curData.errorMessage}
                    </b>
                  ) : (
                    <b style={{ color: "darkgreen" }}>
                      {t("Submitted")}!
                    </b>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Box>
  ) : null;
}
