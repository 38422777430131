import React from "react";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { getResourceLink } from "../../../../helpers/ServerProperties";
import { Box, Button } from "@material-ui/core";

import { Buffer } from "buffer";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export type FaviconViewProps = {
  faviconLinks?: string[];
  showMulti?: boolean;
  setFaviconLoadError: Function;
};

export function FaviconView({
  faviconLinks,
  showMulti,
  setFaviconLoadError,
}: FaviconViewProps) {
  const [Favicon, setFavicon] = React.useState(null);
  const [loadError, setLoadError] = React.useState(null);
  const [index, setIndex] = React.useState(0);

  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  React.useEffect(() => {
    if ("mock" !== user.server && faviconLinks && faviconLinks.length) {
      Axios.get(getResourceLink(faviconLinks[index]), {
        responseType: "arraybuffer",
        auth:
          user.server && !user.server.includes("localhost")
            ? {
                username: user ? user.username : null,
                password: user
                  ? user.onetime
                    ? // eslint-disable-next-line no-eval
                      window.atob(eval(window.atob(user.onetime))).substr(13)
                    : null
                  : null,
              }
            : null,
      })
        .then((response) => {
          // if there is no data, then show an erorr (otherwise it'll just keep saying Loading ...)
          if (!response.data.byteLength) setLoadError("Favicon has no data");
          // not that e.g. for a805a28fdbe304ffae9cf3878f849753 the output could be empty
          setFavicon(Buffer.from(response.data, "binary").toString("base64"));
        })
        .catch((e) => setLoadError(e.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faviconLinks, index]);

  return (
    <ShowFavicon
      loadError={loadError}
      Favicon={Favicon}
      faviconLinks={faviconLinks}
      mock={"mock" === user.server}
      setIndex={setIndex}
      index={index}
      setFavicon={setFavicon}
      showMulti={showMulti}
      setFaviconLoadError={setFaviconLoadError}
    />
  );
}

export type ShowFaviconProps = {
  loadError: string;
  Favicon: string;
  faviconLinks?: string[];
  mock?: boolean;
  setIndex: Function;
  index: number;
  setFavicon: Function;
  showMulti?: boolean;
  setFaviconLoadError: Function;
};

/**
 * This function will determine if the base64 image is valid and of type image/x-icon
 * TODO - determine if another type, e.g. image/jpeg or image/png
 *
 * @param base64String the base64 string of the image
 */
async function isBase64UrlImage(base64String: string): Promise<boolean> {
  let image = new Image();
  image.src = "data:image/x-icon;base64," + base64String;
  return await new Promise((resolve) => {
    image.onload = function () {
      if (image.height === 0 || image.width === 0) {
        resolve(false);
        return;
      }
      resolve(true);
    };
    image.onerror = (e) => {
      // console.log(e);
      resolve(false);
    };
  });
}

function ShowFavicon({
  loadError,
  Favicon,
  faviconLinks,
  mock,
  setIndex,
  index,
  setFavicon,
  showMulti,
  setFaviconLoadError,
}: ShowFaviconProps) {
  const [validIcon, setValidIcon] = React.useState(true);
  const [iconOrTextValue, setIconOrTextValue] = React.useState(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (Favicon) isBase64UrlImage(Favicon).then((res) => setValidIcon(res));
  }, [Favicon]);

  React.useEffect(() => {
    if (!mock) {
      if (!loadError) {
        setIconOrTextValue(
          !Favicon ? (
            t("Loading") + " ..."
          ) : !validIcon ? (
            t("Favicon not of type image/x-icon")
          ) : Favicon ? (
            <img
              style={{ maxWidth: "60px", height: "auto" }}
              src={"data:image/x-icon;base64, " + Favicon}
              alt={t("Favicon")}
            />
          ) : Favicon !== null ? (
            t("Favicon has no data")
          ) : faviconLinks && faviconLinks.length ? (
            t("Loading Favicon") + " ..."
          ) : (
            t("No Favicon")
          )
        );
      } else {
        setFaviconLoadError(true);
        setIconOrTextValue(
          loadError === "Favicon has no data"
            ? t("No Favicon to display")
            : t("Error Loading Favicon") + " : " + t(loadError)
        );
      }
    } else
      setIconOrTextValue(
        <img
          style={{ width: "100%", height: "auto" }}
          src="/ng/images/beaverng.jpg"
          alt={t("Favicon")}
        />
      );
  }, [Favicon, faviconLinks, loadError, mock, setFaviconLoadError, t, validIcon]);

  return (
    <>
      {showMulti && faviconLinks && faviconLinks.length > 1 ? (
        <Box>
          <Button
            color="primary"
            onClick={() => {
              setFavicon(null);
              setIndex(index - 1);
            }}
            disabled={!Favicon || index < 1}
          >
            <ChevronLeftIcon />
          </Button>
          {[t("Favicon"), index + 1, t("of"), faviconLinks.length].join(" ") +
            " (" +
            t("Date")}
          {": "}
          {faviconLinks[index].match(/\/\d{8}\//g)[0].replace(/\//g, "") + ") "}
          <Button
            color="primary"
            onClick={() => {
              setFavicon(null);
              setIndex(index + 1);
            }}
            disabled={!Favicon || index > faviconLinks.length - 2}
          >
            <ChevronRightIcon />
          </Button>
        </Box>
      ) : null}
      {iconOrTextValue}
    </>
  );
}
