import React from "react";
import { Link } from "react-router-dom";
import PageHeader from "commons/components/layout/pages/PageHeader";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  Grid,
  LinearProgress,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import { SearchElasticCafeJSON } from "components/beaver/helpers/ServerProperties";
import { SimpleTableView } from "../report/common/SimpleTableView";
import { useStyles } from "../report/common/sharedStyles";
import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";
import md5 from "md5";

import { useDispatch, useSelector } from "react-redux";
import {
  elasticCafeSearchSelector,
  ElasticCafeSearchState,
  setSearchError,
  setSearchResults,
  setSearchTerms,
  setSearchText,
} from "slices/elasticcafesearch";

import { fetchSources, sourceSelector } from "slices/sources";

import {
  MoreHorizOutlined,
  MonetizationOn,
  Attachment,
  Close,
  StarBorder,
} from "@material-ui/icons";
import { OtherSearches } from "components/routes/Search";
import PageFullWidth from "commons/components/layout/pages/PageFullWidth";

export function ElasticCafeSearch() {
  const { t } = useTranslation();

  const title = t("Cafe Spam Emails Search");
  return (
    <>
      <Helmet>
        <title>{["BEAVER", title].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader title={title} />
      <PageFullWidth noTopMargin>
        <OtherSearches currentSearchPage="cafe" />
        <CafeSearch />
      </PageFullWidth>
    </>
  );
}

export function CafeSearch() {
  const { t } = useTranslation();

  const [query, setQuery] = React.useState("");
  const [resultsSize, setResultsSize] = React.useState("100");
  const [startAt, setStartAt] = React.useState("0");

  const [err, setErr] = React.useState<string>(null);
  const [warn, setWarn] = React.useState<string>(null);
  const [results, setResults] = React.useState<any>(null);
  const [searching, setSearching] = React.useState(false);

  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  const dispatch = useDispatch();

  const elasticCafeSearchState: ElasticCafeSearchState = useSelector(
    elasticCafeSearchSelector
  );

  // this code is loading the state from redux
  React.useEffect(() => {
    if (elasticCafeSearchState) {
      if (elasticCafeSearchState.searchText)
        setQuery(elasticCafeSearchState.searchText);
      if (elasticCafeSearchState?.searchTerms?.resultsSize)
        setResultsSize(elasticCafeSearchState.searchTerms.resultsSize);
      if (elasticCafeSearchState?.searchTerms?.startAt)
        setStartAt(elasticCafeSearchState.searchTerms.startAt);
      if (elasticCafeSearchState.searchResults)
        setResults(elasticCafeSearchState.searchResults);
    }
  }, [elasticCafeSearchState]);

  const doSearch = (newStartAt = "0") => {
    setWarn(null);
    setStartAt(newStartAt);
    setResults(null);
    // check to see if there are any issues with any of the inputs, warn if is the case
    if (!query || !query.trim()) {
      setWarn(t("Please enter a query"));
      setQuery("");
      return;
    }

    if (!resultsSize.match(/^\d+$/) || parseInt(resultsSize) <= 0) {
      setWarn(t("Number of Results must be a positive number greater than 0"));
      return;
    }

    // do the query itself
    setSearching(true);

    // saving state on search button press
    dispatch(setSearchText(query));
    dispatch(
      setSearchTerms({
        resultsSize: resultsSize,
        newStartAt: newStartAt,
      })
    );

    // do axios search
    Axios.get(SearchElasticCafeJSON(query, resultsSize, newStartAt), {
      auth:
        user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    }).then(
      (response) => {
        // TODO - remove this
        console.log(response);

        if (response.data.error && response.data.error.reason) {
          setErr(response.data.error.reason);
          dispatch(setSearchError(response.data.error.reason));
          setWarn(
            response.data?.error?.root_cause.length &&
              "parse_exception" === response.data.error.root_cause[0].type
              ? t("Syntax Error - your search could query is invalid")
              : [
                  t("There may be an error with our service"),
                  response.data.error.reason,
                ].join(": ")
          );
        } else {
          if ("object" !== typeof response.data) {
            // if it's a string and not an object, likely logged out {
            // could not parse JSON meaning it's an html response, so probably logged out elsewhere
            setTimeout(() => {
              setErr(t("You may need to logout and login again"));
            }, 1200);
            setTimeout(() => {
              if (localStorage.ssoPrefix) localStorage.removeItem("ssoPrefix");
              if (localStorage.currentUser)
                localStorage.removeItem("currentUser");
              window.location.reload();
            }, 3000);
          }
          setResults(response.data);
          dispatch(setSearchResults(response.data));
        }
        setSearching(false);
      },
      (error) => {
        // if it's a 401, logout because bad creds, the user can then log back in
        // a 404 shouldn't happen, this is when the user logs out elsewhere setting the
        // prefix's all wrong causing the 404, so logout here too.
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 404)
        ) {
          setTimeout(() => {
            setErr(t("You may need to logout and login again"));
          }, 1200);
          setTimeout(() => {
            if (localStorage.ssoPrefix) localStorage.removeItem("ssoPrefix");
            if (localStorage.currentUser)
              localStorage.removeItem("currentUser");
            window.location.reload();
          }, 3000);
        } else {
          // otherwise show the message
          console.error(error);
          setErr(error.message);
          dispatch(setSearchError(error.message));
          setSearching(false);
        }
      }
    );
  };

  return (
    <Box style={{ margin: "24px" }}>
      <Grid
        container
        style={{ marginBottom: 20, display: !!results ? "none" : null }}
        spacing={2}
      >
        <ElasticCafeSearchForm
          doSearch={doSearch}
          query={query}
          setQuery={setQuery}
          resultsSize={resultsSize}
          setResultsSize={setResultsSize}
          warn={warn}
          searching={searching}
        />
      </Grid>
      {!!searching && (
        <Box>
          {t("Loading")} ...
          <LinearProgress />
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        open={!!err}
        onClose={() => {
          setErr(null);
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={"error"}
          onClose={() => {
            setErr(null);
          }}
        >
          {t("An Error Occured") + " - " + err}
        </MuiAlert>
      </Snackbar>
      {results ? (
        <ElasticCafeSearchResults
          results={results}
          startAt={startAt}
          resultsSize={resultsSize}
          setResults={setResults}
          query={query}
          doSearch={doSearch}
          setSearchResults={setSearchResults}
        />
      ) : null}
    </Box>
  );
}

export type ElasticCafeSearchFormType = {
  doSearch: (newStartAt?: string) => void;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  resultsSize: string;
  setResultsSize: React.Dispatch<React.SetStateAction<string>>;
  warn: string;
  searching: boolean;
};

export function ElasticCafeSearchForm({
  doSearch,
  query,
  setQuery,
  resultsSize,
  setResultsSize,
  warn,
  searching,
}: ElasticCafeSearchFormType) {
  const { t } = useTranslation();

  const searchIfEnterPressed = (e) => {
    if ("Enter" === e.key) doSearch();
  };

  return (
    <Grid item xs={12} md={6}>
      <Paper style={{ padding: 15 }}>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle1">
            {t("cafesearch.suggestion")}
          </Typography>
          <Typography variant="caption">
            {t("cafesearch.suggestion2")}
          </Typography>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              width: "100%",
              marginTop: 15,
            }}
          >
            <FormControl style={{ flex: 3 }}>
              <TextField
                label={t("Query")}
                value={query}
                onChange={(e) => setQuery("" + e.target.value)}
                onKeyDown={searchIfEnterPressed}
              />
            </FormControl>
            <FormControl style={{ marginLeft: 15, flex: 1 }}>
              <TextField
                label={t("Number of Results (default 100)")}
                value={resultsSize}
                onChange={(e) => setResultsSize("" + e.target.value)}
                onKeyDown={searchIfEnterPressed}
              />
            </FormControl>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              width: "100%",
              marginTop: 15,
              marginBottom: 10,
            }}
          ></Box>
          {warn ? (
            <Typography style={{ marginTop: 30 }} variant="body1" color="error">
              {warn}
            </Typography>
          ) : null}
          <Button
            variant="contained"
            color="primary"
            onClick={() => doSearch()}
            style={{ marginTop: 15 }}
            type="submit"
            disabled={searching}
          >
            {t("Search")}
          </Button>
        </Box>
      </Paper>
    </Grid>
  );
}

export interface ElasticResults {
  hits: {
    max_score?: number;
    total: { value: number; relation: string };
    hits: { _source: any; highlight: any; _score: number; _id: string }[];
  };
}

export type ElasticCafeSearchResultsType = {
  results: ElasticResults;
  query: string;
  doSearch: (newStartAt?: string) => void;
  startAt: string;
  resultsSize: string;
  setResults: React.Dispatch<React.SetStateAction<string>>;
  setSearchResults: Function;
};

export function ElasticCafeSearchResults({
  results,
  query,
  doSearch,
  startAt,
  resultsSize,
  setResults,
  setSearchResults,
}: ElasticCafeSearchResultsType) {
  const { t } = useTranslation();
  const classes = useStyles();

  const maxContentLength = 150;
  const maxMatches = 2;

  const [showModal, setShowModal] = React.useState(-1);

  const dispatch = useDispatch();
  const { sources } = useSelector(sourceSelector);

  React.useEffect(() => {
    dispatch(fetchSources(sources));
  }, [dispatch, sources]);

  return (
    <>
      <Modal open={showModal >= 0} onClose={() => setShowModal(-1)}>
        <Paper
          style={{
            overflow: "auto",
            maxHeight: "80vh",
            width: "80vw",
            display: "block",
            margin: "auto",
            marginTop: "7vh",
          }}
        >
          <Box m={2}>
            <Box
              style={{
                float: "right",
                cursor: "pointer",
              }}
              onClick={() => setShowModal(-1)}
            >
              <Close />
            </Box>
            <Box mb={2}>
              <Typography variant="h5">{t("Email Details")}</Typography>
            </Box>

            <ShowEmail email={results.hits.hits[showModal]} sources={sources} />
          </Box>
        </Paper>
      </Modal>

      <Card>
        <CardHeader
          className={classes.ch}
          title={t("Results for ") + `'${query}'`}
        ></CardHeader>
        <CardContent>
          <p>
            {parseInt(startAt)
              ? [
                  t("Showing results"),
                  (parseInt(startAt) + 1).toLocaleString(),
                  t("to"),
                  parseInt(startAt) + 1 <
                  parseInt(startAt) + parseInt(resultsSize)
                    ? results.hits.total.value.toLocaleString()
                    : (
                        parseInt(startAt) + parseInt(resultsSize)
                      ).toLocaleString(),
                  t("of"),
                  results.hits.total.value.toLocaleString() +
                    (results.hits.total.relation === "gte" ? "+" : ""),
                ].join(" ")
              : [
                  t("Showing first"),
                  results.hits.total.value < parseInt(resultsSize)
                    ? results.hits.total.value.toLocaleString()
                    : resultsSize.toLocaleString(),
                  t("of"),
                  results.hits.total.value.toLocaleString() +
                    (results.hits.total.relation === "gte" ? "+" : ""),
                ].join(" ")}
            {parseInt(startAt) + parseInt(resultsSize) <
              results.hits.total.value && (
              <Button
                color="primary"
                onClick={() => {
                  doSearch("" + (parseInt(startAt) + parseInt(resultsSize)));
                }}
              >
                {[t("Next"), resultsSize].join(" ")}
              </Button>
            )}
            {parseInt(startAt) > 0 && (
              <Button
                color="primary"
                onClick={() => {
                  doSearch("" + (parseInt(startAt) - parseInt(resultsSize)));
                }}
              >
                {[t("Previous"), resultsSize].join(" ")}
              </Button>
            )}
            <Button
              color="primary"
              onClick={() => {
                setResults(null);
                // removing results from state also
                dispatch(setSearchResults(null));
              }}
            >
              {t("New Search")}
            </Button>
          </p>

          <SimpleTableView
            headers={[
              {
                title: "",
                field: "score",
                width: "2%",
                render: (r) => (
                  <>
                    {!!r.numAttachments && (
                      <Badge badgeContent={r.numAttachments} color="secondary">
                        <Tooltip title={r.numAttachments + " Attachments"}>
                          <Attachment />
                        </Tooltip>
                      </Badge>
                    )}
                    {!!r.numCrypto && (
                      <Badge badgeContent={r.numCrypto} color="secondary">
                        <Tooltip
                          title={r.cryptoAddresses.length + " Crypto Addresses"}
                        >
                          <MonetizationOn />
                        </Tooltip>
                      </Badge>
                    )}
                    {!!r.matchedRules.length && (
                      <Badge
                        badgeContent={r.matchedRules.length}
                        color="secondary"
                      >
                        <Tooltip title={r.matchedRules.join(", ")}>
                          <StarBorder />
                        </Tooltip>
                      </Badge>
                    )}
                    <Tooltip title={t("View Email")}>
                      <MoreHorizOutlined
                        onClick={() => setShowModal(r.index)}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </>
                ),
              },
              {
                title: results?.hits?.max_score
                  ? t("Relative Score")
                  : t("Relavancy Score"),
                field: "score",
                width: "2%",
              },
              {
                title: t("Source"),
                field: "source",
                width: "10%",
                render: (r) =>
                  sources && sources[r.source]
                    ? sources[r.source].displayString
                    : r.source,
              },
              { title: t("Email Date"), field: "emailDate", width: "10%" },
              {
                title: t("Subject"),
                field: "subject",
                width: "20%",
                render: (r) => (
                  <div className={classes.breakword}>
                    {r.subject && r.subject.length > maxContentLength
                      ? r.subject.substring(0, maxContentLength) + " ..."
                      : r.subject}
                  </div>
                ),
              },
              {
                title: t("Sender Name"),
                field: "from_email_name",
                width: "10%",
                render: (r) => (
                  <div className={classes.breakword}>{r.from_email_name}</div>
                ),
              },
              {
                title: t("Sender Address"),
                field: "from_email_addr",
                width: "10%",
                render: (r) => (
                  <div className={classes.breakword}>{r.from_email_addr}</div>
                ),
              },
              {
                title: t("Specific Match"),
                field: "highlight",
                width: "20%",
                render: (r) =>
                  !!r.highlight ? (
                    <div
                      className={classes.breakword}
                      dangerouslySetInnerHTML={{
                        __html: (r.highlight[Object.keys(r.highlight)[0]]
                          .length > maxMatches
                          ? r.highlight[Object.keys(r.highlight)[0]]
                              .slice(0, maxMatches)
                              .join(", ")
                          : r.highlight[Object.keys(r.highlight)[0]].join(", ")
                        )
                          .slice(0, maxContentLength)
                          .replaceAll("<em>", "<em><b>")
                          .replaceAll("</em>", "</b></em>"),
                      }}
                    ></div>
                  ) : (
                    ""
                  ),
              },
            ]}
            data={results.hits.hits.map((r, i) => ({
              // sometimes there won't be a max score (e.g. when we sort by recieved_on), so check for that.
              score: results?.hits?.max_score
                ? Math.ceil((r._score * 10000) / results.hits.max_score) / 100 +
                  "%"
                : Math.round(r._score * 100) / 100,
              source: r._source.source,
              emailDate: r._source.dates.emailDate.replace("T", " "),
              numAttachments: r._source.attachments.length,
              cryptoAddresses: r._source.cryptocurrency_addresses,
              numCrypto: r._source.cryptocurrency_addresses.length,
              urls: r._source.urls.map((u) => u.url),
              subject: r._source.subject,
              from_email: r._source.from_email_address,
              from_email_name: ("" + r._source.from_email_address).match(/<|>/)
                ? ("" + r._source.from_email_address).split(/<|>/)[0]
                : "",
              from_email_addr: ("" + r._source.from_email_address).match(/<|>/)
                ? ("" + r._source.from_email_address).split(/<|>/)[1]
                : r._source.from_email_address,
              to_email: r._source.to_email_address,
              replyto_email: r._source.replyto_email_address,
              last_hop: r._source.last_hop,
              matchedRules: r._source.matchedRules,
              body: r._source?.bodies[0]?.decodedBody,
              highlight: r.highlight,
              index: i,
            }))}
          />
        </CardContent>
      </Card>
    </>
  );
}

export function ShowEmail({ email, sources }) {
  const { t } = useTranslation();

  return (
    <Box>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRowItem header={t("Search Index")} value={email._index} />
            <TableRowItem header={t("Email ID")} value={email._id} />
            <TableRowItem
              header={t("Source")}
              value={
                sources && sources[email._source.source]
                  ? sources[email._source.source].displayString
                  : email._source.source
              }
            />
            {!!email.highlight &&
              Object.keys(email.highlight).map((k, i) => (
                <TableRowItem
                  key={i}
                  header={t("Matching") + " (" + k + ")"}
                  value={
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {email.highlight[k]
                        .map((t) =>
                          t
                            .replaceAll("\u003cem\u003e", " *** ")
                            .replaceAll("\u003c/em\u003e", " *** ")
                        )
                        .join("\n\n")}
                    </div>
                  }
                />
              ))}
            <TableRowItem
              header={t("Email Date")}
              value={email._source.dates.emailDate}
            />
            <TableRowItem header={t("Subject")} value={email._source.subject} />
            <TableRowItem
              header={t("From Email Address")}
              value={email._source.from_email_address}
            />
            <TableRowItem
              header={t("To Email Address")}
              value={email._source.to_email_address}
            />
            <TableRowItem
              header={t("Reply-To Email Address")}
              value={email._source.replyto_email_address}
            />
            {email._source.attachments.map((a, i) => (
              <TableRowItem
                key={i}
                header={t("Attachment") + " #" + (i + 1)}
                value={
                  <>
                    <Link to={["/md5", a.md5, "report"].join("/")}>
                      <LinkWrap>{a.md5}</LinkWrap>
                    </Link>{" "}
                    - {a.filename}
                  </>
                }
              />
            ))}
            {email._source.cryptocurrency_addresses.map((c, i) => (
              <TableRowItem
                key={i}
                header={t("Crypto Address") + " #" + (i + 1)}
                value={
                  c.cryptoCurrencyAddress + " (" + c.cryptoCurrencyType + ")"
                }
              />
            ))}
            {/* TODO - have not been able to find sample urls or matched rules */}
            <TableRowItem
              header={t("URLs")}
              value={email._source.urls.map((u, i) => (
                <React.Fragment key={i}>
                  <Box mb={1}>
                    <b>
                      {u.url.match(/^http[s]?:\/\/.+/) ? (
                        <>
                          {t("URL")}
                          {": "}
                          <Link to={["/url", md5(u.url), "report"].join("/")}>
                            <LinkWrap>{u.url}</LinkWrap>
                          </Link>
                        </>
                      ) : (
                        <>
                          {t("Domain")}
                          {": "}
                          <Link to={["/domain", u.url, "report"].join("/")}>
                            <LinkWrap>{u.url}</LinkWrap>
                          </Link>
                        </>
                      )}
                    </b>
                  </Box>
                  {u.ip_details.length ? (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("IP")}</TableCell>
                          <TableCell>{t("IP Country")}</TableCell>
                          <TableCell>{t("IP Region")}</TableCell>
                          <TableCell>{t("IP Location")}</TableCell>
                          <TableCell>{t("IP City")}</TableCell>
                          <TableCell>{t("IP ASN")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {u.ip_details.map((d, j) => (
                          <TableRow key={[i, j].join("-")}>
                            <TableCell>
                              <Link to={["/ip", d.ip, "report"].join("/")}>
                                <LinkWrap>{d.ip}</LinkWrap>
                              </Link>
                            </TableCell>
                            <TableCell>
                              {d.ip_country ? d.ip_country : t("N/A")}
                            </TableCell>
                            <TableCell>
                              {d.ip_region ? d.ip_region : t("N/A")}
                            </TableCell>
                            <TableCell>
                              {d.ip_location ? d.ip_location : t("N/A")}
                            </TableCell>
                            <TableCell>
                              {d.ip_city ? d.ip_city : t("N/A")}
                            </TableCell>
                            <TableCell>
                              {d.ip_asn ? d.ip_asn : t("N/A")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : null}
                </React.Fragment>
              ))}
            />
            {!!email._source.matchedRules && (
              <TableRowItem
                header={t("Matched Rules")}
                value={
                  <>
                    {email._source.matchedRules.map((r, i) => (
                      <React.Fragment key={i}>
                        <Chip size="small" color="primary" label={r} />{" "}
                      </React.Fragment>
                    ))}
                  </>
                }
              />
            )}
            <TableRowItem
              header={t("Last Hop")}
              value={
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("IP")}</TableCell>
                      <TableCell>{t("IP Country")}</TableCell>
                      <TableCell>{t("IP Region")}</TableCell>
                      <TableCell>{t("IP Location")}</TableCell>
                      <TableCell>{t("IP City")}</TableCell>
                      <TableCell>{t("IP ASN")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Link
                          to={["/ip", email._source.last_hop.ip, "report"].join(
                            "/"
                          )}
                        >
                          <LinkWrap>{email._source.last_hop.ip}</LinkWrap>
                        </Link>
                      </TableCell>
                      <TableCell>
                        {email._source.last_hop.ip_country
                          ? email._source.last_hop.ip_country
                          : t("N/A")}
                      </TableCell>
                      <TableCell>
                        {email._source.last_hop.ip_region
                          ? email._source.last_hop.ip_region
                          : t("N/A")}
                      </TableCell>
                      <TableCell>
                        {email._source.last_hop.ip_location
                          ? email._source.last_hop.ip_location
                          : t("N/A")}
                      </TableCell>
                      <TableCell>
                        {email._source.last_hop.ip_city
                          ? email._source.last_hop.ip_city
                          : t("N/A")}
                      </TableCell>
                      <TableCell>
                        {email._source.last_hop.ip_asn
                          ? email._source.last_hop.ip_asn
                          : t("N/A")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              }
            />
            {email._source.bodies.map((b, i) => (
              <TableRowItem
                key={i}
                header={t("Decoded Body") + " (" + b.contentType + ")"}
                value={
                  <div style={{ whiteSpace: "pre-wrap" }}>{b.decodedBody}</div>
                }
              />
            ))}
            <TableRowItem
              header={t("Raw Email")}
              value={
                <div style={{ whiteSpace: "pre-wrap" }}>
                  {email._source.rawEmail}
                </div>
              }
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function TableRowItem({ header, value }) {
  return (
    <TableRow>
      <TableCell>{header}</TableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  );
}
