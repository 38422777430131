import React, { forwardRef } from "react";
import _ from "lodash";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { useStyles } from "../sharedStyles";
import { TypeReportData } from "../../../../models/report/type/TypeReportData";
import { SubReportType } from "../../../../models/report/type/SubReportType";

import { useTranslation } from "react-i18next";
import { ClipboardValue, ClipboardButton } from "../ClipboardButton";
import { HelpToolTip } from "components/beaver/components/report/common/HelpButton";
import { LinkWrap } from "../LinkWrap";

const md5 = require("md5");

export type DetailsCardProps = {
  type: SubReportType;
  value: string;
  data: TypeReportData;
  setShow: (show: SubReportType) => void; //could have also used Function
  setShowSubView: Function;
  quick?: boolean;
};

export const DetailsCard = forwardRef(
  (
    {
      type,
      value,
      data,
      setShow,
      setShowSubView,
      quick,
    }: DetailsCardProps,
    ref
  ) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
      <Box mb={2} style={{ minHeight: "400px" }}>
        <Card ref={ref} style={{ minHeight: "400px" }}>
          <CardHeader
            className={classes.ch}
            title={t(_.startCase(type.toString()) + " Details")}
          />
          <CardContent>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>{t(_.startCase(type.toString()))}</TableCell>
                    <TableCell>
                      <LinkWrap noBeaverLink>{value}</LinkWrap>
                      <ClipboardButton value={value} />
                    </TableCell>
                  </TableRow>
                  {type.toString() === "domain" ? (
                    <TableRow>
                      <TableCell>{t("Domain") + " MD5"}</TableCell>
                      <TableCell>
                        <ClipboardValue value={md5(value)} />
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {Object.keys(data.subreports)
                    .filter(
                      (s) =>
                        data.subreports[s] && data.subreports[s].reports.length
                    )
                    .map((sys, i) => {
                      return (
                        <TableRow key={"allowsyssub" + i}>
                          <TableCell>
                            <Button
                              onClick={() => setShow(SubReportType[sys])}
                              style={{
                                margin: 0,
                                padding: 0,
                                justifyContent: "left",
                                textAlign: "left",
                                textTransform: [
                                  SubReportType.MOOSE,
                                  SubReportType.BISON,
                                ].includes(SubReportType[sys])
                                  ? "none"
                                  : null,
                              }}
                            >
                              {t("sys." + data.systemNames[sys].toUpperCase())}
                            </Button>
                          </TableCell>
                          <TableCell>
                            <SubSystemList
                              reports={data.subreports[sys].reports}
                              setShow={setShow}
                              setShowSubView={setShowSubView}
                              system={SubReportType[sys]}
                            />
                            <PhishingKitSubsystemListItem
                              data={data}
                              sys={SubReportType[sys]}
                              setShow={setShow}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  <TableRow>
                    {!quick && (
                      <TableCell>
                        {t("Unsupported Systems")}{" "}
                        <HelpToolTip help="Results for these systems are not applicable for this report type" />
                      </TableCell>
                    )}
                    <TableCell
                      colSpan={quick ? 2 : 1}
                      style={quick ? { padding: "1.5em" } : {}}
                    >
                      {quick ? t("Loading") + " " : ""}
                      {data.allowedSystems
                        .filter(
                          (s) =>
                            !(
                              data.subreports[s] &&
                              data.subreports[s].reports.length
                            )
                        )
                        .map((w) => t("sys." + w))
                        .join(", ")}
                      {quick && <LinearProgress />}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    );
  }
);

export type SubSystemListProps = {
  reports;
  system: SubReportType;
  setShow: (show: SubReportType) => void;
  setShowSubView: Function;
};

export function SubSystemList({
  reports,
  system,
  setShow,
  setShowSubView,
}: SubSystemListProps) {
  const { t } = useTranslation();
  return (
    <>
      {reports.map((r, i) => {
        return (
          <Box display="inline" mr={2} key={i}>
            <Badge
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              badgeContent={r.data.length}
              color="secondary"
            >
              <Button
                disabled={r.data.length === 0}
                onClick={() => {
                  setShow(system);
                  setShowSubView(r.typeName);
                }}
                style={{
                  margin: 0,
                  padding: 0,
                  justifyContent: "left",
                  textAlign: "left",
                }}
              >
                {r.typeName}
                {/* Put in translations.json e.g. tooltip_mt_chetamon_events as key and the help will populate */}
                {!!r.data.length &&
                  _.snakeCase("tooltip." + r.typeName) !==
                    t(_.snakeCase("tooltip." + r.typeName)) && (
                    <HelpToolTip
                      help={t(_.snakeCase("tooltip." + r.typeName))}
                    />
                  )}
              </Button>
            </Badge>
          </Box>
        );
      })}
    </>
  );
}

// no need to export this / bring to another file since it's only used right here
function PhishingKitSubsystemListItem({
  sys,
  data,
  setShow,
}: {
  data: any;
  sys: SubReportType;
  setShow: (show: SubReportType) => void;
}) {
  if ("MOOSE" === sys)
    return (
      <Box display="inline" mr={2}>
        <Badge
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          badgeContent={data.subreports[sys]?.phishingInfo?.phishingKits.length}
          color="secondary"
        >
          <Button
            disabled={!data.subreports[sys]?.phishingInfo?.phishingKits.length}
            onClick={() => setShow(sys)}
            style={{
              margin: 0,
              padding: 0,
              justifyContent: "left",
              textAlign: "left",
            }}
          >
            PHISHING KIT
          </Button>
        </Badge>
      </Box>
    );
  else return null;
}
