import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { fetchSources, sourceSelector } from "slices/sources";
import { fetchTags, tagsSelector } from "slices/tags";

import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GetAppIcon from "@material-ui/icons/GetApp";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {
  isInternal,
  isExternal,
  MD5ReportSummarySampleDownloadLink,
  MD5ReportSummarySourceDownloadReportLink,
  MD5ReportSummarySourceDownloadLink,
  setTagsJSON,
  TagListJSONArray,
  isGeekweek,
} from "../../../helpers/ServerProperties";

import {
  md5ReportModel,
  sampleSourceToObject,
  sampleSummaryChildSample,
  sampleSummaryParentSample,
  sampleSummarySourceData,
} from "../../../models/report/md5/md5ReportModel";

import { HashView } from "./views/HashView";
import { HashView2 } from "./views/HashView2";
import { md5ReportType } from "../../../models/report/md5/md5ReportModel";

import { useStyles } from "../common/sharedStyles";

import { SimpleTableView } from "../common/SimpleTableView";
import { cloneDeep } from "lodash";
import { TagChip } from "../tag/views/TagChip";
import { UserTag } from "../../../models/report/md5/tags";
import { SamplePreviewModal } from "./views/PreviewSampleView";

import { ClipboardValue } from "../common/ClipboardButton";
import { BearErrorType, hasBearError } from "components/beaver/models/report/md5/bearModel";
import { ErrorLabel } from "../common/ErrorLabel";

// import useAppLayout from "commons/components/hooks/useAppLayout";

//typeComplitions lists what analyzed what not (1 or 0) - possible types is all of them and types is the ones done
// actually, mfsReportStatus, states shows all of it (analysis done / analysis not done, errors and warnings)
// parent samples, child samples exist

// there are different types of report families:
//  - BEAR Report , Fire Eye Report, (Dynamic)

// TODO -trasnlations (e.g. should be Rapport de MD5, not MD5 Rapport)
// TODO - link the md5 to another report
// TODO - loading bar while axios loading (might be tricky)
// TODO - priority star (how to tell what's in priority?)
// TODO - add badge to the Parent / Child Samples
// TODO - add the question mark to the sourcesmap - find out hwo to get sources map (http://localhost:9001/compliance#ext)
// TODO - add the views shown & not shown in summary view

export type SampleSummaryProps = {
  sampleSummary: md5ReportModel;
  sha1?: string;
  sha256?: string;
  setShowPriority: Function;
  showPriority: boolean;
  canAddPriority: boolean;
};

export function SampleSummary({
  sampleSummary,
  sha1,
  sha256,
  setShowPriority,
  showPriority,
  canAddPriority,
}: SampleSummaryProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { sources } = useSelector(sourceSelector);
  const { tags } = useSelector(tagsSelector);
  const [userTags, setUserTags] = React.useState(
    sampleSummary.userTags ? sampleSummary.userTags : []
  );
  const [saving, setSaving] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [showTagList, setShowTagList] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  // will run on first mount
  useEffect(() => {
    dispatch(fetchSources(sources));
  }, [dispatch, sources]);

  useEffect(() => {
    dispatch(fetchTags(tags));
  }, [dispatch, tags]);

  const notAnalyzed = sampleSummary.malwareReport.possibleTypes.filter(
    (val) => !sampleSummary.malwareReport.types.includes(val)
  );

  const errors = sampleSummary.malwareReport.typeCompletions
    ? sampleSummary.malwareReport.errors.concat(
        Object.entries(sampleSummary.malwareReport.typeCompletions)
          .filter(([, value]) => value === -1)
          .map(([key, value]) => ({
            errorMessage: key + " report is unavailable",
          }))
      )
    : null;

  if (sampleSummary.loaded === false) return null;
  else {
    return (
      //TODO - show legend and colorize based on date recieved
      // grid breakpoints:  https://material-ui.com/customization/breakpoints/
      <>
        <SamplePreviewModal
          md5={sampleSummary.relationGraph.md5}
          canPreview={sampleSummary.malwareReport.canPreview}
          showModal={showModal}
          setShowModal={setShowModal}
        />
        <Box>
          <Grid container spacing={2}>
            <Grid
              item
              xs={
                sampleSummary.malwareReport.reports[md5ReportType.Hash]?.[0]
                  ? 12
                  : null
              }
              md={
                sampleSummary.malwareReport.reports[md5ReportType.Hash]?.[0]
                  ? 6
                  : null
              }
            >
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title={t("Sample Summary")}
                  className={classes.ch}
                />
                <CardContent>
                  <Box mb={2} ml={1} style={{ display: "flex" }}>
                    {canAddPriority && (
                      <Box mr={2}>
                        <Button
                          className={classes.nm}
                          color="primary"
                          variant="contained"
                          onClick={() => setShowPriority(!showPriority)}
                        >
                          {t("Add to Priority")}
                        </Button>
                      </Box>
                    )}
                    {Object.keys(sampleSummary.malwareReport.reports).length &&
                    !isExternal() &&
                    !isInternal() &&
                    !isGeekweek() ? (
                      <Box mr={2}>
                        <Button
                          className={classes.nm}
                          color="primary"
                          variant="contained"
                          href={MD5ReportSummarySourceDownloadReportLink(
                            sampleSummary.relationGraph.md5
                          )}
                          target="_blank"
                        >
                          {t("Download All Reports")}
                        </Button>
                      </Box>
                    ) : null}
                    {sampleSummary.malwareReport.canPreview ? (
                      <Box mr={2}>
                        <Button
                          id="preview_sample"
                          className={classes.nm}
                          color="primary"
                          variant="contained"
                          onClick={() => setShowModal(true)}
                        >
                          {t("Preview sample")}
                        </Button>
                      </Box>
                    ) : null}
                  </Box>
                  <TableContainer>
                    <Table size="small">
                      <TableBody>
                        {sha1 ? (
                          <TableRow>
                            <TableCell>SHA1</TableCell>
                            <TableCell>{sha1}</TableCell>
                          </TableRow>
                        ) : null}
                        {sha256 ? (
                          <TableRow>
                            <TableCell>SHA256</TableCell>
                            <TableCell>{sha256}</TableCell>
                          </TableRow>
                        ) : null}
                        <TableRow>
                          <TableCell>MD5</TableCell>
                          <TableCell>
                            <ClipboardValue
                              value={sampleSummary.relationGraph.md5}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("Extension")}</TableCell>
                          <TableCell>
                            {sampleSummary.malwareReport.sampleType}
                          </TableCell>
                        </TableRow>
                        {Object.keys(sampleSummary.malwareReport.reports)
                          .length ? null : (
                          <TableRow>
                            <TableCell>{t("Notice")}</TableCell>
                            <TableCell>
                              <b>{t("samplesummary.analysisnotdone")}</b>
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell>{t("Not Analysed")}</TableCell>
                          <TableCell>
                            {/* The logic here is that very most likely the TK_YARA will appear */}
                            {notAnalyzed
                              .filter((n) => "TK_YARA" !== n)
                              .join(", ")}
                          </TableCell>
                        </TableRow>
                        {/* errors will usually look like this:
                    "errors": [
                      {
                        "at": {},
                        "bt": "REPORT_PARSE_ERROR",
                        "errorMessage": "Zip does not exist"
                      }
                    ],
                */}
                        <TableRow>
                          <TableCell>{t("Errors / Warnings")}</TableCell>
                          <TableCell>
                            {!!errors?.length
                              ? errors
                                  .map((e) =>
                                    e.errorMessage
                                      ? e.errorMessage
                                      : JSON.stringify(e)
                                  )
                                  .join(", ")
                              : t("None")}
                          </TableCell>
                        </TableRow>
                        {sampleSummary.generatedTags ? (
                          <TableRow>
                            <TableCell>{t("Generated Tags")}</TableCell>
                            <TableCell>
                              {sampleSummary.generatedTags.map((t, i) => (
                                <Box key={i} className={classes.inlineTags}>
                                  <Badge
                                    badgeContent={
                                      tags && tags[t.family.toLowerCase()]
                                        ? tags[t.family.toLowerCase()].tagcount
                                        : null
                                    }
                                    color="secondary"
                                    max={999}
                                  >
                                    <TagChip tag={t} />
                                  </Badge>
                                </Box>
                              ))}
                            </TableCell>
                          </TableRow>
                        ) : null}
                        {sampleSummary.userTags ? (
                          <TableRow>
                            <TableCell>{t("User Tags")}</TableCell>
                            <TableCell>
                              {userTags
                                ? userTags.map((t, i) => (
                                    <Box key={i} className={classes.inlineTags}>
                                      <Badge
                                        badgeContent={
                                          tags && tags[t.family.toLowerCase()]
                                            ? tags[t.family.toLowerCase()]
                                                .tagcount
                                            : null
                                        }
                                        color="secondary"
                                        max={999}
                                      >
                                        <TagChip
                                          tag={t}
                                          onDeleteFn={() =>
                                            deleteTag(
                                              sampleSummary.relationGraph.md5,
                                              userTags,
                                              setUserTags,
                                              t,
                                              setSaving,
                                              setError
                                            )
                                          }
                                        />
                                      </Badge>
                                    </Box>
                                  ))
                                : null}
                              {saving ? <CircularProgress /> : null}
                              {error ? t("An error occured") : null}
                              {showTagList ? (
                                <ShowTagList
                                  setError={setError}
                                  setShowTagList={setShowTagList}
                                  userTags={userTags}
                                  setSaving={setSaving}
                                  md5={sampleSummary.relationGraph.md5}
                                  setUserTags={setUserTags}
                                />
                              ) : (
                                <Chip
                                  icon={<AddCircleOutlineIcon />}
                                  label={t("Add Tag")}
                                  onClick={() => setShowTagList(true)}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
            {sampleSummary.malwareReport.reports[md5ReportType.Hash]?.[0] && (
              <Grid item xs={12} md={6}>
                {sampleSummary.malwareReport.reports[md5ReportType.Hash][0][
                  "PEHash"
                ] ? (
                  <HashView
                    hashReport={
                      sampleSummary.malwareReport.reports[md5ReportType.Hash][0]
                    }
                  />
                ) : (
                  <HashView2
                    hashReport={
                      sampleSummary.malwareReport.reports[md5ReportType.Hash][0]
                    }
                  />
                )}
              </Grid>
            )}
          </Grid>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <SummarySources
                  md5Sources={sampleSummary.malwareReport.sources}
                  md5={sampleSummary.relationGraph.md5}
                />
                {!!sampleSummary.urlMD5DownloadedFrom &&
                  !!sampleSummary.urlMD5DownloadedFrom.length && (
                    <Box mt={2}>
                      <Card style={{ height: "100%" }} elevation={3}>
                        <CardHeader
                          title={t("Downloaded From")}
                          className={classes.ch}
                        />
                        <CardContent>
                          <SimpleTableView
                            headers={[
                              {
                                title: "Requested URL",
                                field: "urlMd5",
                                render: (r) => (
                                  <Link
                                    className={classes.breakword}
                                    to={["/url", r.urlMd5, "report"].join("/")}
                                  >
                                    <LinkWrap>
                                      {r.url ? r.url : r.urlMd5}
                                    </LinkWrap>
                                  </Link>
                                ),
                              },
                              { title: "Added On", field: "addedOn" },
                            ]}
                            data={cloneDeep(sampleSummary.urlMD5DownloadedFrom)}
                          />
                        </CardContent>
                      </Card>
                    </Box>
                  )}
              </Grid>
              <Grid item xs={12} md={6}>
                <RelatedSamples
                  parentSamples={sampleSummary.malwareReport.parentSamples}
                  childSamples={sampleSummary.malwareReport.childSamples}
                  errors={sampleSummary.malwareReport.errors}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  }
}

function ShowTagList({
  setError,
  setShowTagList,
  userTags,
  setSaving,
  md5,
  setUserTags,
}) {
  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};
  const [tags, setTags] = React.useState([]);
  const [newTag, setNewTag] = React.useState(null);
  const [newColour, setNewColour] = React.useState("");
  const { t } = useTranslation();

  React.useEffect(() => {
    Axios.get(TagListJSONArray(), {
      auth:
        user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    }).then(
      (res) => {
        if (Array.isArray(res.data)) {
          setTags(res.data.sort());
        } else {
          console.log("Error: " + JSON.stringify(res));
          setError(true);
        }
      },
      (err) => {
        console.log(err);
        setError(true);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setError]);

  return (
    <div
      style={{
        verticalAlign: "middle",
        marginLeft: "1em",
        marginTop: "1vh",
      }}
    >
      <Autocomplete
        freeSolo
        disableClearable
        id="combo-box-demo"
        options={tags}
        style={{ width: 300 }}
        onChange={(e, v) => setNewTag(v)}
        onInputChange={(e, v) => setNewTag(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Add Tag")}
            variant="outlined"
            value={newTag}
          />
        )}
      />

      {newTag && newTag.trim() && !tags.includes(newTag) ? (
        <span>
          {t("Colour ")}
          {["red", "orange", "green", "blue", "purple", "black"].map((c, i) => (
            <span
              key={i}
              style={
                c === newColour
                  ? {
                      backgroundColor: c,
                      paddingRight: "2em",
                      border: "1px solid black",
                      cursor: "pointer",
                    }
                  : {
                      backgroundColor: c,
                      paddingRight: "2em",
                      cursor: "pointer",
                    }
              }
              onClick={() => {
                if (c === newColour) {
                  setNewColour("");
                } else {
                  setNewColour(c);
                }
              }}
            ></span>
          ))}
        </span>
      ) : null}

      {newTag && newTag.trim() ? (
        <SaveOutlinedIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            let submit = {
              tags: userTags
                .map((t) => ({ name: t.family, colour: t.colour }))
                .concat([{ name: newTag, colour: newColour }]),
            };

            addOrDeleteTagPostActions(
              setSaving,
              setError,
              md5,
              submit,
              setUserTags
            );
          }}
        />
      ) : null}
      <CancelOutlinedIcon
        style={{ cursor: "pointer" }}
        onClick={() => setShowTagList(false)}
      />
    </div>
  );
}

const deleteTag = (
  md5: string,
  userTags: UserTag[],
  setUserTags: Function,
  tag: UserTag,
  setSaving: Function,
  setError: Function
) => {
  let submit = {
    tags: userTags
      .filter((t) => t.tagID !== tag.tagID)
      .map((t) => ({ name: t.family, colour: t.colour })),
  };
  addOrDeleteTagPostActions(setSaving, setError, md5, submit, setUserTags);
};

function addOrDeleteTagPostActions(
  setSaving: Function,
  setError: Function,
  md5: string,
  submit: { tags: { name: string; colour: string }[] },
  setUserTags: Function
) {
  setSaving(true);
  setError(false);
  // now need to Axios Post this and make the return setUserTags
  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  Axios.post(setTagsJSON(md5), submit, {
    auth:
      user.server && !user.server.includes("localhost")
        ? {
            username: user ? user.username : null,
            password: user
              ? user.onetime
                ? // eslint-disable-next-line no-eval
                  window.atob(eval(window.atob(user.onetime))).substr(13)
                : null
              : null,
          }
        : null,
  }).then(
    (res) => {
      if (Array.isArray(res.data)) {
        setUserTags(res.data);
        setSaving(false);
      } else {
        console.log("Error: " + JSON.stringify(res));
        setSaving(false);
        setError(true);
      }
    },
    (err) => {
      console.log(err);
      setSaving(false);
      setError(true);
    }
  );
}

export type SummarySourcesProps = {
  md5Sources: sampleSummarySourceData;
  md5: string;
};

// sources
function SummarySources({ md5Sources, md5 }: SummarySourcesProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { sources } = useSelector(sourceSelector);
  const [sortedMd5Sources, setSortedMd5Sources] = React.useState([]);

  React.useEffect(() => {
    const sortedSources = Object.keys(md5Sources).map((val, i) =>
      sampleSourceToObject(val)
    );
    sortedSources.sort((b, a) => +a.receivedDate - +b.receivedDate);
    setSortedMd5Sources(sortedSources);
  }, [md5Sources]);

  return (
    <Card style={{ height: "100%" }} elevation={3}>
      <CardHeader title={t("Sources")} className={classes.ch} />
      <CardContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t("Source")}</TableCell>
                <TableCell>{t("Date Received")}</TableCell>
                {!isExternal() && !isInternal() && !isGeekweek() ? (
                  <TableCell></TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedMd5Sources.map((sourceObj, i) => (
                <TableRow key={i}>
                  <TableCell>
                    {sources
                      ? sources[sourceObj.source]
                        ? sources[sourceObj.source]["displayString"]
                        : sourceObj.source
                      : sourceObj.source}
                  </TableCell>

                  <TableCell>
                    {sourceObj.receivedDate
                      ? sourceObj.receivedDate
                      : "unknown"}
                  </TableCell>
                  {!isExternal() && !isInternal() && !isGeekweek() ? (
                    <TableCell>
                      {/* TODO - the download causes propmts to come up, should not */}
                      <Tooltip title={t("Download")} arrow>
                        <Button
                          href={MD5ReportSummarySourceDownloadLink(
                            md5,
                            sourceObj.receivedDate,
                            sourceObj.source
                          )}
                          target="_blank"
                          color="primary"
                        >
                          <GetAppIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

type RelatedSamplesProps = {
  parentSamples: sampleSummaryParentSample[];
  childSamples: sampleSummaryChildSample[];
  errors: any[];
};

type TabColor = {
  color?: string;
};

// parent samlpes and childsamples
function RelatedSamples({ parentSamples, childSamples, errors }: RelatedSamplesProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [md5RelatedTab, setMd5RelatedTab] = React.useState(0);
  const [disableParentTab, setDisableParentTab] = React.useState(false);
  const [parentTabColor, setParentTabColor] = React.useState<TabColor>({});
  const [disableChildTab, setDisabledChildTab] = React.useState(false);
  const [childTabColor, setChildTabColor] = React.useState<TabColor>({});

  const switchRelatedTabs = (event, newValue) => {
    setMd5RelatedTab(newValue);
  };

  useEffect(() => {
    if (!parentSamples.length) {
      setDisableParentTab(true);
      setParentTabColor({ color: "lightgrey" });
      setMd5RelatedTab(1);
    } else if (!childSamples.length) {
      setDisabledChildTab(true);
      setChildTabColor({ color: "lightgrey" });
      setMd5RelatedTab(0);
    }
  }, [parentSamples.length, childSamples.length]);

  if (!parentSamples.length && !childSamples.length) {
    return (
      <Card style={{ height: "100%" }} elevation={3}>
        <CardHeader title={t("Related Samples")} className={classes.ch} />
        <CardContent>
          {hasBearError(errors, BearErrorType.RELATED_SAMPLES_TIMEOUT) 
            ? <ErrorLabel text={t("bear_error_related_samples_timeout")} /> 
            : null}
          {t("No Child or Parent Samples")}
        </CardContent>
      </Card>
    );
  } else {
    return (
      <Card style={{ height: "100%" }} elevation={3}>
        <CardHeader title={t("Related Samples")} className={classes.ch} />
        <CardContent>
          {hasBearError(errors, BearErrorType.RELATED_SAMPLES_TIMEOUT) 
              ? <ErrorLabel text={t("bear_error_related_samples_timeout")} /> 
              : null}
          <Tabs value={md5RelatedTab} onChange={switchRelatedTabs}>
            <Tab
              label={t("Parent Samples")}
              disabled={disableParentTab}
              style={parentTabColor}
            />
            <Tab
              label={t("Child Samples")}
              disabled={disableChildTab}
              style={childTabColor}
            />
          </Tabs>
          <SamplesView
            value={md5RelatedTab}
            type="parent"
            samples={parentSamples}
            index={0}
          />
          <SamplesView
            value={md5RelatedTab}
            type="child"
            samples={childSamples}
            index={1}
          />
        </CardContent>
      </Card>
    );
  }
}

type SamplesViewProps = {
  value: number;
  type: "parent" | "child";
  samples: sampleSummaryParentSample[] | sampleSummaryChildSample[];
  index: number;
};

function SamplesView({ value, index, type, samples }: SamplesViewProps) {
  const { t } = useTranslation();
  return (
    <div hidden={value !== index}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("page.reportview.sampletype." + type)}</TableCell>
              <TableCell>{t("Detail")}</TableCell>
              <TableCell>{t("Date Received")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {samples.map((val, i) => {
              return (
                <TableRow key={"samplesview-" + index + "-" + i}>
                  <TableCell>
                    {!isExternal() && !isInternal() && !isGeekweek() ? (
                      <Tooltip title={t("Download")} arrow>
                        <Button
                          href={MD5ReportSummarySampleDownloadLink(val.md5)}
                          target="_blank"
                          color="primary"
                        >
                          <GetAppIcon />
                        </Button>
                      </Tooltip>
                    ) : null}
                    <Link to={"/md5/" + val.md5 + "/report"}>
                      <LinkWrap>{val.md5}</LinkWrap>
                    </Link>
                  </TableCell>
                  <TableCell>
                    {"REQUESTED" === val.relationshipDetail
                      ? t(
                          "page.reportview.sampletype." +
                            type +
                            "." +
                            val.relationshipDetail
                        )
                      : t(
                          "page.reportview.sampletype." + val.relationshipDetail
                        )}
                  </TableCell>
                  <TableCell>{val.receivedDate}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
