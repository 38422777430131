import React, { useState } from "react";
import { Link } from "react-router-dom";
import Axios, { Method } from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  isExternal,
  isInternal,
  isLab,
  isLocal,
  isStaging,
  UploadSample,
  isGeekweek,
} from "../../helpers/ServerProperties";
import { useStyles } from "../report/common/sharedStyles";
import { useTranslation } from "react-i18next";
import { LinkWrap } from "../report/common/LinkWrap";

//TODO - this should make the localstorage null if it can't parse- put this somewhere and export
const user = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser"))
  : {};

export function SampleUpload() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [files, setFiles]: [FileList, React.Dispatch<FileList>] =
    React.useState(null);
  const [sourceID, setSourceID] = React.useState(
    isExternal()
      ? "mudi"
      : isLocal()
      ? "ev"
      : isGeekweek()
      ? "mugw"
      : "muos"
  );
  const [errorText, setErrorText] = useState(null);
  const [data, setData]: [any, React.Dispatch<any>] = useState([]);
  const [loading, setLoading] = useState(false);

  // it sends each file seperately
  // the form data is sourceID (note capital), and fileUpload
  const submitSample = () => {
    setLoading(true);
    //Axios post similar to the search

    const promises = Array.from(files).map((file) => {
        let data = new FormData();
        data.append("sourceID", sourceID);
        data.append("fileUpload", file);
        return Axios({
          method: UploadSample().method as Method,
          url: UploadSample().url,
          data: data,
          headers: { "Content-Type": "multipart/form-data" },
          auth:
            user.server && !user.server.includes("localhost")
              ? {
                  username: user ? user.username : null,
                  password: user
                    ? user.onetime
                      ? // eslint-disable-next-line no-eval
                        window.atob(eval(window.atob(user.onetime))).substr(13)
                      : null
                    : null,
                }
              : null,
        }).catch(error => {
          let statusCode = error.response.status;
          let response = undefined;
          try {
            response = JSON.parse(error.response.data);
          } catch {
            response = {errorMessage: error.response.data}
          }
          return { 
            data: {
              fileName: file.name,
              md5: response.sampleMd5,
            },
            error: {
              statusCode: statusCode,
              message: response.errorMessage,
            }
          };
        });
      });
    
    Axios.all(promises)
      .then(
        Axios.spread((...res) => {
          // all you are doing here is taking all the responses and adding the
          // data portions to the data array
          setData(res.map((r) => ({...r["data"], error: r["error"]})));
          setLoading(false);
        })
      );
  };
  return (
    <>
      {!isExternal() && !isInternal() && (
        <Link to="/upload/sample/list" style={{ textDecoration: "none" }}>
          <Button>{t("View Uploaded Samples")}</Button>
        </Link>
      )}
      <Card>
        <Box minHeight={{ sm: "31rem" }}>
          <CardHeader title={t("Sample Upload")} className={classes.ch} />
          <CardContent>
            {t("Sample Source")}:
            <br />
            <Select
              name="sourceID"
              id="sourceID"
              value={sourceID}
              onChange={(event) => setSourceID("" + event.target.value)}
            >
              {isLab() || isLocal() || isStaging() ? (
                <MenuItem value="ev">{t("Evaluation")}</MenuItem>
              ) : null}

              {isLab() || isInternal() || isGeekweek() ? (
                <MenuItem value="muos">
                  {t("Manual Upload") + " Open Source"}
                </MenuItem>
              ) : null}
              {isLab() || isInternal() || isGeekweek() ? (
                <MenuItem value="mucc">{t("Manual Upload") + " CCCS"}</MenuItem>
              ) : null}

              {isLab() || isInternal() || isGeekweek() || isExternal() ? (
                <MenuItem value="mudi">
                  {t(
                    "Manual Upload Disclosed Information Without Expectation of Service"
                  )}
                </MenuItem>
              ) : null}
              {isGeekweek() ? (
                <MenuItem value="mugw">{t("Manual Upload GeekWeek")}</MenuItem>
              ) : null}
            </Select>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignContent="space-between"
              minHeight={{ sm: "22rem" }}
            >
              <Box>
                <p>{t("Files")}:</p>
                {!files ? (
                  <>
                    <input
                      style={{ display: "none" }}
                      id="fileUpload"
                      multiple
                      type="file"
                      onChange={(e) => {
                        setFiles(e.currentTarget.files);
                      }}
                    />
                    <label htmlFor="fileUpload">
                      <Button component="span" variant="contained">
                        {t("Choose Files")}
                      </Button>
                    </label>
                  </>
                ) : null}
                {files ? (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th>{t("Name")}</th>
                          <th>{t("Size")}</th>
                          <th>{t("Type")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.from(files).map((f, i) => (
                          // name, size, type are available
                          <tr key={i}>
                            <td>{f["name"]}</td>
                            <td>{f["size"]}</td>
                            <td>{f["type"]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : null}
              </Box>
              <Box mt={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => submitSample()}
                  disabled={
                    !files ||
                    !files.length ||
                    loading ||
                    (data && data.length > 0)
                  }
                >
                  {t("Submit")}
                </Button>
                <Button
                  onClick={() => {
                    setFiles(null);
                    setSourceID("muos");
                    setData([]);
                    setErrorText(null);
                  }}
                >
                  {t("Clear")}
                </Button>
              </Box>
              {errorText ? (
                <Box m={2} style={{ color: "darkred" }}>
                  <b>{t("An error occured")}: </b>
                  {errorText}
                </Box>
              ) : null}
              {loading ? (
                <Box m={2}>
                  <LinearProgress />
                </Box>
              ) : null}
              {data && data.length ? (
                <Box mt={2}>
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th>{t("File Name")}</th>
                          <th style={{ paddingLeft: "8px" }}>{t("MD5")}</th>
                          <th style={{ paddingLeft: "8px" }}>{t("Status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((d, i) => (
                          // name, size, type are available
                          <tr key={i}>
                            <td>{d.fileName}</td>
                            <td style={{ paddingLeft: "8px" }}>
                              {d.md5 ? (
                                <Link to={["/md5", d.md5, "report"].join("/")}>
                                  <LinkWrap>{d.md5}</LinkWrap>
                                </Link>
                              ) : null }
                            </td>
                            <td style={{ paddingLeft: "8px" }}>
                              {d.error ? (
                                <b style={{ color: d.error.statusCode == 409 ? "darkblue" : "darkred" }}>
                                  {d.error.message}
                                </b>
                              ) : (
                                <b style={{ color: "darkgreen" }}>
                                  {t("Submitted")}!
                                </b>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                </Box>
              ) : null}
            </Box>
          </CardContent>
        </Box>
      </Card>
    </>
  );
}
