import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { md5ReportSelector, fetchReport } from "slices/md5report";

import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Paper,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
//summary
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
// pendora
import ComputerOutlinedIcon from "@material-ui/icons/ComputerOutlined";
// virustotal, shadowserver, yara, metascan - but icon too tacky
import BugReportOutlinedIcon from "@material-ui/icons/BugReportOutlined";
// entropy
import PollOutlinedIcon from "@material-ui/icons/PollOutlined";
//viz
import BlurLinearOutlinedIcon from "@material-ui/icons/BlurLinearOutlined";
//file
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
//fireeye
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
//bear - a server - pet icon didn't look very good
import DnsOutlinedIcon from "@material-ui/icons/DnsOutlined";
import WbSunnyOutlinedIcon from "@material-ui/icons/WbSunnyOutlined";
import FingerprintOutlinedIcon from "@material-ui/icons/FingerprintOutlined";

import {
  md5ReportModel,
  md5ReportType,
  md5ReportTypeMap,
} from "../../../models/report/md5/md5ReportModel";
import { ReportType } from "../../../models/report/reportType";

import { SampleSummary } from "./SampleSummary";
import { VizView } from "./views/VizView";
import { ShadowServerView } from "./views/ShadowServerView";
import { ShadowServerCWView } from "./views/ShadowServerCWView";
import { MetaScanView } from "./views/MetaScanView";
import { FileTypeView } from "./views/FileTypeView";
import { YaraView } from "./views/YaraView";
import { VirusTotalView } from "./views/VirusTotalView";
import { HashView } from "./views/HashView";
import { HashView2 } from "./views/HashView2";
import { BearView } from "./views/BearView";
import { EntropyView } from "./views/EntropyView";
import { PendoraView } from "./views/PendoraView";
import { FireEyeView } from "./views/FireEyeView";
import { SunbeltView } from "./views/SunbeltView";
import { SubgroupSummaryView } from "./views/SubgroupSummaryView";
import { ALView } from "./views/ALView";
import { SamplePreviewModal } from "./views/PreviewSampleView";

//TODO - take in the param and set the param based on the view required (e.g. view=TK_VIZ)
//    and use the above value accodingly to show the view
import { useStyles } from "../common/sharedStyles";
import { ParsedError, parseErrorTitle } from "../common/ParsedError";

import { useTranslation } from "react-i18next";
import { AddToPriority } from "../priority/AddToPriority";
import {
  isExternal,
  isInternal,
  isGeekweek,
  MD5ReportSummarySourceDownloadReportLink,
} from "components/beaver/helpers/ServerProperties";
import { getButtonBadgeNum } from "../../../helpers/getButtonBadgeNum";

export type MD5ReportProps = {
  value: string;
  sha1?: boolean;
  sha256?: boolean;
};

export function MD5Report({ value, sha1, sha256 }: MD5ReportProps) {
  const [retry, setRetry] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  // hide menu if the breakpoint is small or less
  const initialHiddenMenu = useMediaQuery(theme.breakpoints.down("xl"));
  //const initialHiddenMenu = useMediaQuery(theme.breakpoints.down("sm"));

  // initialize the redux hook
  const dispatch = useDispatch();

  const { responseData, reportType, error } = useSelector(md5ReportSelector);

  const [showDontRefreshWarning, setShowDontRefreshWarning] =
    React.useState(false);

  // will run on first mount
  useEffect(() => {
    // essentially, a sha1 or sha256 report returns the same MD5 report (not a redirect)
    //TODO - lots of errors in e.g. BEAR, PENDORA, Hash, etc. the SHA1 and SHA256 samples (which are basically MD5 samples)
    if (sha1) dispatch(fetchReport(ReportType.SHA1, value));
    else if (sha256) dispatch(fetchReport(ReportType.SHA256, value));
    else dispatch(fetchReport(ReportType.MD5, value));
  }, [dispatch, value, sha1, sha256, retry]);

  useEffect(() => {
    setTimeout(() => setShowDontRefreshWarning(true), 10000);
  }, []);

  // start by just showing the summary once loaded
  if (
    !error &&
    (!responseData || !reportType || reportType !== ReportType.MD5)
  ) {
    // show a loading bar until it loads, and show the error if there is one
    // TODO - translate the loading text
    return (
      <>
        {t("Loading")} ...{" "}
        {showDontRefreshWarning
          ? t("Please do not refresh the page") + " !"
          : null}
        <LinearProgress />
      </>
    );
  } else if (error) {
    // debugger;
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          {/* By incrementing the state value which is monitoired by useEffect, the useEffect is triggered*/}
          {/* when the hash can't be found, don't show the retry as per issue #352 */}
          {error && !error.match("file could be found") ? (
            <Button size="small" onClick={() => setRetry(retry + 1)}>
              {t("Retry")}
            </Button>
          ) : null}
        </CardActions>
      </Card>
    );
  } else {
    return (
      <Box>
        <SystemsView
          data={responseData}
          hideMenu={initialHiddenMenu}
          sha1={sha1 ? value : null}
          sha256={sha256 ? value : null}
        />
      </Box>
    );
  }
}

type ReportButtonGroupProps = {
  type: string;
  types: string[];
  show: string;
  setShow: Function;
  data: md5ReportModel;
  shortForm?: boolean;
  showPriority: boolean;
  setShowPriority: Function;
};

// make a function get label and return e.g. the icon depending on the val or the proper label e.g. VirusTotal shoudl be VT, not VI.
function getShortLabel(val) {
  switch (true) {
    case md5ReportType.Pendora === val:
      return <ComputerOutlinedIcon />;
    case md5ReportType.FileType === val:
      return <DescriptionOutlinedIcon />;
    case md5ReportType.Viz === val:
      return <BlurLinearOutlinedIcon />;
    case md5ReportType.Entropy === val:
      return <PollOutlinedIcon />;
    case md5ReportType.Sunbelt === val:
      return <WbSunnyOutlinedIcon />;
    case md5ReportType.Hash === val:
      return <FingerprintOutlinedIcon />;
    case md5ReportType.ShadowServerCW === val:
      return (
        <>
          <BugReportOutlinedIcon />
          CW
        </>
      );
    case md5ReportType.ShadowServerZ === val:
      return (
        <>
          <BugReportOutlinedIcon />Z
        </>
      );
    case md5ReportType.ShadowServer === val:
    case md5ReportType.MetaSploit === val:
    case md5ReportType.VirusTotal === val:
    case md5ReportType.Yara === val:
      return (
        <>
          <BugReportOutlinedIcon />
          {val.replace("TK_", "").substring(0, 1)}
        </>
      );
    case /^BEAR_/.test(val):
      return (
        <>
          <DnsOutlinedIcon />
          {"ux" === val.substring(10, 12) ? "L" : val.substring(10, 12)}
        </>
      );
    case /^FE_/.test(val):
      return (
        <>
          <VisibilityOutlinedIcon />
          {val.substring(6, 8).replaceAll("_", "")}
        </>
      );
    case /_SUMMARY$/.test(val):
      return <ListAltOutlinedIcon />;
    default:
      return _.toUpper(val.replace("TK_", "").substring(0, 2));
  }
}

// TODO use button variant=contained (to color it) if it is selected
function ReportButtonGroup({
  type,
  types,
  show,
  setShow,
  data,
  shortForm,
  showPriority,
  setShowPriority,
}: ReportButtonGroupProps) {
  // look at the "types", see what type is reqeusted (e.g. static), then look accordingly
  const buttonList = md5ReportTypeMap[type].map((val) => {
    return {
      text: val,
      disabled: !(
        types.includes(val) ||
        ("TK_YARA" === val &&
          data.yaraAssemblyLineRules &&
          data.yaraAssemblyLineRules.length)
      ),
    };
  });

  const summaryText = `${type.toUpperCase()}_SUMMARY`;

  return (
    <>
      {shortForm ? (
        <ReportButton
          key={1}
          getButtonBadgeNum={getButtonBadgeNum}
          text={summaryText}
          data={data}
          shortForm={shortForm}
          setShow={setShow}
          show={show}
          selectedTabStyle={selectedTabStyle}
          getShortLabel={getShortLabel}
          showPriority={showPriority}
          setShowPriority={setShowPriority}
          disabled={false}
        />
      ) : null}
      {buttonList.map((val) => {
        if (shortForm)
          return (
            <ReportButton
              key={val.text}
              getButtonBadgeNum={getButtonBadgeNum}
              text={val.text}
              data={data}
              shortForm={shortForm}
              setShow={setShow}
              show={show}
              selectedTabStyle={selectedTabStyle}
              getShortLabel={getShortLabel}
              showPriority={showPriority}
              setShowPriority={setShowPriority}
              disabled={val.disabled}
            />
          );
        else
          return (
            <ListItem key={val.text}>
              <ReportButton
                getButtonBadgeNum={getButtonBadgeNum}
                text={val.text}
                data={data}
                shortForm={shortForm}
                setShow={setShow}
                show={show}
                selectedTabStyle={selectedTabStyle}
                getShortLabel={getShortLabel}
                showPriority={showPriority}
                setShowPriority={setShowPriority}
                disabled={val.disabled}
              />
            </ListItem>
          );
      })}
    </>
  );
}

type ReportButtonProps = {
  getButtonBadgeNum: Function;
  text: string;
  data: md5ReportModel;
  shortForm: boolean;
  setShow: Function;
  show: string;
  selectedTabStyle: any;
  getShortLabel: Function;
  showPriority: boolean;
  setShowPriority: Function;
  disabled: boolean;
};

function ReportButton({
  getButtonBadgeNum,
  text,
  data,
  shortForm,
  setShow,
  show,
  selectedTabStyle,
  getShortLabel,
  showPriority,
  setShowPriority,
  disabled,
}: ReportButtonProps) {
  const classes = useStyles();
  return (
    <Badge
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      badgeContent={getButtonBadgeNum(text, data)}
      color="secondary"
    >
      <Tooltip title={text.replace("TK_", "")} placement="top" arrow>
        <span>
          <Button
            className={shortForm ? classes.shortFormMenu : classes.nm}
            disabled={disabled}
            color="primary"
            onClick={() => {
              setShow(text);
              setShowPriority(false);
            }}
            style={
              !showPriority && text === show && shortForm
                ? selectedTabStyle
                : null
            }
          >
            {shortForm ? getShortLabel(text) : text.replace("TK_", "")}
          </Button>
        </span>
      </Tooltip>
    </Badge>
  );
}

function TopLevelButton({
  data,
  title,
  text,
  types,
  setShow,
  subMenu,
  setSubMenu,
  setShowPriority,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [badgeContent, setBadgeContent] = useState(0);

  useEffect(() => {
    const typeCount = md5ReportTypeMap[text.toLowerCase()]
      .map((val) => {
        if (types.includes(val)) {
          const num = getButtonBadgeNum(val, data);
          if (num !== null && typeof num !== "undefined") {
            return num;
          }
        }
        return 0;
      })
      .reduce((total, currentVal) => (total += currentVal), 0);
    setBadgeContent(
      "Signature" === text
        ? typeCount + data.yaraAssemblyLineRules?.length
        : typeCount
    );
  }, [data, text, types]);

  return (
    <Badge
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      badgeContent={badgeContent}
      color="secondary"
    >
      <Tooltip title={title} placement="top" arrow>
        <Button
          className={classes.shortFormMenu}
          color="primary"
          onClick={() => {
            setShow(`${title}_SUMMARY`);
            setSubMenu(title);
            setShowPriority(false);
          }}
          style={title === subMenu ? selectedTabStyle : null}
        >
          {t(text)}
        </Button>
      </Tooltip>
    </Badge>
  );
}

// Note:  Buttons were used instead of vertical tabs as the latter doesn't work well with mobile view

// TODO - for menu, keep use hash or GET to determine the initial view (or with router) and change accordingly on a click

// now showing in a left menu that is collapsable - have to move some stuff to summary
//TODO - make download all reports work, make add to priority work
//TODO - Locked?? Errors/Warnings display
//TODO - Move the Errors/Warnings and the Generated Tags / User Tags to Summary!
//TODO - Hover over the buttons should show the entire title (could be cut off esp. BEAR stuff)

export type SystemsViewProps = {
  data: md5ReportModel;
  hideMenu: boolean;
  sha1?: string;
  sha256?: string;
};

// this defines the style of the selected tab.
const selectedTabStyle = {
  backgroundColor: "white",
  border: "1px solid",
  borderBottom: "none",
  borderRadius: "0",
};

function getSubMenu(reportValue, types) {
  if (
    "SIGNATURE_SUMMARY" === reportValue ||
    "STATIC_SUMMARY" === reportValue ||
    "DYNAMIC_SUMMARY" === reportValue
  ) {
    return reportValue.split("_")[0];
  } else if (types.includes(reportValue)) {
    if (Object.values(md5ReportTypeMap.signature).includes(reportValue)) {
      return "SIGNATURE";
    } else if (Object.values(md5ReportTypeMap.static).includes(reportValue)) {
      return "STATIC";
    } else if (Object.values(md5ReportTypeMap.dynamic).includes(reportValue)) {
      return "DYNAMIC";
    }
  }
}

export function SystemsView({
  data,
  hideMenu,
  sha1,
  sha256,
}: SystemsViewProps) {
  const classes = useStyles();
  const { malwareReport, enableAddPriority } = data;
  const { types, quick } = malwareReport;
  const { t } = useTranslation();
  const theme = useTheme();
  const hideTabMenu = useMediaQuery(theme.breakpoints.down("sm"));

  //set the initial menuHidden based on the breakpoint (use width, but pass it from the MD5Report to the SystemsView)
  // if small or xs, we don't want to see the menu initially
  const [menuHidden, setMenuHidden] = useState(hideMenu);
  // this one controls if the add to priority prompt should be shown
  const [showPriority, setShowPriority] = useState(false);
  // this one makes the add to priority go away after adding
  const [addedToPriority, setAddedToPriority] = useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // this will set the hash to sumary if there either isn't one or if it's not valid (not a type of the report)
  React.useEffect(() => {
    if (
      "" === window.location.hash.substring(1) ||
      ("TK_YARA" !== window.location.hash.substring(1) &&
        !types.includes(window.location.hash.substring(1)) &&
        "SIGNATURE_SUMMARY" !== window.location.hash.substring(1) &&
        "STATIC_SUMMARY" !== window.location.hash.substring(1) &&
        "DYNAMIC_SUMMARY" !== window.location.hash.substring(1) &&
        !quick)
    )
      window.location.replace("#SUMMARY");

    if (location.hash) {
      setTheShow(location.hash.substring(1));
      setSubMenu(getSubMenu(location.hash.substring(1), types));
    }
  }, [location, types]);

  // the view to be shown
  const [show, setTheShow] = useState(
    "" === window.location.hash.substring(1) ||
      ("TK_YARA" !== window.location.hash.substring(1) &&
        !types.includes(window.location.hash.substring(1)) &&
        "SIGNATURE_SUMMARY" !== window.location.hash.substring(1) &&
        "STATIC_SUMMARY" !== window.location.hash.substring(1) &&
        "DYNAMIC_SUMMARY" !== window.location.hash.substring(1))
      ? "SUMMARY"
      : window.location.hash.substring(1)
  );

  const [subMenu, setSubMenu] = useState("");
  const menu = getSubMenu(window.location.hash.substring(1), types);
  React.useEffect(() => {
    setSubMenu(
      menu
        ? menu
        : window.location.hash.substring(1) === "TK_YARA"
        ? "SIGNATURE"
        : null
    );
  }, [menu]);

  const setShow = (s) => {
    setTheShow(s);
    // store in browser history,
    navigate(location.pathname + "#" + s);
    // window.location.hash = s;
  };

  // show or hide the menu on the left
  const toggleMenu = () => {
    setMenuHidden(!menuHidden);
  };
  const myRef = useRef(null);
  // switch views and scroll to the view smoothly
  const setView = (view) => {
    setShow(view);
    //window.scrollTo({ top: myRef.current.offsetTop - 50, behavior: "smooth" });
  };

  //TODO - translate the headers below
  return (
    <Box>
      <SamplePreviewModal
        md5={data.relationGraph.md5}
        canPreview={malwareReport.canPreview}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <Box hidden={!menuHidden}>
        <Button onClick={toggleMenu}>
          <MenuIcon />
          &nbsp;Menu
        </Button>
        {hideTabMenu ? null : (
          <>
            <Tooltip title="SUMMARY" placement="top" arrow>
              <Button
                className={classes.shortFormMenu}
                color="primary"
                onClick={() => {
                  setView("SUMMARY");
                  setSubMenu("");
                  setShowPriority(false);
                }}
                style={
                  "SUMMARY" === show && !showPriority ? selectedTabStyle : null
                }
              >
                {t("Summary")}
              </Button>
            </Tooltip>
            {enableAddPriority && !addedToPriority ? (
              <Tooltip title="ADD_TO_PRIORITY" placement="top" arrow>
                <Button
                  className={classes.shortFormMenu}
                  color="primary"
                  onClick={() => {
                    setSubMenu("");
                    setShowPriority(!showPriority);
                  }}
                  style={showPriority ? selectedTabStyle : null}
                >
                  {t("Priority")}
                </Button>
              </Tooltip>
            ) : null}
            <TopLevelButton
              data={data}
              title="SIGNATURE"
              text="Signature"
              types={types}
              subMenu={subMenu}
              setShow={setShow}
              setSubMenu={setSubMenu}
              setShowPriority={setShowPriority}
            />
            <TopLevelButton
              data={data}
              title="STATIC"
              text="Static"
              types={types}
              subMenu={subMenu}
              setShow={setShow}
              setSubMenu={setSubMenu}
              setShowPriority={setShowPriority}
            />
            <TopLevelButton
              data={data}
              title="DYNAMIC"
              text="Dynamic"
              types={types}
              subMenu={subMenu}
              setShow={setShow}
              setSubMenu={setSubMenu}
              setShowPriority={setShowPriority}
            />
            <Tooltip title="EXTERNAL_VT" placement="top" arrow>
              <Button
                className={classes.shortFormMenu}
                color="primary"
                target="_blank"
                href={[
                  "https://www.virustotal.com/gui/file",
                  data.relationGraph.md5,
                  "detection",
                ].join("/")}
              >
                <OpenInNewIcon />
                VT
              </Button>
            </Tooltip>
            <Tooltip title="ALIEN_VAULT" placement="top" arrow>
              <Button
                className={classes.shortFormMenu}
                color="primary"
                target="_blank"
                href={
                  "https://otx.alienvault.com/browse/indicators?q=" +
                  data.relationGraph.md5
                }
              >
                <OpenInNewIcon />
                OTX
              </Button>
            </Tooltip>
            {data.malwareReport.reports[md5ReportType.Hash] &&
            data.malwareReport.reports[md5ReportType.Hash].length ? (
              <Tooltip title="HYBRID_ANALYSIS" placement="top" arrow>
                <Button
                  className={classes.shortFormMenu}
                  color="primary"
                  target="_blank"
                  href={
                    "https://www.hybrid-analysis.com/sample/" +
                    data.malwareReport.reports[md5ReportType.Hash][0]["sha256"]
                  }
                >
                  <OpenInNewIcon />
                  HA
                </Button>
              </Tooltip>
            ) : null}
          </>
        )}
        {/* show indicator that the regular report is still loading if we have the quick view only */}
        {!!data.malwareReport.quick && (
          <Box style={{ display: "inline" }} ml={2}>
            <CircularProgress size="1.5em" /> {t("Loading more data")}...
          </Box>
        )}
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={8} md={3} lg={2} hidden={menuHidden}>
          <Paper>
            <Button onClick={toggleMenu}>
              <MenuOpenIcon />
              &nbsp;Menu
            </Button>
            <List>
              {Object.keys(data.malwareReport.reports).length &&
              !isExternal() &&
              !isInternal() &&
              !isGeekweek() ? (
                <ListItem>
                  <Button
                    className={classes.nm}
                    color="primary"
                    href={MD5ReportSummarySourceDownloadReportLink(
                      data.relationGraph.md5
                    )}
                    target="_blank"
                  >
                    {t("Download All Reports")}
                  </Button>
                </ListItem>
              ) : null}
              {enableAddPriority && !addedToPriority ? (
                <ListItem>
                  <Button
                    className={classes.nm}
                    color="primary"
                    onClick={() => setShowPriority(!showPriority)}
                  >
                    {t("Add to Priority")}
                  </Button>
                </ListItem>
              ) : null}
              {malwareReport.canPreview ? (
                <ListItem>
                  <Button
                    className={classes.nm}
                    color="primary"
                    onClick={() => setShowModal(true)}
                  >
                    {t("Preview sample")}
                  </Button>
                </ListItem>
              ) : null}
              <ListItem>
                <Button
                  className={classes.nm}
                  color="primary"
                  onClick={() => {
                    setView("SUMMARY");
                    setShowPriority(false);
                  }}
                >
                  {t("Summary")}
                </Button>
              </ListItem>
              <ListItem>{t("SIGNATURE-BASED")}:</ListItem>
              <ReportButtonGroup
                type="signature"
                types={types}
                show={show}
                setShow={setView}
                data={data}
                showPriority={showPriority}
                setShowPriority={setShowPriority}
              />
              <ListItem>{t("STATIC")}:</ListItem>
              <ReportButtonGroup
                type="static"
                types={types}
                show={show}
                setShow={setView}
                data={data}
                showPriority={showPriority}
                setShowPriority={setShowPriority}
              />
              <ListItem>{t("DYNAMIC")}:</ListItem>
              <ReportButtonGroup
                type="dynamic"
                types={types}
                show={show}
                setShow={setView}
                data={data}
                showPriority={showPriority}
                setShowPriority={setShowPriority}
              />
              <ListItem>{t("EXTERNAL RESOURCES")}:</ListItem>
              <ListItem>
                <Button
                  className={classes.nm}
                  color="primary"
                  target="_blank"
                  href={[
                    "https://www.virustotal.com/gui/file",
                    data.relationGraph.md5,
                    "detection",
                  ].join("/")}
                >
                  VirusTotal
                </Button>
              </ListItem>
              <ListItem>
                <Button
                  className={classes.nm}
                  color="primary"
                  target="_blank"
                  href={
                    "https://otx.alienvault.com/browse/indicators?q=" +
                    data.relationGraph.md5
                  }
                >
                  AlienVault
                </Button>
              </ListItem>
              {data.malwareReport.reports[md5ReportType.Hash] &&
              data.malwareReport.reports[md5ReportType.Hash].length ? (
                <ListItem>
                  <Button
                    className={classes.nm}
                    color="primary"
                    target="_blank"
                    href={
                      "https://www.hybrid-analysis.com/sample/" +
                      data.malwareReport.reports[md5ReportType.Hash][0][
                        "sha256"
                      ]
                    }
                  >
                    HybridAnalysis
                  </Button>
                </ListItem>
              ) : null}
            </List>
          </Paper>
        </Grid>
        <Grid
          ref={myRef}
          item
          xs={12}
          md={menuHidden ? 12 : 9}
          lg={menuHidden ? 12 : 10}
        >
          {showPriority ? (
            <AddToPriority
              setShowPriority={setShowPriority}
              md5={data.relationGraph.md5}
              setAddedToPriority={setAddedToPriority}
            />
          ) : (
            <>
              {subMenu === "SIGNATURE" ? (
                <Box hidden={!menuHidden}>
                  <ReportButtonGroup
                    type="signature"
                    types={types}
                    show={show}
                    setShow={setView}
                    data={data}
                    shortForm={true}
                    showPriority={showPriority}
                    setShowPriority={setShowPriority}
                  />
                </Box>
              ) : null}
              {subMenu === "STATIC" ? (
                <Box hidden={!menuHidden}>
                  <ReportButtonGroup
                    type="static"
                    types={types}
                    show={show}
                    setShow={setView}
                    data={data}
                    shortForm={true}
                    showPriority={showPriority}
                    setShowPriority={setShowPriority}
                  />
                </Box>
              ) : null}
              {subMenu === "DYNAMIC" ? (
                <Box hidden={!menuHidden}>
                  <ReportButtonGroup
                    type="dynamic"
                    types={types}
                    show={show}
                    setShow={setView}
                    data={data}
                    shortForm={true}
                    showPriority={showPriority}
                    setShowPriority={setShowPriority}
                  />
                </Box>
              ) : null}
              {show !== "" ? (
                <ShowSystemView
                  show={show}
                  setShow={setShow}
                  data={data}
                  sha1={sha1}
                  sha256={sha256}
                  setShowPriority={setShowPriority}
                  showPriority={showPriority}
                  canAddPriority={enableAddPriority && !addedToPriority}
                />
              ) : null}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export type ShowSystemViewProps = {
  show: string;
  data: md5ReportModel;
  sha1?: string;
  sha256?: string;
  setShowPriority: Function;
  setShow: Function;
  showPriority: boolean;
  canAddPriority: boolean;
};

// This function will determine which view is shown
export function ShowSystemView({
  show,
  data,
  sha1,
  sha256,
  setShowPriority,
  setShow,
  showPriority,
  canAddPriority,
}: ShowSystemViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  if (
    md5ReportType.Summary !== show &&
    md5ReportType.Yara !== show &&
    (!!data.malwareReport.reports[show] ||
      !!data?.yaraAssemblyLineRules?.length) &&
    show !== "DYNAMIC_SUMMARY" &&
    show !== "STATIC_SUMMARY" &&
    show !== "SIGNATURE_SUMMARY" &&
    data.malwareReport.reports[show] == null
  ) {
    return (
      <Box pt={4}>
        <Card>
          <CardHeader
            className={classes.ch}
            avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
            title={t("No Report Available")}
          />
          <CardContent>
            <p>
              {t("This sample has not been analyzed for") +
                " " +
                show +
                " " +
                t("so there is no report to display")}
            </p>
          </CardContent>
        </Card>
      </Box>
    );
  } else {
    const existsWithError = data.malwareReport.errors.filter(function (el) {
      return el.at.value === show && !el.bt.includes("TIMEOUT");
    });

    return (
      <div>
        {existsWithError.length > 0 ? (
          <Box marginBottom={2}>
            <Card>
              <CardHeader
                className={classes.ch}
                avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
                title={t("Warning for " + show)}
              />
              <CardContent>
                {t("This analysis completed with errors")}
              </CardContent>
            </Card>
          </Box>
        ) : (
          <></>
        )}
        {getComponent({
          show,
          data,
          sha1,
          sha256,
          setShowPriority,
          setShow,
          showPriority,
          canAddPriority,
        })}
      </div>
    );
  }
}

function getComponent({
  show,
  data,
  sha1,
  sha256,
  setShowPriority,
  setShow,
  showPriority,
  canAddPriority,
}: ShowSystemViewProps) {
  switch (true) {
    case md5ReportType.Summary === show:
      return (
        <SampleSummary
          sampleSummary={data}
          sha1={sha1}
          sha256={sha256}
          setShowPriority={setShowPriority}
          showPriority={showPriority}
          canAddPriority={canAddPriority}
        />
      );
    case md5ReportType.Viz === show:
      return (
        <VizView
          canPreview={data.malwareReport.canPreview}
          vizReport={data.malwareReport.reports?.[md5ReportType.Viz]?.[0]}
        />
      );
    case md5ReportType.ShadowServer === show:
      return (
        <ShadowServerView
          shadowServerReport={
            data.malwareReport.reports?.[md5ReportType.ShadowServer]?.[0]
          }
          reportType={show}
        />
      );
    case md5ReportType.ShadowServerCW === show ||
      md5ReportType.ShadowServerZ === show:
      return (
        <ShadowServerCWView
          shadowServerReport={data.malwareReport.reports?.[show]?.[0]}
          JA3={data.malwareReport.fingerprintsPerSandbox?.[show]}
          SandboxAlerts={data.malwareReport.alertsPerSandbox?.[show]}
          callouts={data.malwareReport.dbCNCMap?.[show]}
          reportType={show}
        />
      );
    case md5ReportType.MetaSploit === show:
      return (
        <MetaScanView
          metaScanReport={
            data.malwareReport.reports?.[md5ReportType.MetaSploit]?.[0]
          }
        />
      );
    case md5ReportType.FileType === show:
      return (
        <FileTypeView
          fileTypeReports={data.malwareReport.reports?.[md5ReportType.FileType]}
        />
      );
    case md5ReportType.Yara === show:
      return (
        <YaraView
          yaraReport={
            data.malwareReport.reports?.[md5ReportType.Yara] &&
            data.malwareReport.reports?.[md5ReportType.Yara]?.[0]
          }
          yaraAssemblyLineRules={data.yaraAssemblyLineRules}
        />
      );
    case md5ReportType.Entropy === show:
      return (
        <EntropyView
          entropyReport={data.malwareReport.reports?.[md5ReportType.Entropy]?.[0]}
        />
      );
    case md5ReportType.Pendora === show:
      return (
        <PendoraView
          pendoraReport={data.malwareReport.reports?.[md5ReportType.Pendora]?.[0]}
        />
      );
    case md5ReportType.VirusTotal === show:
      return (
        <VirusTotalView
          // TODO - allow user to select whch one they want to see if there are multiple
          // see https://gitlab.acid.azure.chi/ino/attackdetection/beaver-ui/-/issues/627
          vtReports={data.malwareReport.reports?.[md5ReportType.VirusTotal]}
        />
      );
    case md5ReportType.AssemblyLine === show:
      return (
        // TODO - show most recent or paginate (note count is total)!
        <ALView alReports={data.malwareReport.reports?.[show]} />
      );
    case md5ReportType.Hash === show:
      // at this point, we need to determine if it is the new hash report or old one
      // the old one has a PEHash, the new one does not.
      if (data.malwareReport.reports?.[md5ReportType.Hash]?.[0]?.["PEHash"])
        return (
          <HashView
            hashReport={data.malwareReport.reports?.[md5ReportType.Hash]?.[0]}
          />
        );
      else
        return (
          <HashView2
            hashReport={data.malwareReport.reports?.[md5ReportType.Hash]?.[0]}
          />
        );
    case md5ReportType.Sunbelt === show:
      return (
        <SunbeltView
          sunbeltReport={data.malwareReport.reports?.[show]?.[0]}
          dbCNCMap={data.malwareReport.dbCNCMap?.[show]}
          sandBoxAlerts={data.malwareReport.alertsPerSandbox?.[show]}
          fingerprints={data.malwareReport.fingerprintsPerSandbox?.[show]}
        />
      );
    case /^BEAR_/.test(show):
      return (
        <BearView
          // am now showing the latest report - TODO find a way to show all reports in tabs like beaver legacy
          bearReport={
            data.malwareReport.reports?.[show]?.[
              data.malwareReport.reports[show].length - 1
            ]
          }
          dbCNCMap={data.malwareReport.dbCNCMap?.[show]}
          sandBoxAlerts={data.malwareReport.alertsPerSandbox?.[show]}
          fingerprints={data.malwareReport.fingerprintsPerSandbox?.[show]}
          dnsQueries={data.dnsQueries?.[show]}
          reportAddedDates={data.malwareReport.reportAddedDates}
          resources={data.resources}
          type={show}
          errors={data.malwareReport.errors}
        />
      );
    case /^FE_/.test(show):
      return (
        <FireEyeView
          fireEyeReport={data.malwareReport.reports?.[show]?.[0]}
          dbCNCMap={data.malwareReport.dbCNCMap?.[show]}
          type={show}
        />
      );
    case /_SUMMARY$/.test(show):
      return <SubgroupSummaryView data={data} type={show} setShow={setShow} />;
    default:
      return <TBD show={show} />;
  }
}

type TBDProps = {
  show: string;
};

function TBD({ show }: TBDProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader
        className={classes.ch}
        avatar={<Avatar className={classes.errorIcon}>:(</Avatar>}
        title={t("md5.the") + " '" + show + "' " + t("md5.viewnotdone")}
      />
      <CardContent>
        <p>
          {t("Sorry, the content type view")} '{show}'{" "}
          {t("is not complete (yet)")}.
        </p>
        <p>
          {t(
            "If you believe this is an error or need this view more urgently, please email the BeAVER / INO team!"
          )}
        </p>
      </CardContent>
    </Card>
  );
}
