import React from "react";
import Axios, { Method } from "axios";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import useAppLayout from "commons/components/hooks/useAppLayout";
import { AddToPriorityJSON } from "components/beaver/helpers/ServerProperties";
import { UserProfileProps } from "commons/components/layout/topnav/UserProfile";

// stub used for testing purposes
// TODO - figure out how to get currentUser from useAppLayout() hook for testing
export function AddToPriority({
  setShowPriority,
  md5,
  setAddedToPriority,
}: {
  setShowPriority: Function;
  md5: string;
  setAddedToPriority: Function;
}) {
  const { currentUser } = useAppLayout();
  return (
    <AddToPriorityImpl
      setShowPriority={setShowPriority}
      setAddedToPriority={setAddedToPriority}
      md5={md5}
      currentUser={currentUser}
    />
  );
}

export function AddToPriorityImpl({
  setShowPriority,
  md5,
  setAddedToPriority,
  currentUser,
}: {
  setShowPriority: Function;
  md5: string;
  setAddedToPriority: Function;
  currentUser: UserProfileProps;
}) {
  const { t } = useTranslation();

  const [comment, setComment] = React.useState("");
  const [added, setAdded] = React.useState(false);
  const [error, setError] = React.useState(false);
  // this hides the form until the mui has finished being shown (then the md5report will hide this whole thing)
  const [show, setShow] = React.useState(true);

  const doAddPriority = () => {
    // do the axios call here
    let data = new URLSearchParams();
    data.append("comment", comment);

    Axios({
      method: AddToPriorityJSON(md5).method as Method,
      url: AddToPriorityJSON(md5).url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      data: data,
      auth:
        currentUser.server && !currentUser.server.includes("localhost")
          ? {
              username: currentUser ? currentUser.username : null,
              password: currentUser
                ? currentUser.onetime
                  ? window
                      // eslint-disable-next-line no-eval
                      .atob(eval(window.atob(currentUser.onetime)))
                      .substr(13)
                  : null
                : null,
            }
          : null,
    })
      .then((res) => {
        //TODO - change backend to also show number in queue, etc. and if already added
        // show feedback (with link to the priority list page)

        // hide add to priority button & prompt
        setAdded(true);
        setShow(false);
        setAddedToPriority(true);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  // TODO - still need to show stats e.g. Currently 750 sample(s) in queue, and 0 for you.
  // TODO - show a link to bulk mode when done:  Add this MD5 to the priority list. (Bulk mode)
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        open={added || error}
        onClose={() => {
          setAdded(false);
          setError(false);
          setShowPriority(false);
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={error ? "error" : "success"}
          onClose={() => {
            setAdded(false);
            setError(false);
            setShowPriority(false);
          }}
        >
          {error ? t("An Error Occured") : t("Added to Priority List")}
        </MuiAlert>
      </Snackbar>
      {show && (
        <Grid container style={{ marginBottom: 20 }} spacing={2}>
          <Grid item xs={12} md={6} xl={4}>
            <Paper style={{ padding: 15 }}>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6">{t("Add to priority")}</Typography>
                <Typography variant="body1" style={{ marginTop: 15 }}>
                  {t("MD5")}: {md5}
                </Typography>

                <FormControl style={{ marginTop: 10 }}>
                  <TextField
                    label={t("Comment")}
                    value={comment}
                    onChange={(e) => setComment("" + e.target.value)}
                  />
                </FormControl>
                <Typography variant="body2" style={{ marginTop: 10 }}>
                  {t("As")}: {currentUser.username}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => doAddPriority()}
                  style={{ marginTop: 15 }}
                >
                  {t("Add to priority list")}
                </Button>
                <Button
                  onClick={() => setShowPriority(false)}
                  style={{ marginTop: 10 }}
                >
                  {t("Cancel")}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}
