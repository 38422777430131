import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { ComplianceJSON } from "../components/beaver/helpers/ServerProperties";

export interface TLPValues {
  [tlp: string]: {
    displayName: string;
    name: string;
    icon: string;
    definition: string;
  };
}

// export interface ComplianceData {
//   sourceComplianceTable: string[][];
//   sourceComplianceLastUpdate: string;
//   tlpValues: TLPValues;
// }

// TODO - define error more properly (e.g. string?)
export type ComplianceState = {
  // TODO - figure out why making this type Compliance Data causes compile error after yarn upgrade
  compliance: any;
  error: any;
};

export const slice = createSlice({
  name: "compliance",
  initialState: {} as ComplianceState,
  reducers: {
    setCompliance: (state, { payload }) => {
      state.compliance = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export default slice.reducer;

// use the Redux chrome plugin to inspect the state
export const complianceSelector = (state: ComplianceState) => state.compliance;

/**
 * This will fetch the compliance if they have not already been fetched,
 * hence why you send the compliance in the fetch
 *
 * @param compliance you pass the current sources from the reducer
 */
export const fetchCompliance = (compliance) => async (dispatch) => {
  //TODO - this should make the localstorage null if it can't parse- put this somewhere and export
  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  if (!compliance)
    Axios.get(ComplianceJSON(), {
      auth:
        user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    }).then(
      (response) => {
        dispatch(slice.actions.setCompliance(response.data));
      },
      (error) => {
        // error being ommited from console.log so as not to pollute the tests
        dispatch(
          slice.actions.setError(
            error.response
              ? JSON.stringify(error.response)
              : JSON.stringify({
                  status: error.message.match(/\d+/g)
                    ? parseInt(error.message.match(/\d+/g)[0])
                    : 500,
                  message: error.message,
                  data: JSON.stringify(error),
                })
          )
        );
      }
    );
};
