import React from "react";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import {
  SimpleTableCardView,
  SimpleTableView,
} from "components/beaver/components/report/common/SimpleTableView";

import { dbCNCMapModel } from "../../../../models/report/md5/dbCNCMapCalloutModel";

import { useStyles } from "../../common/sharedStyles";

import { useTranslation } from "react-i18next";
import { LinkWrap } from "../../common/LinkWrap";

interface processInfo {
  pid: number;
  imagepath: string;
  md5sum: string;
  tid: string;
}

export interface fireEyeModel {
  //HEADER STUFF
  creationDate: string;
  md5: string;
  extension: string;
  engineName: string;
  reportResult: string;
  // TODO - message type
  messages: string[];
  fe_av: {
    scannerName: string;
    scannerVersion: string;
    updateDate: string;
    result: string;
  }[];

  // CALLOUTS are in dbCNC

  //DNSQueries
  networkReports: {
    calloutStructure: {
      dnsQueries: {
        query: string;
        type: string;
        server: string;
        answers: string[];
        // unsure if ttls are string[]
        ttls: string[];
      }[];
    };
  }[];

  // host activity stuff is already broken down for us :)
  apicalls: {
    dllname: string;
    apiname: string;
    address: string;
    processinfo: processInfo;
    sequenceNumber: number;
    timestamp: number;
  }[];
  fileActivity: {
    md5sum: string;
    sha1sum: string;
    value: string;
    ntstatus: string;
    filesize: number;
    processinfo: processInfo;
    sequenceNumber: number;
    timestamp: number;
    mode: string;
  }[];
  folderActivity: {
    value: string;
    ntstatus: string;
    processinfo: processInfo;
    sequenceNumber: number;
    timestamp: number;
    mode: string;
  }[];
  networkActivity: {
    protocol_type: string;
    destination_port: number;
    ipaddress: string;
    http_request: string;
    qtype: string;
    hostname: string;
    dns_response_code: number;
    answer_number: number;
    processinfo: processInfo;
    sequenceNumber: number;
    timestamp: number;
    mode: string;
  }[];
  regkey: {
    value: string;
    processinfo: processInfo;
    sequenceNumber: number;
    timestamp: number;
    mode: string;
  }[];
  processes: {
    pid: number;
    ppid: number;
    parentname: string;
    cmdline: string;
    filesize: string;
    md5sum: string;
    sha1sum: string;
    value: string;
    sequenceNumber: string;
    timestamp: number;
    mode: string;
  }[];
  maliciousAlerts: {
    "display-msg": string;
    classtype: string;
    sequenceNumber: number;
    timestamp: number;
  }[];

  // Drops
  drops: { parentMd5: string; md5: string; receivedDate: number }[];
}

export type FireEyeViewProps = {
  fireEyeReport: fireEyeModel;
  type: string;
  dbCNCMap: dbCNCMapModel[];
};

export function FireEyeView({
  fireEyeReport,
  type,
  dbCNCMap,
}: FireEyeViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Card>
        <CardHeader className={classes.ch} title={t("FireEye Report")} />
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{t("Analysis Date")}</TableCell>
                  <TableCell>{fireEyeReport?.creationDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("MD5")}</TableCell>
                  <TableCell>{fireEyeReport?.md5}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Extension")}</TableCell>
                  <TableCell>{fireEyeReport?.extension}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Sandbox Name")}</TableCell>
                  <TableCell>{fireEyeReport?.engineName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Result")}</TableCell>
                  <TableCell>{fireEyeReport?.reportResult}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Box mt={2}>
        <SimpleTableCardView
          title={t("AV Results")}
          headers={[
            { title: t("AV"), field: "scannerName" },
            { title: t("Result"), field: "result" },
            { title: t("Update"), field: "updateDate" },
            { title: t("Scanner Version"), field: "scannerVersion" },
          ]}
          data={cloneDeep(fireEyeReport?.fe_av) ?? []}
        />
      </Box>
      <Box mt={2}>
        {fireEyeReport && <HostActivityView fireEyeReport={fireEyeReport} key={type} />}
      </Box>
      <Box mt={2}>
        <SimpleTableCardView
          title={"Callouts"}
          headers={[
            {
              title: t("Callout"),
              field: "callout",
              render: (r) => (
                <Link to={["/domain", r.callout, "report"].join("/")}>
                  <LinkWrap>{r.callout}</LinkWrap>
                </Link>
              ),
            },
            { title: t("Protocol"), field: "protocol" },
            { title: t("Port"), field: "port" },
            {
              title: t("IP"),
              field: "ip",
              render: (r) => (
                <Link to={["/ip", r.ip, "report"].join("/")}>
                  <LinkWrap>{r.ip}</LinkWrap>
                </Link>
              ),
            },
            {
              title: t("DNS Name"),
              field: "dnsName",
              render: (r) => (
                <Link to={["/domain", r.dnsName, "report"].join("/")}>
                  <LinkWrap>{r.dnsName}</LinkWrap>
                </Link>
              ),
            },
            { title: t("Location"), field: "location" },
            { title: t("Association"), field: "association" },
            {
              title: t("Channel"),
              field: "channel",
              render: (r) => (
                <span className={classes.breakword}>{r.channel}</span>
              ),
            },
          ]}
          data={dbCNCMap?.map((r) => ({
            callout: r.callout.domain,
            protocol: r.callout.protocols.join(", "),
            port: r.callout.port,
            ip: r.callout.ip,
            dnsName: r.domains[0].domain,
            location: [r.domains[0].countryCode, r.domains[0].city].join(" - "),
            association: r.domains[0].asName,
            channel: r.callout.channel,
            //TODO - looks like the url is from the domain + channel md5'ed
          })) ?? []}
        />
      </Box>

      <Box mt={2}>
        <SimpleTableCardView
          title={t("DNS Queries")}
          headers={[
            {
              title: t("DNS Query"),
              field: "query",
              render: (r) => r.query + " (" + r.type + ") using " + r.server,
            },
            { title: t("Query"), field: "query" },
            { title: t("Type"), field: "type" },
            { title: t("Server"), field: "server" },
            {
              title: t("Answers"),
              field: "answersString",
              render: (r) => (
                <>
                  {r.answers.map((a, i) => (
                    <React.Fragment key={i}>
                      {i > 0 ? ", " : null}
                      <Link to={["/ip", a, "report"].join("/")}>
                        <LinkWrap>{a}</LinkWrap>
                      </Link>
                    </React.Fragment>
                  ))}
                </>
              ),
            },
          ]}
          data={fireEyeReport?.networkReports[0].calloutStructure.dnsQueries.map(
            (r) => ({
              query: r.query,
              type: r.type,
              server: r.server,
              answers: r.answers,
              answersString: r.answers.join(", "),
            })
          ) ?? []}
        />
      </Box>

      <Box mt={2}>
        <SimpleTableCardView
          title={t("Drops")}
          headers={[
            {
              title: t("Parent MD5"),
              field: "parentMd5",
              render: (r) => (
                <Link to={["/md5", r.parentMd5, "report"].join("/")}>
                  <LinkWrap>{r.parentMd5}</LinkWrap>
                </Link>
              ),
            },
            {
              title: t("MD5"),
              field: "md5",
              render: (r) => (
                <Link to={["/md5", r.md5, "report"].join("/")}>
                  <LinkWrap>{r.md5}</LinkWrap>
                </Link>
              ),
            },
            { title: t("Received Date"), field: "receivedDate" },
          ]}
          data={cloneDeep(fireEyeReport?.drops) ?? []}
        />
      </Box>
    </Box>

    //TODO - messages
  );
}

type HostActivityProps = {
  fireEyeReport: fireEyeModel;
};

function HostActivityView({ fireEyeReport }: HostActivityProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const hostViews = {
    api: {title: "API call", data: fireEyeReport.apicalls},
    file: {title: "File", data: fireEyeReport.fileActivity},
    folder: {title: "Folder", data: fireEyeReport.folderActivity},
    network: {title: "Network", data: fireEyeReport.networkActivity},
    reg: {title: "Reg Key", data: fireEyeReport.regkey},
    process: {title: "Process", data: fireEyeReport.processes},
    alert: {title: "Malicious alert", data: fireEyeReport.maliciousAlerts},
  };

  const [hostView, setHostView] = React.useState(
    (Object.entries(hostViews).find(([, value]) => value.data.length > 0)
    ?? Object.keys(hostViews))[0]
  )

  return (
    <Card>
      <CardHeader className={classes.ch} title={t("Host Activity")} />
      <CardContent>
        {Object.keys(hostViews).map((v, i) => (
          <Button
            key={i}
            onClick={() => setHostView(v)}
            variant={v === hostView ? "outlined" : "text"}
            size={v === hostView ? "large" : "medium"}
            disabled={hostViews[v].data.length === 0}
          >
            {hostViews[v].title}
          </Button>
        ))}
        {"alert" === hostView ? (
          <SimpleTableView
            data={hostViews[hostView].data.map((r) => ({
              ...r,
              view: "Malicious alert",
            }))}
            headers={[
              { title: t("Timestamp"), field: "timestamp" },
              { title: t("Type"), field: "classtype" },
              { title: t("Value"), field: "display-msg" },
            ]}
          />
        ) : null}

        {"process" === hostView ? (
          <SimpleTableView
            data={hostViews[hostView].data.map((r) => ({
              ...r,
              view: "Reg Key",
            }))}
            headers={[
              { title: t("Timestamp"), field: "timestamp" },
              { title: t("Mode"), field: "mode" },
              {
                title: t("Value"),
                field: "value",
                render: (r) => (
                  <span className={classes.breakword}>{r.value}</span>
                ),
              },
              { title: t("PID"), field: "pid" },
              { title: t("MD5"), field: "md5sum" },
              { title: t("Filesize"), field: "filesize" },
              {
                title: t("Process"),
                field: "parentname",
                render: (r) => (
                  <span className={classes.breakword}>{r.parentname}</span>
                ),
              },
              { title: t("Parent PID"), field: "ppid" },
            ]}
          />
        ) : null}

        {"reg" === hostView ? (
          <SimpleTableView
            data={hostViews[hostView].data.map((r) => ({
              ...r,
              PIpid: r.processinfo.pid,
              PIimagepath: r.processinfo.imagepath,
              PImd5sum: r.processinfo.md5sum,
              PItid: r.processinfo.tid,
              view: "Reg Key",
            }))}
            headers={[
              { title: t("Timestamp"), field: "timestamp" },
              { title: t("Mode"), field: "mode" },
              {
                title: t("Value"),
                field: "value",
                render: (r) => (
                  <span className={classes.breakword}>{r.value}</span>
                ),
              },
              {
                title: t("Process"),
                field: "PIimagepath",
                render: (r) => (
                  <span className={classes.breakword}>{r.PIimagepath}</span>
                ),
              },
              { title: t("PID"), field: "PIpid" },
              { title: t("Process MD5"), field: "PImd5sum" },
            ]}
          />
        ) : null}

        {"network" === hostView ? (
          <SimpleTableView
            data={hostViews[hostView].data.map((r) => ({
              ...r,
              port: r.destination_port === -1 ? null : "" + r.destination_port,
              view: "Network",
            }))}
            headers={[
              { title: t("Timestamp"), field: "timestamp" },
              { title: t("Mode"), field: "mode" },
              {
                title: t("HTTP Request"),
                field: "http_request",
                render: (r) => (
                  <span className={classes.breakword}>{r.http_request}</span>
                ),
              },
              {
                title: t("Hostname"),
                field: "hostname",
                render: (r) => (
                  <Link to={["/domain", r.hostname, "report"].join("/")}>
                    <LinkWrap>{r.hostname}</LinkWrap>
                  </Link>
                ),
              },
              { title: t("Protocol Type"), field: "protocol_type" },
              {
                title: t("IP"),
                field: "ipaddress",
                render: (r) => (
                  <Link to={["/ip", r.ipaddress, "report"].join("/")}>
                    <LinkWrap>{r.ipaddress}</LinkWrap>
                  </Link>
                ),
              },
              { title: t("Port"), field: "port" },
            ]}
          />
        ) : null}

        {"folder" === hostView ? (
          <SimpleTableView
            data={hostViews[hostView].data.map((r) => ({
              ...r,
              PIpid: r.processinfo.pid,
              PIimagepath: r.processinfo.imagepath,
              PImd5sum: r.processinfo.md5sum,
              PItid: r.processinfo.tid,
              view: "Folder",
            }))}
            headers={[
              { title: t("Timestamp"), field: "timestamp" },
              { title: t("Mode"), field: "mode" },
              {
                title: t("Value"),
                field: "value",
                render: (r) => (
                  <span className={classes.breakword}>{r.value}</span>
                ),
              },
              {
                title: t("Process"),
                field: "PIimagepath",
                render: (r) => (
                  <span className={classes.breakword}>{r.PIimagepath}</span>
                ),
              },
              { title: t("PID"), field: "PIpid" },
              { title: t("Process MD5"), field: "PImd5sum" },
            ]}
          />
        ) : null}

        {"file" === hostView ? (
          <SimpleTableView
            data={hostViews[hostView].data.map((r) => ({
              ...r,
              PIpid: r.processinfo.pid,
              PIimagepath: r.processinfo.imagepath,
              PImd5sum: r.processinfo.md5sum,
              PItid: r.processinfo.tid,
              view: "File",
            }))}
            headers={[
              { title: t("Timestamp"), field: "timestamp" },
              { title: t("Mode"), field: "mode" },
              {
                title: t("Value"),
                field: "value",
                render: (r) => (
                  <span className={classes.breakword}>{r.value}</span>
                ),
              },
              { title: t("MD5"), field: "md5sum" },
              { title: t("Filesize"), field: "filesize" },
              {
                title: t("Process"),
                field: "PIimagepath",
                render: (r) => (
                  <span className={classes.breakword}>{r.PIimagepath}</span>
                ),
              },
              { title: t("PID"), field: "PIpid" },
              { title: t("Process MD5"), field: "PImd5sum" },
            ]}
          />
        ) : null}

        {"api" === hostView ? (
          <SimpleTableView
            data={hostViews[hostView].data.map((r) => ({
              ...r,
              PIpid: r.processinfo.pid,
              PIimagepath: r.processinfo.imagepath,
              PImd5sum: r.processinfo.md5sum,
              PItid: r.processinfo.tid,
              view: "API call",
            }))}
            headers={[
              { title: t("Timestamp"), field: "timestamp" },
              {
                title: t("API Name"),
                field: "apiname",
                render: (r) => (
                  <span className={classes.breakword}>{r.apiname}</span>
                ),
              },
              { title: t("DLL Name"), field: "dllname" },
              {
                title: t("Process"),
                field: "PIimagepath",
                render: (r) => (
                  <span className={classes.breakword}>{r.PIimagepath}</span>
                ),
              },
              { title: t("PID"), field: "PIpid" },
              { title: t("Process MD5"), field: "PImd5sum" },
            ]}
          />
        ) : null}
      </CardContent>
    </Card>
  );
}

//{title:t(""), field:""},
