import { createSlice } from "@reduxjs/toolkit";
import { ReportType } from "../components/beaver/models/report/reportType";
import { doTypeRequest } from "./beaverng";

// TODO : define this more definitely (e.g. error?: string),
export type IPReportState = {
  responseData: any;
  error: string;
  reportType: ReportType;
  quick: boolean;
};

export const slice = createSlice({
  name: "ipreport",
  initialState: {} as IPReportState,
  reducers: {
    clearReport: (state) => {
      state.error = null;
      state.reportType = null;
      state.responseData = {};
    },
    setReportType: (state, { payload }) => {
      state.reportType = payload;
    },
    setReport: (state, { payload }) => {
      state.responseData = { ...state.responseData, ...payload };
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setQuick: (state, { payload }) => {
      state.quick = payload ? payload : false;
    },
  },
});

export default slice.reducer;

// the state is {uploadurl:{uploadurl:{}}} to begin with - notice the extra query that wraps everything (set from the index)!
// use the Redux chrome plugin to inspect the state
// TODO - figure out the typescript type of state, dispatch, etc.
export const ipReportSelector = (state) => state.ipreport;

export const clearReport = () => async (dispatch) => {
  console.log("clearing ip report");
  dispatch(slice.actions.clearReport);
};

// type is e.g. md5 or domain, value is e.g. the md5 or domain value / DNS
export const fetchReport =
  (type: ReportType, value?: string) => async (dispatch) => {
    if (ReportType.IP === type) {
      doTypeRequest(slice, dispatch, type, value);
    } else {
      console.log(`Dispatch ${type} with value ${value} does not exist`);
    }
  };
