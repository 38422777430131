import React from "react";
import { cloneDeep } from "lodash";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { SimpleTableCardView } from "../../common/SimpleTableView";

import { yaraModel } from "../../../../models/report/md5/yaraModel";

import { useStyles } from "../../common/sharedStyles";
import { useTranslation } from "react-i18next";
import { yaraAssemblyLineRule } from "components/beaver/models/report/md5/md5ReportModel";
import { YaraAssemblyLineView } from "./YaraAssemblyLineView";

export type YaraViewProps = {
  yaraReport: yaraModel;
  yaraAssemblyLineRules: yaraAssemblyLineRule[];
  testing?: boolean;
};

export function YaraView({
  yaraReport,
  testing,
  yaraAssemblyLineRules,
}: YaraViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (yaraReport) {
    return (
      <Box>
        <Card>
          <CardHeader className={classes.ch} title={t("Yara Report")} />
          <CardContent>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>{t("Analysis Date")}</TableCell>
                    <TableCell>{yaraReport?.creationDate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>MD5</TableCell>
                    <TableCell>{yaraReport?.md5}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Report Version")}</TableCell>
                    <TableCell>{yaraReport?.version}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Tools Version")}</TableCell>
                    <TableCell>{yaraReport?.rulesVersion}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Result")}</TableCell>
                    <TableCell>{yaraReport?.result}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Message")}</TableCell>
                    <TableCell>
                      {yaraReport?.messages && yaraReport?.messages.length
                        ? yaraReport?.messages[0].type +
                          "  " +
                          yaraReport?.messages[0].message
                        : null}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>{" "}
          </CardContent>
        </Card>
        <Box mt={2}>
          <SimpleTableCardView
            testing={testing}
            title={t("Events")}
            data={cloneDeep(
              Object.values(yaraReport?.events ?? []).map((v) => {
                return {
                  name: v.name,
                  id: v.id,
                  revision: v.revision,
                  author: v.author,
                  cve: v.cve ? v.cve.join(", ") : "",
                  description: v.description,
                  tags: v.tags ? v.tags.join(", ") : "",
                };
              })
            )}
            headers={[
              { title: t("Name"), field: "name" },
              { title: t("ID"), field: "id" },
              { title: t("Revision"), field: "revision" },
              { title: t("Author"), field: "author" },
              { title: t("CVE"), field: "cve" },
              { title: t("Description"), field: "description" },
              { title: t("Tags"), field: "tags" },
            ]}
          />
        </Box>
        {/* It is possible that the yara assemblyline rules exist here also, so show them here if so */}
        <Box mt={2}>
          <YaraAssemblyLineView
            yaraAssemblyLineRules={yaraAssemblyLineRules}
            testing={testing}
            title={t("Yara Rules")}
          />
        </Box>
      </Box>
    );
  }

  // this should be a seperate view so that it can be shown above - first 'return' is shown and nothing else processes
  if (yaraAssemblyLineRules && yaraAssemblyLineRules.length) {
    return (
      <YaraAssemblyLineView
        yaraAssemblyLineRules={yaraAssemblyLineRules}
        testing={testing}
      />
    );
  }

  return (
    <Card>
      <CardHeader
        className={classes.ch}
        title={t("There are no Yara Rules for this MD5")}
      />
      <CardContent>{t("There are no Yara Rules for this MD5")}</CardContent>
    </Card>
  );
}
