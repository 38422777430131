import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = (width) => {
  return makeStyles((theme) => ({
    page: {
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "auto",
      width: `${width}%`,
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        maxWidth: "95%",
      },
    },
  }))();
};

type PageCenterProps = {
  width?: number;
  children: React.ReactNode;
};

const PageCenter: React.FC<PageCenterProps> = ({ width = 100, children }) => {
  const classes = useStyles(width);
  return <Box className={classes.page}>{children}</Box>;
};

export default PageCenter;
