import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SnackbarProvider } from "notistack";

import ErrorBoundary from "./ErrorBoundry";

// To switch to the TBSLayout, you have to change the following, and the useAppLayout.ts file also
import AppLayoutProvider from "../../commons/components/layout/LayoutProvider";
import Home from "../routes/Home";
import Search from "../routes/Search";
import LoginScreen from "../routes/login";
import Logout from "../routes/logout";
import NotFoundPage from "../routes/404";
import useMyLayout from "../hooks/useMyLayout";
import {
  isExternal,
  isInternal,
  isGeekweek,
} from "../beaver/helpers/ServerProperties";
import { isSimplified } from "components/beaver/helpers/ServerProperties";
import { ReportView } from "../routes/ReportView";
import { PhishingList } from "../beaver/components/report/phishing/PhishingList";
import { PhishingClustersList } from "../beaver/components/report/phishing/PhishingClustersList";
import { TermsAndConditions } from "../beaver/components/policy/TermsAndConditions";
import { Compliance } from "../beaver/components/policy/Compliance";
import { TagList } from "../beaver/components/report/tag/TagList";
import { PriorityList } from "../beaver/components/report/priority/PriorityList";
import { UploadedSamples } from "../beaver/components/upload/UploadedSamples";
import { UploadedURLs } from "../beaver/components/upload/UploadedURLs";
import { UploadPage } from "../beaver/components/upload/UploadPage";
import { AcceptTerms } from "../beaver/components/policy/AcceptTerms";
import { SSOLogin } from "../routes/ssologin";
import { YaraRulesList } from "components/beaver/components/report/retroHunt/LiveHunt";
import { YaraRulesHitList } from "components/beaver/components/report/retroHunt/LiveHuntRule";
import { SpamPhishingList } from "components/beaver/components/report/retroHunt/SpamPhishing";
import { AddPriorityBulk } from "components/beaver/components/report/priority/AddPriorityBulk";
import { URLMD5ClusterList } from "../beaver/components/report/url/URLMD5ClustersList";
import { ElasticURLSearch } from "components/beaver/components/search/ElasticURLSearch";
import { ElasticCafeSearch } from "components/beaver/components/search/ElasticCafeSearch";
import axios from "axios";
import { BorealisSearch } from "components/beaver/components/borealis/BorealisSearch";
import { BorealisReportPage } from "components/beaver/components/borealis/BorealisReportPage";

type AppProps = {};

const App: React.FC<AppProps> = () => {
  const storedUser = localStorage.getItem("currentUser");
  const user = storedUser ? JSON.parse(storedUser) : null;

  const termsAccepted = localStorage.getItem("termsAccepted")
    ? JSON.parse(localStorage.getItem("termsAccepted"))
    : null;

  const renderRoutes = () => {
    return (
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/sso" element={<SSOLogin />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/search" element={<Search />} />
        {/* login and ssologin control if user can see borealis, geekweek control is via proxy */}
        {!!user.canSeeBorealis && (
          <Route exact path="/borealisSearch" element={<BorealisSearch />} />
        )}
        {!!user.canSeeBorealis && (
          <Route
            exact
            path="/borealisReport"
            element={<BorealisReportPage />}
          />
        )}
        <Route exact path="/urlsearch" element={<ElasticURLSearch />} />
        {!isExternal() && !isSimplified() && (
          <Route exact path="/cafesearch" element={<ElasticCafeSearch />} />
        )}
        <Route exact path="/logout" element={<Logout />} />
        {!isSimplified() && (
          <Route exact path="/phishing_kit/list" element={<PhishingList />} />
        )}
        {!isSimplified() && (
          <Route
            exact
            path="/phishing_kit/clusters/list"
            element={<PhishingClustersList />}
          />
        )}
        {!isSimplified() && (
          <Route exact path="/robin/liveHunt" element={<YaraRulesList />} />
        )}
        {!isSimplified() && (
          <Route exact path="/robin/rules/:id" element={<YaraRulesHitList />} />
        )}
        {!isSimplified() && (
          <Route
            exact
            path="/spam/search/list"
            element={<SpamPhishingList />}
          />
        )}
        <Route
          exact
          path="/terms_and_conditions"
          element={<TermsAndConditions />}
        />
        <Route exact path="/compliance" element={<Compliance />} />
        {!isSimplified() && (
          <Route exact path="/taglist" element={<TagList />} />
        )}
        {!isSimplified() && (
          <Route exact path="/urlmd5/list" element={<URLMD5ClusterList />} />
        )}
        {!isSimplified() && (
          <Route exact path="/priority/bulk" element={<AddPriorityBulk />} />
        )}
        {!isSimplified() && (
          <Route
            exact
            path="/priority/:type?/:user?"
            element={<PriorityList />}
          />
        )}
        <Route path="/:type/:value/report" element={<ReportView />} />
        {!isExternal() && !isInternal() && (
          <Route path="/upload/sample/list" element={<UploadedSamples />} />
        )}
        {!isExternal() && (
          <Route path="/upload/url/list" element={<UploadedURLs />} />
        )}
        <Route path="/upload" element={<UploadPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  };

  const layout = useMyLayout();
  const { t } = useTranslation();
  React.useEffect(() => {
    // expire the terms accepted after 1y ~ 365 days * 24h * 60 mins * 60 s * 1000ms
    if (localStorage.termsAccepted && !isNaN(localStorage.termsAccepted)) {
      if (Date.now() - localStorage.termsAccepted > 1000 * 60 * 60 * 24 * 365) {
        localStorage.termsAccepted = null;
        window.location.reload();
      }
    }
  }, []);

  const [keepAliveInterval, setKeepAliveInterval] = React.useState(null);

  React.useEffect(() => {
    if (isGeekweek()) {
      console.log("Starting Keep Alive for Beaver Collaboration OAuth");
      if (keepAliveInterval) setKeepAliveInterval(null);
      setKeepAliveInterval(
        // run every 1 mins
        setInterval(
          () => {
            axios
              .get("https://" + window.location.hostname + "/auth/keepalive")
              .catch((error) => window.location.replace("/auth/logout"));
          },
          1000 * 60 * 1
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter basename="/ng">
      <Helmet>
        <title>{layout.useTBSLayout ? "BEAVER NEXT" : "BEAVER NG"}</title>
        <meta name="description" content={t("page.home.description")} />
      </Helmet>

      <AppLayoutProvider value={layout} user={user}>
        <ErrorBoundary>
          <SnackbarProvider>
            {user ? (
              isExternal() && !termsAccepted && "..." !== user["name"] ? (
                <AcceptTerms />
              ) : "..." !== user["name"] ? (
                renderRoutes()
              ) : (
                <SSOLogin />
              )
            ) : (
              <LoginScreen />
            )}
          </SnackbarProvider>
        </ErrorBoundary>
      </AppLayoutProvider>
    </BrowserRouter>
  );
};

export default App;
