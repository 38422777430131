import React from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "commons/components/layout/pages/PageHeader";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  Paper,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { OtherSearches } from "components/routes/Search";
import { searchExamples } from "../search/searchExamples";
import IndicatorValidate from "components/beaver/helpers/IndicatorValidate";
import { BorealisReport } from "./BorealisReport";
import { HelpToolTip, HelpButton } from "../report/common/HelpButton";
import {
  BorealisModulesHelp,
  BorealisHelp,
} from "components/beaver/help/BorealisHelp";
import {
  setSearchText,
  setModulesState,
  setResponses,
  setErrorText,
  runBorealisSearch,
  borealisSearchSelector,
} from "slices/borealissearch";
import { useDispatch, useSelector } from "react-redux";
import { isGeekweek } from "components/beaver/helpers/ServerProperties";

import { BorealisModules } from "components/beaver/models/borealis/borealisModel";

import FlashOnOutlinedIcon from "@material-ui/icons/FlashOnOutlined";
import FlashOffOutlinedIcon from "@material-ui/icons/FlashOffOutlined";
import PageFullWidth from "commons/components/layout/pages/PageFullWidth";

export function BorealisSearch() {
  const { t } = useTranslation();

  const title = t("Borealis Search");
  return (
    <>
      <Helmet>
        <title>{["BEAVER", title].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader
        title={
          <>
            {title}
            <HelpButton help={<BorealisHelp />} title={t("Borealis")} />
          </>
        }
      />
      <PageFullWidth noTopMargin>
        <OtherSearches currentSearchPage="borealis" />
        <BorealisSearchPage />
      </PageFullWidth>
    </>
  );
}

const validator: any = new IndicatorValidate(true, false, true);

function BorealisSearchPage() {
  const { t } = useTranslation();
  const [searching, setSearching] = React.useState(false);
  const [onlineMode, setOnlineMode] = React.useState(true);

  const { searchText, modulesState, responses, errorText } = useSelector(
    borealisSearchSelector
  );

  // extra check for if you are logged out etc.
  const handleError = (error: any) => {
    // if it's a 401, logout because bad creds, the user can then log back in
    if (error.response && error.response.status === 401) {
      setTimeout(() => {
        dispatch(setErrorText(t("You may need to logout and login again")));
      }, 1200);
      setTimeout(() => {
        if (localStorage.ssoPrefix) localStorage.removeItem("ssoPrefix");
        if (localStorage.currentUser) localStorage.removeItem("currentUser");
        window.location.reload();
      }, 3000);
    }
  };

  const dispatch = useDispatch();

  const doBorealisSearch = (searchText: string) => {
    dispatch(setErrorText(""));
    setSearching(true);
    dispatch(setResponses(null));
    // grab the search text, validate stuff, do queries for each one
    let valid = validator.validate(searchText);
    if (!valid) {
      dispatch(setErrorText(validator.getErrorText()));
      setSearching(false);
      return;
    } else {
      // just dispatch the runBorealisSearch
      dispatch(
        runBorealisSearch(
          modulesState,
          searchText,
          !onlineMode,
          setSearching,
          handleError
        )
      );
    }
  };

  return (
    <Box style={{ margin: "24px" }}>
      <Box style={{ display: "flex" }}>
        <Typography variant="h6">{t("New Search")}:</Typography>
      </Box>
      {/* Modules stuff should not be shown by default, but rather an option to show it */}
      <Box my={2}>
        <Paper style={{ padding: "12px" }}>
          <Box>
            <Typography variant="body1" style={{ display: "inline" }}>
              {t("Modules")}:
            </Typography>
            <Button
              color="primary"
              onClick={() =>
                dispatch(
                  setModulesState(
                    BorealisModules.reduce(
                      (ac, a) => ({ ...ac, [a]: true }),
                      {}
                    )
                  )
                )
              }
            >
              {t("Select All")}
            </Button>
            <Button
              color="primary"
              onClick={() =>
                dispatch(
                  setModulesState(
                    // set only BEAVER as module if none
                    BorealisModules.reduce(
                      (ac, a) => ({
                        ...ac,
                        [a]: "BEAVER" === a ? true : false,
                      }),
                      {}
                    )
                  )
                )
              }
            >
              {t("Select None")}
            </Button>
            <Tooltip
              arrow
              placement="top"
              title={t("Select only those modules which use Offline resources")}
            >
              <Button
                color="primary"
                onClick={() =>
                  dispatch(
                    setModulesState(
                      BorealisModules.reduce(
                        (ac, a) => ({
                          ...ac,
                          [a]:
                            "BEAVER" === a || BorealisModulesHelp[a][7]
                              ? true
                              : false,
                        }),
                        {}
                      )
                    )
                  )
                }
              >
                {t("Only Offline")}
              </Button>
            </Tooltip>
            <Tooltip
              arrow
              placement="top"
              title={t(
                "Select only those modules which only use Online resources"
              )}
            >
              <Button
                color="primary"
                onClick={() =>
                  dispatch(
                    setModulesState(
                      BorealisModules.reduce(
                        (ac, a) => ({
                          ...ac,
                          [a]:
                            "BEAVER" === a || !BorealisModulesHelp[a][7]
                              ? true
                              : false,
                        }),
                        {}
                      )
                    )
                  )
                }
              >
                {t("Only Online")}
              </Button>
            </Tooltip>
            <FormGroup row>
              {BorealisModules.filter((r) => "BEAVER" !== r)
                .sort()
                .map((m, i) => (
                  <React.Fragment key={i}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={modulesState[m]}
                          onChange={(event) =>
                            dispatch(
                              setModulesState({
                                ...modulesState,
                                [event.target.name]: event.target.checked,
                              })
                            )
                          }
                          name={m}
                        />
                      }
                      label={
                        <>
                          {m}
                          {/* linghting bolt if online, otherwise offline */}
                          {BorealisModulesHelp[m][7] ? (
                            <Tooltip arrow title={t("Offline")}>
                              <IconButton
                                style={{ cursor: "default" }}
                                size="small"
                                onClick={() => {}}
                              >
                                <FlashOffOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip arrow title={t("Online")}>
                              <IconButton
                                style={{ cursor: "default" }}
                                size="small"
                                onClick={() => {}}
                              >
                                <FlashOnOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {/* question mark labels describing each module */}
                          <HelpToolTip help={BorealisModulesHelp[m][1]} />
                        </>
                      }
                    />
                  </React.Fragment>
                ))}
            </FormGroup>
          </Box>
          {!isGeekweek() && (
            <>
              <Divider style={{ marginBlock: "12px" }} />
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Typography variant="body1" style={{ display: "inline" }}>
                  {t("Data source:")}
                </Typography>

                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>Offline</Grid>
                    <Grid item>
                      <Switch
                        checked={onlineMode}
                        onChange={() => setOnlineMode(!onlineMode)}
                      />
                    </Grid>
                    <Grid item>Online</Grid>
                  </Grid>
                </Typography>
                <HelpToolTip
                  help={t(
                    "Whether to fetch the data from offline or online sources."
                  )}
                />
              </Box>
            </>
          )}
        </Paper>
      </Box>
      <Box my={2}>
        <Typography variant="body2" style={{ textAlign: "center" }}>
          {t("beaver.borealissearch.supportedtypes")}
        </Typography>
      </Box>

      {/* The search box */}
      <Paper>
        <TextField
          error={Boolean(errorText)}
          helperText={errorText ? "Error: " + errorText : null}
          id="outlined-multiline-static"
          label=""
          multiline
          rows={8}
          value={searchText}
          onChange={(event) => {
            dispatch(setSearchText(event.target.value));
          }}
          variant="outlined"
          fullWidth
        />
      </Paper>
      {errorText && !errorText.match(/malformed/) ? (
        <Box mt={2} style={{ textAlign: "center" }}>
          <img
            src="/ng/images/beaver_error_logo.png"
            alt="SAD BEAVER"
            style={{ maxHeight: "25vh", mixBlendMode: "darken" }}
          />
          <Typography>
            {t("An Error Occured!")}: {errorText}
          </Typography>
        </Box>
      ) : null}
      <br />

      {/* These are the search buttons and their functionalities */}
      <Box style={{ textAlign: "center" }}>
        {!searching ? (
          <ButtonGroup color="primary" style={{ paddingTop: "5px" }}>
            <Button
              onClick={() => {
                dispatch(setErrorText(""));
                doBorealisSearch(searchText);
              }}
            >
              {t("Search")}
            </Button>
            <Button
              onClick={() => {
                dispatch(setErrorText(""));
                dispatch(setSearchText(searchExamples.borealisSearchExample));
                doBorealisSearch(searchExamples.borealisSearchExample);
              }}
            >
              {t("Show Examples")}
            </Button>
            <Button
              onClick={() => {
                dispatch(setErrorText(""));
                dispatch(setSearchText(""));
              }}
            >
              {t("Clear")}
            </Button>
          </ButtonGroup>
        ) : (
          <>
            <Box my={2}>{t("Searching")} ... </Box>
            <LinearProgress />
          </>
        )}
      </Box>

      {responses && responses.length && (
        <>
          <Typography variant="h6">{t("Results")}:</Typography>
          {responses.map((r, i) => (
            <BorealisReport
              report={r}
              // populate the error with the report only if there is the reasonPhrase
              // field wich should only be populated on an error
              error={!!r.reasonPhrase ? r : null}
              key={i}
              // only expand the first one (i.e. when i is 0 it's false)
              startMinimized={!!i}
            />
          ))}
        </>
      )}
    </Box>
  );
}
