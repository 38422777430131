import React from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import {
  isExternal,
  isGeekweek,
  WhoAmIURL,
} from "components/beaver/helpers/ServerProperties";
import useAppLayout from "commons/components/hooks/useAppLayout";
import { useNavigate, useLocation } from "react-router-dom";
import { NotAllowedPage } from "./404";

export function SSOLogin() {
  const { t } = useTranslation();
  const { currentUser, setCurrentUser } = useAppLayout();
  const [error, setError] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const getWhoAmI = async () => {
    try {
      const res = await Axios.get(WhoAmIURL());

      if (res.data.name) {
        setCurrentUser({
          username: res.data.preferred_username
            ? res.data.preferred_username
            : res.data.email, //will be populated later via whoami
          name: res.data.name, // will be populated later
          email: res.data.preferred_username
            ? res.data.preferred_username
            : res.data.readOnlyEmail
            ? res.data.readOnlyEmail
            : res.data.email,
          avatar: null,
          server: currentUser.server,
          onetime: currentUser.onetime,
          login: Date.now(),
          // add the canSeeBorealis - external absolutely cannot, geekweek can only if the whomai says they can (proxy controlled), and lab, int, stg, dev can
          canSeeBorealis: isExternal()
            ? false
            : isGeekweek()
            ? res.data.canSeeBorealis
            : true,
        });
        window.location.reload();
      } else if (res.data.error && "not_logged_in" === res.data.error) {
        // see if already /auth/login, in which case you have a proxy problem (this will happen on localhost testing, obviously)
        if ("/auth/login" === window.location.pathname) {
          setError("Beaver SSO Error");
          setErrorMsg(
            "Beaver's Connection to Authenticate Appears to be Down.  Please Contact INO/Beaver group."
          );
        } else {
          // redirect to login
          window.location.href = "/auth/login#AUTO";
        }
      } else {
        // see if error and set accordingly
        res.data.error
          ? setError(res.data.error)
          : setError("An Error Occured");
        res.data.msg
          ? setErrorMsg(res.data.msg)
          : setErrorMsg("An Unexpected Error Occured while completing login");
      }
      // TODO - see if there is a way to do this some other way
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if ("/logout" === location.pathname) {
      setCurrentUser(null);
      //also have to remove the prefix
      localStorage.ssoPrefix = null;
      navigate(".", { replace: true });
      window.location.reload();
    }

    // do the axios login to /auth/whoami to see if that's there or redirect to /ng/home
    if ("..." === currentUser.username) {
      getWhoAmI();
    } else {
      // go home, because you are logged in already
      navigate(".", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return error ? (
    <NotAllowedPage error={error} errorMsg={errorMsg} />
  ) : (
    <p>{t("Completing Login ...")}</p>
  );
}
