import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  Menu,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from "@material-ui/core";
import useMyLayout, { BreadCrumbRoute } from "../hooks/useMyLayout";
import useAppLayout from "commons/components/hooks/useAppLayout";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import UserProfile, {
  UserMenuElement,
  UserProfileProps,
} from "../../commons/components/layout/topnav/UserProfile";
import { LeftNavElement } from "../../commons/components/layout/leftnav/LeftNavDrawer";
import QuickSearch from "commons/components/layout/topnav/QuickSearch";

const useStyles = makeStyles((theme) => ({
  header: {},
  canada: {
    color: "white",
    backgroundColor: "black",
    padding: "8px 16px",
  },
  canadaText: {
    color: "white",
    backgroundColor: "black",
    display: "inline-block",
    paddingRight: theme.spacing(3),
  },
  beaverLogo: {
    height: "4em",
  },
  appBar: {
    borderTop: "4px solid",
    borderTopColor: theme.palette.secondary.dark,
  },
  rowList: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  canImg: {
    height: "1.5em",
    filter: "brightness(0) invert(100%)",
  },
  headImg: {
    height: "4em",
  },
  headImg2: {
    height: "3.5em",
    paddingTop: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
}));

enum Lang {
  EN = "en",
  FR = "fr",
}

const isLang = (lang: Lang): boolean => {
  return i18n.language === lang.valueOf();
};
export const onToggleLanguage = () => {
  i18n.changeLanguage(isLang(Lang.EN) ? Lang.FR.valueOf() : Lang.EN.valueOf());
};

export type TopMenuProps = {
  nav: {
    elements: LeftNavElement[];
  };
  userMenu: UserMenuElement[];
  currentLayout: string;
  allowQuickSearch: boolean;
};

export function TopMenu({
  nav,
  userMenu,
  currentLayout,
  allowQuickSearch,
}: TopMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorId, setAnchorId] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const matches = useMediaQuery("(max-width:900px)");
  const classes = useStyles();

  const isStickyTopBar = currentLayout === "top";

  const { showQuickSearch } = useAppLayout();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 42.1833,
    target: undefined,
  });

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setAnchorId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorId(null);
  };
  return (
    <>
      <AppBar
        color="inherit"
        position={!trigger || !isStickyTopBar ? "static" : "fixed"}
        className={classes.appBar}
      >
        <Toolbar>
          <Box display="flex">
            <img
              src="/ng/images/beaver_logo5.png"
              alt="beaver"
              className={classes.beaverLogo}
            />
          </Box>
          {allowQuickSearch && showQuickSearch && <QuickSearch />}
          {nav.elements.map((val, i) => {
            if (!matches)
              if (val.type === "item")
                return (
                  <RouterLink
                    key={i}
                    to={val.element["route"]}
                    style={{ textDecoration: "none", whiteSpace: "nowrap" }}
                  >
                    <Button>{val.element["text"]}</Button>
                  </RouterLink>
                );
              else if (val.type === "group")
                return (
                  <Box key={i} display="inline" whiteSpace="nowrap">
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, val.element.id)}
                    >
                      {val.element["title"]}
                    </Button>
                    <Menu
                      id={"" + val.element.id}
                      anchorEl={anchorEl}
                      keepMounted
                      open={anchorId === val.element.id}
                      onClose={handleClose}
                    >
                      {val.element["items"].map((valb, j) => {
                        return (
                          <Box key={valb.id + j} whiteSpace="nowrap">
                            <RouterLink
                              to={valb.route}
                              style={{ textDecoration: "none" }}
                              onClick={(e) => {
                                handleClose();
                                handleDrawerClose();
                              }}
                            >
                              <Button>
                                {valb.icon}
                                {valb.text}
                              </Button>
                            </RouterLink>
                          </Box>
                        );
                      })}
                    </Menu>
                  </Box>
                );
            return null;
          })}
          {matches ? null : (
            <>
              <UserProfile />
            </>
          )}
          {matches ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="button" onClick={handleDrawerOpen}>
                Menu
              </Typography>
            </>
          ) : null}
        </Toolbar>
      </AppBar>
      <Drawer variant="persistent" open={open}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
        <List>
          {nav.elements.map((val, i) => {
            if (val.type === "item")
              return (
                <ListItem key={i}>
                  <RouterLink
                    to={val.element["route"]}
                    style={{ textDecoration: "none", whiteSpace: "nowrap" }}
                    onClick={handleDrawerClose}
                  >
                    <Button style={{ display: "flex", alignItems: "flex-start" }}>
                      <span>{val.element.icon}</span>
                      <span style={{ marginLeft: "8px" }}>{val.element["text"]}</span>
                    </Button>
                  </RouterLink>
                </ListItem>
              );
            else if (val.type === "group")
              return (
                <ListItem key={i}>
                  <Box key={i} display="inline" whiteSpace="nowrap">
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      style={{ display: "flex", alignItems: "flex-start" }}
                      onClick={(event) => handleClick(event, val.element.id)}
                    >
                      <span>{val.element.icon}</span>
                      <span style={{ marginLeft: "8px" }}>{val.element["title"]}</span>
                    </Button>
                    <Menu
                      id={"" + val.element.id}
                      anchorEl={anchorEl}
                      keepMounted
                      open={anchorId === val.element.id}
                      onClose={handleClose}
                    >
                      {val.element["items"].map((valb, j) => {
                        return (
                          <Box key={valb.id + j} whiteSpace="nowrap">
                            <RouterLink
                              to={valb.route}
                              style={{ textDecoration: "none" }}
                              onClick={(e) => {
                                handleClose();
                                handleDrawerClose();
                              }}
                            >
                              <Button>
                                {valb.icon}
                                {valb.text}
                              </Button>
                            </RouterLink>
                          </Box>
                        );
                      })}
                    </Menu>
                  </Box>
                </ListItem>
              );
            return null;
          })}
        </List>
        <Divider />
        <List>
          {userMenu.map((val, i) => {
            return (
              <ListItem key={i}>
                <RouterLink key={i} to={val.route}>
                  <Button style={{ display: "flex", alignItems: "flex-start" }}>
                    <span>{val.icon}</span>
                    <span style={{ marginLeft: "8px" }}>{val.name}</span>
                  </Button>
                </RouterLink>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </>
  );
}

export type BreadCrumbProps = {
  nav: { elements: LeftNavElement[] };
  bcRoutes: BreadCrumbRoute[];
};

export function BreadCrumb({ nav, bcRoutes }: BreadCrumbProps) {
  const { t } = useTranslation();
  // by adding this variable, it forces it too look at the lcoation on each change.
  const location = useLocation();
  let curPath = location.pathname.replace(new RegExp("/$"), "");
  let breadCrumb = [];
  // if it's not there, then get the title somehow.
  for (const item of nav.elements) {
    if (item.type === "item") {
      if (curPath === item.element["route"]) {
        breadCrumb.push({
          text: item.element["text"],
          route: item.element["route"],
        });
      }
    } else if (item.type === "group") {
      for (const gitem of item.element["items"]) {
        // sometimes e.g. /item?create is used instead for some reason.
        if (
          curPath === gitem.route ||
          curPath + location.search.replace(new RegExp("&.*$"), "") ===
            gitem.route ||
          location.pathname +
            location.search.replace(new RegExp("&.*$"), "") ===
            gitem.route
        ) {
          // add parent
          breadCrumb.push({ text: item.element["title"], route: null });
          // add child
          breadCrumb.push({ text: gitem.text, route: gitem.route });
        }
      }
    }
  }
  // see if there are any breadcrumbroutes defined if nothing found
  if (breadCrumb.length === 0) {
    for (const bc of bcRoutes) {
      if (curPath.match(new RegExp(bc.regexp)) !== null) {
        // push parent if defined first
        if (bc.parentText !== undefined)
          breadCrumb.push({ text: bc.parentText, route: bc.parentRoute });
        breadCrumb.push({ text: bc.text, route: null });
      }
    }
  }
  // debugger;
  // if the first element is not home, add home to front
  if (breadCrumb.length === 0 || breadCrumb[0].route !== "/home") {
    breadCrumb.unshift({ text: t("drawer.home"), route: "/" });
  }
  return (
    <Box display="flex" alignItems="center" mt={1}>
      {breadCrumb.map((val, i) => {
        if (i === breadCrumb.length - 1)
          return (
            <Button key={val + i} href={null}>
              {val.text}
            </Button>
          );
        else if (val.route === null && i !== breadCrumb.length - 1)
          return (
            <React.Fragment key={i}>
              <Button key={val + i}>{val.text}</Button>
              <ChevronRightIcon />
            </React.Fragment>
          );
        return (
          <Box display="flex" alignItems="center" key={val + i}>
            <RouterLink to={val.route}>
              <Button>{val.text}</Button>
            </RouterLink>
            <ChevronRightIcon />
          </Box>
        );
      })}
    </Box>
  );
}

export type HeaderProps = {
  user: UserProfileProps | null;
  currentLayout: string;
};

export function Header({ user, currentLayout }: HeaderProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const layout = useMyLayout();
  return (
    <>
      <Box className={classes.canada}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item md={3}>
            <img
              alt="Canada"
              src="/ng/images/sig-en.svg"
              className={classes.canImg}
            />
          </Grid>
          <Grid item md={8}>
            <Box textAlign="right">
              <Link
                underline="none"
                href="#"
                className={classes.canadaText}
                onClick={onToggleLanguage}
              >
                <Typography variant="button">
                  {t("header.text.opposite.language")}
                </Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.header}>
        {user ? (
          <>
            <TopMenu
              nav={layout.leftnav}
              userMenu={layout.topnav.userMenu}
              currentLayout={currentLayout}
              allowQuickSearch={layout.allowQuickSearch}
            />
            <Grid container justifyContent="center">
              <Grid item xs={11}>
                <BreadCrumb
                  nav={layout.leftnav}
                  bcRoutes={layout.breadCrumbRoutes}
                />
              </Grid>
            </Grid>
          </>
        ) : null}
      </Box>
    </>
  );
}
