import { useTranslation } from "react-i18next";
import PageHeader from "../../commons/components/layout/pages/PageHeader";
import { SearchPage } from "../beaver/components/search/SearchPage";
import { Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  isExternal,
  isGeekweek,
} from "components/beaver/helpers/ServerProperties";
import { isSimplified } from "components/beaver/helpers/ServerProperties";
import PageFullWidth from "commons/components/layout/pages/PageFullWidth";

export function OtherSearches({
  currentSearchPage,
}: {
  currentSearchPage: "borealis" | "regular" | "url" | "cafe";
}) {
  const { t } = useTranslation();

  return (
    <>
      {/* TODO add a help button on these searches explaining what they are */}
      <Box ml={3} style={{ display: "flex" }}>
        <p>{t("Other Searches")}: </p>
        {"regular" !== currentSearchPage && !isExternal() && !isGeekweek() && (
          <Button color="primary" component={Link} to={"/search"}>
            {t("Beaver Search")}
          </Button>
        )}
        {"borealis" !== currentSearchPage && !isExternal() && !isGeekweek() && (
          <Button color="primary" component={Link} to={"/borealisSearch"}>
            {t("Borealis Search")}
          </Button>
        )}
        {"url" !== currentSearchPage && (
          <Button color="primary" component={Link} to={"/urlsearch"}>
            {t("URL Search")}
          </Button>
        )}{" "}
        {"cafe" !== currentSearchPage && !isExternal() && !isSimplified() && (
          <Button color="primary" component={Link} to={"/cafesearch"}>
            {t("Cafe Spam Emails Search")}
          </Button>
        )}
      </Box>
    </>
  );
}

function Search(props) {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader title={t("page.search.title")} />
      <PageFullWidth noTopMargin>
        <OtherSearches currentSearchPage="regular" />
        <SearchPage />
      </PageFullWidth>
    </>
  );
}

export default Search;
