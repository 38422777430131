import { Box, makeStyles } from "@material-ui/core";
import useAppLayout from "../hooks/useAppLayout";

const useStyles = (useTBSLayout, open) => {
    return makeStyles((theme) => ({
        container: {
            [theme.breakpoints.up("sm")]: {
              marginLeft: 
                useTBSLayout
                  ? 0
                  : theme.spacing(7) + 1,
            },
            [theme.breakpoints.up("md")]: {
              marginLeft: 
                useTBSLayout
                  ? 0
                  : open
                    ? theme.spacing(7) + 262 - 56 + 1
                    : theme.spacing(7) + 1,
            },
            marginTop: useTBSLayout ? 0 : theme.spacing(3),
            paddingTop: useTBSLayout ? theme.spacing(2) : theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
    }))();
};

type MainContentContainerProps = {
  children: React.ReactNode;
};
const MainContentContainer = (props: MainContentContainerProps) => {
    const { useTBSLayout, drawerState } = useAppLayout();
    const classes = useStyles(useTBSLayout, drawerState);
    return (
        <Box className={classes.container}>{props.children}</Box>
    )
}

export default MainContentContainer;