import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import {
  isExternal,
  LiveHuntJSON,
  LiveHuntRuleJSON,
  PhishingKitJSON,
  PhishingKitListJSON,
  PriorityListAll,
  PriorityListBot,
  PriorityListHuman,
  PriorityListUser,
  SHA1ReportJSON,
  SHA256ReportJSON,
  SpamPhishingJSON,
  TagJSON,
  TypeReportJSON,
  TypeReportJSONSystem,
  URLMD5ClustersListJSON,
  URLMD5ClustersTagJSON,
  URLMD5CpanelClustersListJSON,
  URLMD5PhishingClustersListJSON,
  isGeekweek,
} from "../components/beaver/helpers/ServerProperties";
import { ReportType } from "../components/beaver/models/report/reportType";

//TODO - this should make the localstorage null if it can't parse- put this somewhere and export
const user = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser"))
  : {};

// TODO : define this more definitely (e.g. error?: string),
export type BeaverNGState = {
  responseData: any;
  error: string;
  reportType: ReportType;
  // so far quick is only used in the type reports (e.g. ip report, sid report, etc. !!! be careful with it's use)
  quick: boolean;
};

export const slice = createSlice({
  name: "beaverng",
  initialState: {} as BeaverNGState,
  reducers: {
    clearReport: (state) => {
      state.error = null;
      state.reportType = null;
      // state is clearing, you want to keep the previous yaraRules so you can iterate through it maybe
      let yaraRules = state.responseData ? state.responseData.yaraRules : null;
      state.responseData = { yaraRules: yaraRules };
    },
    setReportType: (state, { payload }) => {
      state.reportType = payload;
    },
    setReport: (state, { payload }) => {
      state.responseData = { ...state.responseData, ...payload };
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setQuick: (state, { payload }) => {
      state.quick = payload ? payload : false;
    },
  },
});

export default slice.reducer;

// the state is {beaverng:{beaverng:{}}} to begin with - notice the extra query that wraps everything (set from the index)!
// use the Redux chrome plugin to inspect the state
// TODO - figure out the typescript type of state, dispatch, etc.
export const beaverNGSelector = (state) => state.beaverng;

export const clearReport = () => async (dispatch) => {
  console.log("clearing report");
  dispatch(slice.actions.clearReport);
};

/**
 * This function does the actual Axios call
 * @param dispatch pass along from the async call
 * @param thunk The ServerProperties resta call to run
 * @param type e.g. md5, url, etc.
 * @param quickThunk The ServerProperties resta quick call to run, can be null (no call)
 */
export const doRequest = (slice, dispatch, thunk, type: ReportType, quickThunk?) => {
  // first clear
  dispatch(slice.actions.clearReport());

  if (quickThunk) {
    // do quick one first, do not defer results
    const quickRequestCompletion = doInternalRequest(slice, dispatch, quickThunk, type, Promise.resolve());
    // do the rest, wait for quick completion to set the results
    doInternalRequest(slice, dispatch, thunk, type, quickRequestCompletion);
  } else {
    // do the only one, do not defer results
    doInternalRequest(slice, dispatch, thunk, type, Promise.resolve())
  }
}

/**
 * This function does the actual Axios call
 * @param dispatch pass along from the async call
 * @param thunk The ServerProperties resta call to run
 * @param type e.g. md5, url, etc.
 * @param resultDeferrer promise on which to wait before setting the results. Set to resolved promise for no wait (Promise.resolve())
 */
const doInternalRequest = (slice, dispatch, thunk, type: ReportType, resultDeferrer: Promise<void>) => {
  // now do the axios rest call to get the data
  // NOTE:  The reason we don't just use const auth object or similar instead of
  // the large auth object below is because we want to obfuscate this and make
  // it harder to undertand
  return Axios.get(thunk, {
    auth:
      user && user.server && !user.server.includes("localhost")
        ? {
            username: user ? user.username : null,
            password: user
              ? user.onetime
                ? // eslint-disable-next-line no-eval
                  window.atob(eval(window.atob(user.onetime))).substr(13)
                : null
              : null,
          }
        : null,
  }).then(
    (response) => {
      response.data.loaded = true;

      //we need to reverse the flatCriterias List for view.
      if (response.data.flatCriterias) response.data.flatCriterias.reverse();

      resultDeferrer.finally(() => {
        dispatch(
          // so what's happening here is that the upload sample and upload url returns an array from beaver legacy, so we have to wrap it in an object
          slice.actions.setReport(
            [ReportType.UPLOAD_SAMPLE, ReportType.UPLOAD_URL].includes(type)
              ? { [type]: response.data }
              : response.data
          )
        );
        dispatch(slice.actions.setReportType(type));
      });
    },
    (error) => {
      resultDeferrer.finally(() => {
        dispatch(
          slice.actions.setError(
            error.response
              ? JSON.stringify(error.response)
              : JSON.stringify({
                  status: error.message.match(/\d+/g)
                    ? parseInt(error.message.match(/\d+/g)[0])
                    : 500,
                  message: error.message,
                  data: JSON.stringify(error),
                })
          )
        );
      })
    }
  );
};

function getAxiosTypeReport(
  type,
  value,
  system = null,
  fromDate = null,
  toDate = null
) {
  if (!system)
    return Axios.get(TypeReportJSON(type, value), {
      auth:
        user && user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    });

  return Axios.get(
    TypeReportJSONSystem(type, value, system) +
      "?" +
      (fromDate ? "from=" + fromDate : "") +
      (toDate ? "to=" + toDate : ""),
    {
      auth:
        user && user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    }
  );
}

export const doTypeRequest = (
  slice,
  dispatch,
  type: ReportType,
  value: string,
) => {
  // first clear
  dispatch(slice.actions.clearReport());
  // do quick one first, do not defer results
  const quickRequestCompletion = doInternalTypeRequest(slice, dispatch, type, value, true, Promise.resolve());
  // do the rest, wait for quick completion to set the results
  doInternalTypeRequest(slice, dispatch, type, value, false, quickRequestCompletion);
}
  
/**
 * This function does the actual Axios calls for this report type
 * @param dispatch pass along from the async call
 * @param type report type
 * @param value value to query
 * @param quick whether to do a quick version of the request, aka with reduced external system calls
 * @param resultDeferrer promise on which to wait before setting the results. Set to resolved promise for no wait (Promise.resolve())
 */
const doInternalTypeRequest = (
  slice,
  dispatch,
  type: ReportType,
  value: string,
  quick: boolean,
  resultDeferrer: Promise<void>
) => {
  // now do the axios rest call to get the data
  // TODO - do this properly later. - if the server is int, then don't use maxmind, if external, then only beaver!
  // e.g. if do the typereport json and use systems listed therein.
  // for a slow to load report, namely 18.213.250.117, the BISON, EFS, MAXMIND and DFS load faster than the json alone.
  const systemsJSON =
    quick
      ? [
          getAxiosTypeReport(type, value),
        ]
      : isExternal()
      ? [
          null, // Base, fetched with quick
          getAxiosTypeReport(type, value, "DFS"),
          getAxiosTypeReport(type, value, "BEAVER"),
          getAxiosTypeReport(type, value, "MOOSE"),
        ]
      : isGeekweek()
      ? [
          null, // Base, fetched with quick
          getAxiosTypeReport(type, value, "DFS"),
          getAxiosTypeReport(type, value, "BISON"),
          getAxiosTypeReport(type, value, "EFS"),
          getAxiosTypeReport(type, value, "BEAVER"),
          getAxiosTypeReport(type, value, "MOOSE"),
          getAxiosTypeReport(type, value, "RACCOON"),
        ]
      : [
          null, // Base, fetched with quick
          getAxiosTypeReport(type, value, "DFS"),
          getAxiosTypeReport(type, value, "BISON"),
          getAxiosTypeReport(type, value, "EFS"),
          getAxiosTypeReport(type, value, "MAXMIND"),
          getAxiosTypeReport(type, value, "BEAVER"),
          getAxiosTypeReport(type, value, "MOOSE"),
          getAxiosTypeReport(type, value, "RACCOON"),
        ];

  // the order in the spread below matters ! it has to be in the same order as above !
  return Axios.all(systemsJSON)
    .then(
      Axios.spread(
        async (typeResp, one, two, three, four, five, six, seven) => {
          // these will return in order the type reports above, so needs to match in the below stuff!
          let subreports = isExternal()
            ? {
                DFS: one?.data ? one.data : null,
                BEAVER: two?.data ? two.data : null,
                MOOSE: three?.data ? three.data : null,
              }
            : isGeekweek()
            ? {
                DFS: one?.data ? one.data : null,
                BISON: two?.data ? two.data : null,
                EFS: three?.data ? three.data : null,
                BEAVER: four?.data ? four.data : null,
                MOOSE: five?.data ? five.data : null,
                RACCOON: six?.data ? six.data : null,
              }
            : {
                DFS: one?.data ? one.data : null,
                BISON: two?.data ? two.data : null,
                EFS: three?.data ? three.data : null,
                MAXMIND: four?.data ? four.data : null,
                BEAVER: five?.data ? five.data : null,
                MOOSE: six?.data ? six.data : null,
                RACCOON: seven?.data ? seven.data : null,
              };

          resultDeferrer.finally(() => {
            dispatch(
              slice.actions.setReport({
                ...typeResp?.data,
                loaded: true,
                subreports: subreports,
              })
            );
            dispatch(slice.actions.setReportType(type));
            dispatch(slice.actions.setQuick(quick));
          });
        }
      )
    )
    .catch((error) => {
      resultDeferrer.finally(() => {
        dispatch(
          slice.actions.setError(
            error.response
              ? JSON.stringify(error.response)
              : JSON.stringify({
                  status: error.message.match(/\d+/g)
                    ? parseInt(error.message.match(/\d+/g)[0])
                    : 500,
                  message: error.message,
                  data: JSON.stringify(error),
                })
          )
        );
      });
    });
};

// type is e.g. md5 or domain, value is e.g. the md5 or domain value / DNS
export const fetchReport =
  (type: ReportType, value?: string, subvalue?: string) => async (dispatch) => {
    if (ReportType.SHA1 === type) {
      doRequest(slice, dispatch, SHA1ReportJSON(value), ReportType.MD5);
    } else if (ReportType.SHA256 === type) {
      doRequest(slice, dispatch, SHA256ReportJSON(value), ReportType.MD5);
    } else if (
      [
        ReportType.SID,
        ReportType.IP,
        ReportType.EMAIL,
        ReportType.DOMAIN,
      ].includes(type)
    ) {
      doTypeRequest(slice, dispatch, type, value);
    } else if (ReportType.PHISHING === type) {
      // the json request will determine if md5 or tag
      doRequest(slice, dispatch, PhishingKitJSON(value), type);
    } else if (ReportType.PHISHING_LIST === type) {
      doRequest(slice, dispatch, PhishingKitListJSON(), type);
    } else if (ReportType.TAG === type) {
      doRequest(slice, dispatch, TagJSON(value), type);
    } else if (ReportType.PRIORITY_LIST_ALL === type) {
      doRequest(slice, dispatch, PriorityListAll(), type);
    } else if (ReportType.PRIORITY_LIST_HUMAN === type) {
      doRequest(slice, dispatch, PriorityListHuman(), type);
    } else if (ReportType.PRIORITY_LIST_USER === type) {
      doRequest(slice, dispatch, PriorityListUser(value), type);
    } else if (ReportType.PRIORITY_LIST_BOT === type) {
      doRequest(slice, dispatch, PriorityListBot(), type);
    } else if (ReportType.SPAM_PHISHING === type) {
      doRequest(slice, dispatch, SpamPhishingJSON(), type);
    } else if (ReportType.LIVE_HUNT === type) {
      doRequest(slice, dispatch, LiveHuntJSON(), type);
    } else if (ReportType.LIVE_HUNT_RULE === type) {
      doRequest(slice, dispatch, LiveHuntRuleJSON(value), type);
    } else if (ReportType.URL_MD5_TAG_LIST === type) {
      doRequest(slice, dispatch, URLMD5ClustersListJSON(), type);
    } else if (ReportType.CPANEL_URL_MD5_TAG_LIST === type) {
      doRequest(slice, dispatch, URLMD5CpanelClustersListJSON(), type);
    } else if (ReportType.PHISHING_URL_MD5_TAG_LIST === type) {
      doRequest(slice, dispatch, URLMD5PhishingClustersListJSON(), type);
    } else if (ReportType.URL_MD5_TAG === type) {
      doRequest(slice, dispatch, URLMD5ClustersTagJSON(value, subvalue), type);
    } else {
      console.log(`Dispatch ${type} with value ${value} does not exist`);
    }
  };
