import { Box, Button, Grid, Paper, useTheme } from "@material-ui/core";
import useAppLayout from "commons/components/hooks/useAppLayout";
import { TermsText } from "components/beaver/components/policy/TermsAndConditions";
import { onToggleLanguage } from "components/tbslayout/Header";
import { useTranslation } from "react-i18next";

export function AcceptTerms() {
  const theme = useTheme();
  const { getBanner, useTBSLayout } = useAppLayout();

  if (useTBSLayout) {
    return (
      <Grid container justify="center" spacing={2}>
        <Grid item xs={11} md={8}>
          <Box color={theme.palette.primary.main} fontSize="30pt">
            {getBanner(theme)}
          </Box>
          <TermsPrompt />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12} sm={11} md={9} lg={7} xl={6}>
        <Paper>
          <Box p={3}>
            <Box color={theme.palette.primary.main} fontSize="30pt">
              {getBanner(theme)}
            </Box>
            <TermsPrompt />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

function TermsPrompt() {
  const { t } = useTranslation();
  return (
    <>
      <p
        style={{
          textAlign: "center",
        }}
      >
        {t("You must accept the terms and conditions to continue")}:
        <Button onClick={onToggleLanguage}>
          {t("header.text.opposite.language")}
        </Button>
      </p>
      <TermsText />
      <Box mt={3} textAlign="center">
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => {
            localStorage.termsAccepted = Date.now();
            window.location.reload();
          }}
        >
          {t("Agree")}
        </Button>
      </Box>
    </>
  );
}
