import { useState } from "react";
import { alpha, Box, InputBase, makeStyles } from "@material-ui/core";
import useAppLayout from "../../hooks/useAppLayout";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useStyles = (layout, useTBSLayout, open) => {
  return makeStyles((theme) => ({
    search: {
      flexGrow: 1,
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.text.primary, 0.04),
      "&:hover": {
        backgroundColor: alpha(theme.palette.text.primary, 0.06),
      },
      width: "100%",
      [theme.breakpoints.up("md")]: {
        marginLeft:
          layout === "side" && !useTBSLayout
            ? open
              ? theme.spacing(7) + 262 - 56
              : theme.spacing(7)
            : theme.spacing(3),
      },
      marginLeft: 
          layout === "side" && !useTBSLayout
            ? theme.spacing(7) 
            : theme.spacing(3),
      marginRight: theme.spacing(2),
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
    },
  }))();
};

const QuickSearch = () => {
  const { t } = useTranslation();
  const { currentLayout, useTBSLayout, drawerState, layoutProps } = useAppLayout();
  const classes = useStyles(currentLayout, useTBSLayout, drawerState);
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const uri = layoutProps.topnav.quickSearchURI
    ? layoutProps.topnav.quickSearchURI
    : "/search/";
  const param = layoutProps.topnav.quickSearchParam
    ? layoutProps.topnav.quickSearchParam
    : "q";

  const submit = (event) => {
    event.preventDefault();
    navigate(`${uri}?${param}=${value}`);
    setValue("");
  };

  return (
    <Box className={classes.search}>
      <form autoComplete="off" onSubmit={submit}>
        <Box className={classes.searchIcon}>
          <SearchIcon />
        </Box>
        <InputBase
          onChange={(event) => setValue(event.target.value)}
          placeholder={t("quicksearch.placeholder")}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={value}
          inputProps={{ "aria-label": t("quicksearch.aria") }}
        />
      </form>
    </Box>
  );
};

export default QuickSearch;
