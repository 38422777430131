import { Box, makeStyles } from "@material-ui/core";
import useAppLayout from "../../hooks/useAppLayout";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  page: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    width: "100%",
  },
  cccsPage: { marginTop: theme.spacing(3) },
  tbsPage: {
    [theme.breakpoints.up("sm")]: {
      width: "95%",
    },
  },
}));

export default function PageFullWidth({ 
  children, 
  noTopMargin 
}: {
  children: any;
  noTopMargin?: boolean;
}) {
  const classes = useStyles();
  const layout = useAppLayout();

  return (
    <Box
      style={{ marginTop: noTopMargin ? "0px" : null }}
      className={clsx(classes.page, {
        [classes.cccsPage]: !layout.layoutProps.useTBSLayout,
        [classes.tbsPage]: layout.layoutProps.useTBSLayout,
      })}
    >
      {children}
    </Box>
  );
}
