import { combineReducers } from "redux";

import beaverng from "./beaverng";
import sources from "./sources";
import compliance from "./compliance";
import search from "./search";
import elasticurlsearch from "./elasticurlsearch";
import elasticcafesearch from "./elasticcafesearch";
import uploadurls from "./uploadurls";
import uploadsamples from "./uploadsamples";
import md5report from "./md5report";
import sidreport from "./sidreport";
import ipreport from "./ipreport";
import domainreport from "./domainreport";
import urlreport from "./urlreport";
import clusters from "./clusters";
import phishingclusters from "./phishingclusters";
import tags from "./tags";
import preview from "./preview";
import borealissearch from "./borealissearch";
import borealis from "./borealis";
import misplogan from "./misplogan";

const rootReducer = combineReducers({
  beaverng,
  sources,
  compliance,
  search,
  elasticurlsearch,
  elasticcafesearch,
  uploadurls,
  uploadsamples,
  md5report,
  sidreport,
  ipreport,
  domainreport,
  urlreport,
  clusters,
  phishingclusters,
  tags,
  preview,
  borealissearch,
  borealis,
  misplogan,
});

export default rootReducer;
