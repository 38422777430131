/* eslint-disable no-unused-vars */
import axios from "axios";
import { ReportType } from "../models/report/reportType";

// make mock == true to get the mocks in the jsonmocks public folder !!
const mock = JSON.parse(localStorage.getItem("currentUser"))
  ? "mock" === JSON.parse(localStorage.getItem("currentUser")).server
  : false;

export enum BeaverInstance {
  LOCAL, // Local
  STG, // Staging
  SSTG, // Simplified staging
  LAB, // Lab
  INT, // Internal
  EXT, // External
  GWK // GeekWeek / Collaboration
}

// This setting will be overriden by the deploy scripts
export const CurrentInstance = BeaverInstance.EXT;

if (isLocal()) {
  axios.defaults.withCredentials = true
}

export function BeaverServer() {
  // TODO handle the setting of this info via package.json or some other build (maybe even env. property??)

  // handle case when the local storage current user or sso prefix is not cleared correctly
  if (
    localStorage.getItem("currentUser") === "null" ||
    localStorage.getItem("ssoPrefix") === "null"
  ) {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("ssoPrefix");
  }

  let server = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")).server
    : "";
  if (ssoPrefix() && "http://localhost:9001" === server)
    server = "http://localhost:5000";
  return localStorage.getItem("currentUser")
    ? ssoPrefix()
      ? server + ssoPrefix()
      : server
    : "";
}

export function isExternal(): boolean {
  return CurrentInstance.valueOf() === BeaverInstance.EXT.valueOf();
}

export function isGeekweek(): boolean {
  return CurrentInstance.valueOf() === BeaverInstance.GWK.valueOf();
}

export function isInternal(): boolean {
  return CurrentInstance.valueOf() === BeaverInstance.INT.valueOf();
}

export function isLab(): boolean {
  return CurrentInstance.valueOf() === BeaverInstance.LAB.valueOf();
}

export function isStaging(): boolean {
  return CurrentInstance.valueOf() === BeaverInstance.STG.valueOf() ||
         CurrentInstance.valueOf() === BeaverInstance.SSTG.valueOf();
}

export function isSimplified(): boolean {
  // add the environments where the stripped down / simplified beaver should be shown

  // ☑ Remove the following sections: ✓ Tags, ✓ Phishing kit, ✓ Retrohunt, ✓ Priority ( ✗ this includes removing pivot links from other pages to these pages
  // ☑ Remove from Search page: ✓ CAFE SPAM email search, ✓ Search history, ✓ Recent Search and ✓ Save this search.
  // ☑ Adjust the Search to consider only the following artifacts: sample, ip, url, domain
  // ☑ Description: Types supported (one per line): IP, Domain, MD5 (sample or url)
  // ☑ And add an example url (https://qazaqtravel.kz/admin/login)

  return CurrentInstance.valueOf() === BeaverInstance.SSTG.valueOf();
}

export function isLocal(): boolean {
  return CurrentInstance.valueOf() === BeaverInstance.LOCAL.valueOf();
}

export function WhoAmIURL() {
  let server = JSON.parse(localStorage.getItem("currentUser")).server;
  // localhost requires different port number, you need to be running the oidc project locally.
  return (("http://localhost:9001" === server ? "http://localhost:5000" : server) + "/auth/whoami");
}

export function ssoPrefix() {
  return localStorage.getItem("ssoPrefix") &&
    localStorage.getItem("ssoPrefix") !== "null"
    ? localStorage.getItem("ssoPrefix")
    : "";
}

export function loganMispURL(): string {
  return "https://logan.goat.chimera.cyber.gc.ca";
}

export function BorealisQueryJSON(
  query: string,
  modules?: string[],
  offlineMode?: boolean
) {
  if (mock) {
    if ("50.63.110.1" === query) return "/ng/jsonmocks/borealis/query2.json";
    else if ("canada.ca" === query) return "/ng/jsonmocks/borealis/query3.json";
    else if ("dpbqyqxynbip.ru" === query)
      return "/ng/jsonmocks/borealis/query4.json";
    else if ("error.com" === query) return "/ng/jsonmocks/borealis/error.json";
    else return "/ng/jsonmocks/borealis/query.json";
  }
  return (
    BeaverServer() +
    "/borealis/?query=" +
    query +
    (modules && modules.length
      ? "&modules=" + modules.join(",") + (offlineMode ? "&" : "")
      : "") +
    (offlineMode ? "mode=OFFLINE" : "")
  );
}

export function getResourceLink(resourceLink, index?: number) {
  // added an index - index overrides filename as per backend docs
  return mock
    ? "/ng/images/beaverng.jpg"
    : BeaverServer() + resourceLink + (index ? "?index=" + index : "");
}

// sourcelist is e.g. http://localhost:9001/sourceList
export function SourceListJSON() {
  return mock ? "/ng/jsonmocks/sources.json" : BeaverServer() + "/sourceList";
}

// this one is wierd since the mock is a get and the server uses post - see the SearchBox.jsx on how to use
export function SearchTermsJSON() {
  if (mock) return { method: "get", url: "/ng/jsonmocks/search/terms.json" };
  return { method: "post", url: BeaverServer() + "/search/terms/quick" };
}

export function SearchMoreJSON() {
  if (mock) return { method: "get", url: "/ng/jsonmocks/search/terms.json" };
  return { method: "post", url: BeaverServer() + "/search/terms/more/quick" };
}

// search elastic e.g. http://localhost:9001/elasticURLSearch?query=cra%20and%20cerb&from=20210101&size=20
export function SearchElasticURLJSON(
  query,
  to,
  from,
  size,
  startAt,
  sortDate,
  excludeErrored
) {
  let queryString = `?query=${query}${!!from ? "&from=" + from : ""}${!!to ? "&to=" + to : ""
    }${!!size ? "&size=" + size : ""}${!!startAt ? "&startAt=" + startAt : ""}${sortDate ? "&sortDesc=received_on" : ""
    }${excludeErrored ? "&excludeErrored=true" : ""}`;

  if (mock && !sortDate)
    return `/ng/jsonmocks/search/elastic.json${queryString}`;
  else if (mock && sortDate)
    return `/ng/jsonmocks/search/elasticsortbydate.json${queryString}`;

  return BeaverServer() + `/elasticURLSearch${queryString}`;
}

// TODO - fix the terms and query string accordingly to terms also the mock files!
export function SearchElasticCafeJSON(query, size, startAt) {
  let queryString = `?query=${query}${!!startAt ? "&startAt=" + startAt : ""}`;

  if (mock) return `/ng/jsonmocks/search/elasticcafe.json${queryString}`;

  return BeaverServer() + `/elasticCafeSearch${queryString}`;
}

export function UploadSample() {
  if (mock)
    return { method: "get", url: "/ng/jsonmocks/upload/uploadsample.json" };
  return { method: "post", url: BeaverServer() + "/upload" };
}

export function UploadUrl() {
  if (mock)
    return { method: "get", url: "/ng/jsonmocks/upload/uploadurl.json" };
  return { method: "post", url: BeaverServer() + "/upload" };
}

// e.g. http://localhost:9001/md5/86385447ce35c120511647c1ade5cef9/json
export function MD5ReportJSON(md5) {
  if (mock) {
    if (md5 === "0adb15f80d1f00974b392c86c72c6e01")
      return "/ng/jsonmocks/md5/pandorareport.json";
    else if (md5 === "1e40e651e4a041de689d9871ea01f479")
      return "/ng/jsonmocks/md5/shadowservercw.json";
    else if (md5 === "0ad25b86f1cbdf8baf6507c1aa6c24fa")
      return "/ng/jsonmocks/md5/shadowservercw2.json";
    else if (md5 === "0001399358cd4a22d7dc345f88f3087d")
      return "/ng/jsonmocks/md5/shadowserverz.json";
    else if (md5 === "000000882fdf0865c083d35b6ad5f9f0")
      return "/ng/jsonmocks/md5/sunbelt.json";
    else if (md5 === "03150919d43e9fd954f14d8ed4520d9f")
      return "/ng/jsonmocks/md5/newhashreport.json";
    else if (md5 === "53dab40b6c4aab9949f0435f6aa2c489")
      return "/ng/jsonmocks/md5/newfiletype.json";
    else if (md5 === "25a92fee1f45b81cfa8ba98cf1bc8e3e")
      return "/ng/jsonmocks/md5/yaraAssemblyLineRules.json";
    else if (md5 === "04ecfc3fa0c53151d976f2d6fbd65c31")
      return "/ng/jsonmocks/md5/04ecfc3fa0c53151d976f2d6fbd65c31.json";
    // assemblyline report
    else if (md5 === "000000092a1510efb1ab86801ab83dca")
      return "/ng/jsonmocks/md5/000000092a1510efb1ab86801ab83dca.json";
    // yara assemblyline report
    else if (md5 === "25a92fee1f45b81cfa8ba98cf1bc8e3e")
      return "/ng/jsonmocks/md5/25a92fee1f45b81cfa8ba98cf1bc8e3e.json";
    return "/ng/jsonmocks/md5/report.json";
  }
  return BeaverServer() + "/md5/" + md5 + "/json";
}

// e.g. http://localhost:9001/md5/86385447ce35c120511647c1ade5cef9/quick
export function MD5ReportJSONQuick(md5) {
  if (mock) {
    if (md5 === "0adb15f80d1f00974b392c86c72c6e01")
      return "/ng/jsonmocks/md5/pandorareport.json";
    else if (md5 === "1e40e651e4a041de689d9871ea01f479")
      return "/ng/jsonmocks/md5/shadowservercw.json";
    else if (md5 === "0ad25b86f1cbdf8baf6507c1aa6c24fa")
      return "/ng/jsonmocks/md5/shadowservercw2.json";
    else if (md5 === "0001399358cd4a22d7dc345f88f3087d")
      return "/ng/jsonmocks/md5/shadowserverz.json";
    else if (md5 === "000000882fdf0865c083d35b6ad5f9f0")
      return "/ng/jsonmocks/md5/sunbelt.json";
    else if (md5 === "03150919d43e9fd954f14d8ed4520d9f")
      return "/ng/jsonmocks/md5/newhashreport.json";
    else if (md5 === "53dab40b6c4aab9949f0435f6aa2c489")
      return "/ng/jsonmocks/md5/newfiletype.json";
    else if (md5 === "25a92fee1f45b81cfa8ba98cf1bc8e3e")
      return "/ng/jsonmocks/md5/yaraAssemblyLineRules.json";
    else if (md5 === "04ecfc3fa0c53151d976f2d6fbd65c31")
      return "/ng/jsonmocks/md5/04ecfc3fa0c53151d976f2d6fbd65c31.json";
    // assemblyline report
    else if (md5 === "000000092a1510efb1ab86801ab83dca")
      return "/ng/jsonmocks/md5/000000092a1510efb1ab86801ab83dca.json";
    // yara assemblyline report
    else if (md5 === "25a92fee1f45b81cfa8ba98cf1bc8e3e")
      return "/ng/jsonmocks/md5/25a92fee1f45b81cfa8ba98cf1bc8e3e.json";
    return "/ng/jsonmocks/md5/report.json";
  }
  return BeaverServer() + "/md5/" + md5 + "/quick";
}

//e.g. http://localhost:9001/md5/86ad5df5230717b84f141057c37d1d18/jsonStatus
export function MD5ReportJSONStatus(md5) {
  return BeaverServer() + "/md5/" + md5 + "/jsonStatus";
}

export function YaraPivotJSON(yaraRuleId: number) {
  if (mock) return "/ng/jsonmocks/md5/yararulepivot.json";
  return BeaverServer() + "/yara/" + yaraRuleId + "/fullRulePivot";
}

export function YaraRuleFullJSON(yaraRuleId: number) {
  if (mock) return "/ng/jsonmocks/md5/yararulefulljson.json";
  return BeaverServer() + "/yara/" + yaraRuleId + "/fullRuleJson";
}

export function setTagsJSON(md5) {
  if (mock) return null;
  return BeaverServer() + "/md5/" + md5 + "/report/setTags";
}

// e.g. https://beaver.ccirc.lab/url/b51e37992f4465d51d5f8e908ee7a0e2/report
export function URLReportJSON(md5) {
  if (mock) {
    if (md5 === "e6de9bd28b04074523450145ef02b7aa")
      return "/ng/jsonmocks/url/md5/2/report.json";
    else if (md5 === "ad47f06610c806f0d1bb883f24645a6e")
      return "/ng/jsonmocks/url/md5/3/report.json";
    else if (md5 === "3cfb562bfdd9cbfefdddf1d9c7f4cba7")
      return "/ng/jsonmocks/url/md5/4/report.json";
    else if (md5 === "a7a3239980ab276f3f9e9dfa4df873b3")
      return "/ng/jsonmocks/url/md5/5/report.json";
    else if (md5 === "18074a4e9c27aed0609f8d054c981e88")
      return "/ng/jsonmocks/url/md5/6/report.json";
    else if (md5 === "error") return "/ng/jsonmocks/url/md5/7/report.json";
    return "/ng/jsonmocks/url/md5/1/report.json";
  }
  return BeaverServer() + "/url/" + md5 + "/json";
}

// e.g. https://beaver.ccirc.lab/url/b51e37992f4465d51d5f8e908ee7a0e2/report/fetchjson/title
export function URLReportJSONTitle(md5) {
  if (mock) {
    if (md5 === "e6de9bd28b04074523450145ef02b7aa")
      return "/ng/jsonmocks/url/md5/2/title.json";
    else if (md5 === "ad47f06610c806f0d1bb883f24645a6e")
      return "/ng/jsonmocks/url/md5/3/title.json";
    else if (md5 === "3cfb562bfdd9cbfefdddf1d9c7f4cba7")
      return "/ng/jsonmocks/url/md5/4/title.json";
    else if (md5 === "a7a3239980ab276f3f9e9dfa4df873b3")
      return "/ng/jsonmocks/url/md5/5/title.json";
    else if (md5 === "18074a4e9c27aed0609f8d054c981e88")
      return "/ng/jsonmocks/url/md5/6/title.json";
    else if (md5 === "error") return "/ng/jsonmocks/url/md5/7/title.json";
    return "/ng/jsonmocks/url/md5/1/title.json";
  }
  return BeaverServer() + "/url/" + md5 + "/report/fetchjson/title";
}

// e.g. https://beaver.ccirc.lab/url/b51e37992f4465d51d5f8e908ee7a0e2/report/fetchjson/favicon
export function URLReportJSONFavicon(md5) {
  if (mock) {
    if (md5 === "e6de9bd28b04074523450145ef02b7aa")
      return "/ng/jsonmocks/url/md5/2/favicon.json";
    else if (md5 === "ad47f06610c806f0d1bb883f24645a6e")
      return "/ng/jsonmocks/url/md5/3/favicon.json";
    else if (md5 === "3cfb562bfdd9cbfefdddf1d9c7f4cba7")
      return "/ng/jsonmocks/url/md5/4/favicon.json";
    else if (md5 === "a7a3239980ab276f3f9e9dfa4df873b3")
      return "/ng/jsonmocks/url/md5/5/favicon.json";
    else if (md5 === "18074a4e9c27aed0609f8d054c981e88")
      return "/ng/jsonmocks/url/md5/6/favicon.json";
    else if (md5 === "error") return "/ng/jsonmocks/url/md5/7/favicon.json";
    return "/ng/jsonmocks/url/md5/1/favicon.json";
  }
  return BeaverServer() + "/url/" + md5 + "/report/fetchjson/favicon";
}

export function URLReportJSONActualURL(md5) {
  if (mock) {
    return "/ng/jsonmocks/url/actualurl.json";
  }
  return BeaverServer() + "/url/" + md5 + "/report/fetchjson/actualurl";
}

// e.g. https://beaver.ino.u.azure.chimera.cyber.gc.ca/ng/logan/<eventId>/report
export function MISPLoganReport(eventid) {
  if (mock) {
    if (eventid === "820") return "/ng/jsonmocks/misp_logan/820.json";
    else if (eventid === "21956") return "/ng/jsonmocks/misp_logan/21956.json";
    else if (eventid === "14198") return "/ng/jsonmocks/misp_logan/14198.json";
    else if (eventid === "23660") return "/ng/jsonmocks/misp_logan/23660.json";
    else if (eventid === "23882") return "/ng/jsonmocks/misp_logan/23882.json";
    else if (eventid === "23797") return "/ng/jsonmocks/misp_logan/23797.json";
    return "/ng/jsonmocks/misp_logan/23916.json";
  }
  return BeaverServer() + "/logan/" + eventid + "/report";
}

// e.g. http://localhost:9001/<type>/<value>/json
export function TypeReportJSON(type: ReportType, value: string) {
  if (mock) return "/ng/jsonmocks/" + type + "/1/report.json";
  return BeaverServer() + "/" + type + "/" + value + "/json";
}

//e.g. https://beaver.ccirc.lab/<type>/<value>/report/fetch/BISON
export function TypeReportJSONSystem(type: ReportType, value: string, system) {
  if (mock)
    return "/ng/jsonmocks/" + type + "/1/report/fetch/" + system + ".json";
  return BeaverServer() + "/" + type + "/" + value + "/report/fetch/" + system;
}

// e.g. http://localhost:9001/passivednsipdomain/50.63.110.1/lodhia.com
export function PassiveDNSIPDomainJSON(ip: String, domain: String) {
  if (mock) return "/ng/jsonmocks/passivednsipdomain.json";
  return [BeaverServer(), "passivednsipdomain", ip, domain].join("/");
}

//e.g. http://localhost:9001/phishing_kit/cra/json (can repl. cra w/md5)
export function PhishingKitJSON(param) {
  if (mock) {
    if (param.length !== 32) {
      return "/ng/jsonmocks/phishing_kit/cluster.json";
    } else if (param === "025abe529ca54e0f504d44a808f956fc") {
      return "/ng/jsonmocks/phishing_kit/result2.json";
    }
    return "/ng/jsonmocks/phishing_kit/result.json";
  }
  return BeaverServer() + "/phishing_kit/" + param + "/json";
}

//e.g. http://localhost:9001/phishing_kit/json
export function PhishingKitListJSON() {
  if (mock) return "/ng/jsonmocks/phishing_kit/list.json";
  return BeaverServer() + "/phishing_kit/json";
}

// e.g. http://localhost:9001/yara/allRules
export function LiveHuntJSON() {
  if (mock) return "/ng/jsonmocks/live_hunt/liveHunt.json";
  return BeaverServer() + "/yara/allRules";
}

//e.g. http://localhost:9001/robin/rulesJson/40
export function LiveHuntRuleJSON(rule: string) {
  if (mock) return "/ng/jsonmocks//live_hunt/rule.json";
  return BeaverServer() + "/robin/rulesJson/" + rule;
}

//e.g. http://localhost:9001/spam/search/json
export function SpamPhishingJSON() {
  if (mock) return "/ng/jsonmocks/spam_phishing/search.json";
  return BeaverServer() + "/spam/search/json";
}

export function AddSpamCriteria() {
  if (mock)
    return {
      method: "get",
      url: "/ng/jsonmocks/spam_phishing/addcriteria.json",
    };
  return {
    method: "post",
    url: BeaverServer() + "/spam/search/list/add",
  };
}

export function AddToPriorityJSON(md5: string) {
  if (mock)
    return {
      method: "get",
      url: "/ng/jsonmocks/priority/addtopriority.json",
    };
  return {
    method: "post",
    url: BeaverServer() + "/md5/" + md5 + "/priority",
  };
}

export function AddToPriorityBulkJSON() {
  if (mock)
    return {
      method: "get",
      url: "/ng/jsonmocks/priority/addtoprioritybulk.json",
    };
  return {
    method: "post",
    url: BeaverServer() + "/priority/bulk",
  };
}

export function PreviewSample(md5) {
  return BeaverServer() + "/md5/" + md5 + "/preview";
}

export function DelSpamCriteria() {
  if (mock)
    return {
      method: "get",
      url: "/ng/jsonmocks/spam_phishing/delcriteria.json",
    };
  return {
    method: "post",
    url: BeaverServer() + "/spam/search/list/remove",
  };
}

//e.g. http://localhost:9001/taglist/json
export function TagListJSON() {
  if (mock) return "/ng/jsonmocks/tags/taglist.json";
  return BeaverServer() + "/taglist/json";
}

// TODO - this can be used in a Redux slice to show tag counts
// e.g. http://localhost:9001/tagmap/json
export function TagMapJSON() {
  if (mock) return "/ng/jsonmocks/tags/tagmap.json";
  return BeaverServer() + "/tagmap/json";
}

//e.g. http://localhost:9001/taglist/jsonArray
export function TagListJSONArray() {
  if (mock) return "/ng/jsonmocks/tags/taglistarray.json";
  return BeaverServer() + "/taglist/jsonArray";
}

// This returns both the generated and the user md5 list
//  (unlike Beaver in which you need to know if it is generated or user)
//e.g. http://localhost:9001/taglist/md5list/json/trickbot
export function TagJSON(family: string) {
  if (mock) {
    if (family === "trickbot") return "/ng/jsonmocks/tags/familygen2.json";
    else if (family === "fake") return "/ng/jsonmocks/tags/familygen.json";
    else if (family === "Bluebot") return "/ng/jsonmocks/tags/family2.json";
    else return "/ng/jsonmocks/tags/family.json";
  }
  return BeaverServer() + "/taglist/md5list/json/" + family;
}

//e.g. http://localhost:9001/priority/json
export function PriorityListAll() {
  if (mock) return "/ng/jsonmocks/priority/all.json";
  return BeaverServer() + "/priority/json";
}

//e.g. http://localhost:9001/priority/user/json/local.dev
export function PriorityListUser(user: string) {
  if (mock) return "/ng/jsonmocks/priority/user.json";
  return BeaverServer() + "/priority/user/json/" + user;
}

//e.g. http://localhost:9001/priority/human/json
export function PriorityListHuman() {
  if (mock) return "/ng/jsonmocks/priority/human.json";
  return BeaverServer() + "/priority/human/json";
}

//e.g. http://localhost:9001/priority/bot/json
export function PriorityListBot() {
  if (mock) return "/ng/jsonmocks/priority/bot.json";
  return BeaverServer() + "/priority/bot/json";
}

//e.g. http://localhost:9001/statsJson
export function SimpleStats() {
  if (mock) return "/ng/jsonmocks/stats.json";
  return BeaverServer() + "/statsJson";
}

//e.g. http://localhost:9001/sha1/6d5679ac48c5dd9b8a49d9ceb61fd88dddf64e8a/json (returns a md5 report)
export function SHA1ReportJSON(sha1: string) {
  if (mock) {
    return "/ng/jsonmocks/sha1/report.json";
  }
  return BeaverServer() + "/sha1/" + sha1 + "/json";
}

//e.g. http://localhost:9001/sha1/6d5679ac48c5dd9b8a49d9ceb61fd88dddf64e8a/quick (returns a md5 report)
export function SHA1ReportJSONQuick(sha1: string) {
  if (mock) {
    return "/ng/jsonmocks/sha1/report.json";
  }
  return BeaverServer() + "/sha1/" + sha1 + "/quick";
}

//e.g. http://localhost:9001/sha256/ca6a054154acb559a495cfea1923933a65c94a325834a3cf9f46d85d3adaacf5/quick (returns a md5 report)
export function SHA256ReportJSONQuick(sha256: string) {
  if (mock) {
    return "/ng/jsonmocks/sha256/report.json";
  }
  return BeaverServer() + "/sha256/" + sha256 + "/quick";
}

//e.g. http://localhost:9001/sha256/ca6a054154acb559a495cfea1923933a65c94a325834a3cf9f46d85d3adaacf5/json (returns a md5 report)
export function SHA256ReportJSON(sha256: string) {
  if (mock) {
    return "/ng/jsonmocks/sha256/report.json";
  }
  return BeaverServer() + "/sha256/" + sha256 + "/json";
}

//e.g. https://beaver.staging.ccirc.lab/upload/url/json
export function UploadedURLsJson() {
  if (mock) {
    return "/ng/jsonmocks/upload/url.json";
  }
  return BeaverServer() + "/upload/url/json";
}

//e.g. https://beaver.staging.ccirc.lab/upload/url/quick
export function UploadedURLsJsonQuick() {
  if (mock) {
    return "/ng/jsonmocks/upload/url.json";
  }
  return BeaverServer() + "/upload/url/quick";
}

//e.g. https://beaver.staging.ccirc.lab/upload/sample/json
export function UploadedSamplesJson() {
  if (mock) {
    return "/ng/jsonmocks/upload/sample.json";
  }
  return BeaverServer() + "/upload/sample/json";
}

export function PostUpload() {
  if (mock) {
    // TODO - mock the response
    return null;
  }
  return BeaverServer() + "/upload";
}

//e.g. http://localhost:9001/complianceJson
// NOTE:  you need the table headers from the sourceCompliance.scala.html - it's annoying but it's staic
export function ComplianceJSON() {
  if (mock) return "/ng/jsonmocks/compliance.json";
  return BeaverServer() + "/complianceJson";
}

// e.g. http://localhost:9001/search/973   - load a search result
export function RecentSearchJSON(searchId: number) {
  if (mock) return "/ng/jsonmocks/search/recentresult.json";
  return BeaverServer() + "/search/" + searchId + "/quick";
}

//e.g. http://localhost:9001/search/recent - show recent search list
export function RecentSearchesJSON() {
  if (mock) return "/ng/jsonmocks/search/recent.json";
  return BeaverServer() + "/search/recent";
}

// save's the search e.g. https://beaver.ccirc.lab/search/save
export function SaveSearchJSON() {
  if (mock) return { method: "get", url: "/ng/jsonmocks/search/save.json" };
  return { method: "post", url: BeaverServer() + "/search/save" };
}

// delete the SaveSearchJSON (no response) https://beaver.ccirc.lab/search/delete/979
export function DeleteSearchJSON(searchId: number) {
  if (mock) return "/ng/jsonmocks/search/delete.json";
  return BeaverServer() + "/search/delete/" + searchId;
}

// e.g. https://beaver.ccirc.lab/download/report/86385447ce35c120511647c1ade5cef9
export function MD5ReportSummarySourceDownloadReportLink(md5) {
  return BeaverServer() + "/download/report/" + md5;
}

// e.g. http://127.0.0.1:9000/download/sample/86385447ce35c120511647c1ade5cef9/20191025/ext
export function MD5ReportSummarySourceDownloadLink(md5, date, source) {
  return BeaverServer() + "/download/sample/" + md5 + "/" + date + "/" + source;
}

// e.g. http://127.0.0.1:9000/download/sample/fe6c0660c180c2003355b81fbf904a02
export function MD5ReportSummarySampleDownloadLink(md5) {
  return BeaverServer() + "/download/sample/" + md5;
}

//e.g. http://localhost:9001/url/clusters/json
export function URLMD5ClustersListJSON() {
  if (mock) return "/ng/jsonmocks/url/clusterslist.json";
  return BeaverServer() + "/url/clusters/json";
}

//e.g. http://localhost:9001/url/cpanelclusters/json
export function URLMD5CpanelClustersListJSON() {
  if (mock) return "/ng/jsonmocks/url/cpanelclusterlist.json";
  return BeaverServer() + "/url/cpanelclusters/json";
}

//e.g. http://localhost:9001/url/phishingclusters/json
export function URLMD5PhishingClustersListJSON() {
  if (mock) return "/ng/jsonmocks/url/phishingclusterlist.json";
  return BeaverServer() + "/url/phishingclusters/json";
}

//e.g. http://localhost:9001/url/clusters/json
export function URLMD5ClustersTagJSON(cluster, subcluster) {
  if (mock) return "/ng/jsonmocks/url/cluster.json";
  return (
    BeaverServer() +
    (subcluster
      ? `/url/clusters/${cluster}/${subcluster}/json`
      : `/url/clusters/${cluster}/json`)
  );
}

//e.g. http://localhost:9001/download/urlreport/<md5>
export function DownloadURLReport(md5: string): string {
  if (mock) return "";
  return `${BeaverServer()}/download/urlreport/${md5}`;
}

//e.g. http://localhost:9001/download/webpage/<md5>/<date>/<source>
export function DownloadRawURLReport(
  md5: string,
  date: string,
  source: string
): string {
  if (mock) return "";
  return `${BeaverServer()}/download/webpage/${md5}/${date}/${source}`;
}

//e.g. http://localhost:9001/download/phishing_kit/<md5>
export function DownloadPhishingKit(md5: string): string {
  if (mock) return "";
  return `${BeaverServer()}/download/phishing_kit/${md5}`;
}
