import { reportMessage } from "./reportMessage";

export interface bearModel {
  type: string;
  creationDate: string;
  md5: string;
  version: string;
  engineName: string;
  engineVersion: string;
  result: string;
  messages: reportMessage[];
  hhreport?: HallowHunterModel;
}

export interface HallowHunterModel {
  hollowsHunterVersion: string;
  peSieveVersion: string;
  processes: ProcessModel[];
  rawProcessReports: RawProcessReportModel[];
}

export interface RawProcessReportModel {
  hollowsHunterVersion: string;
  peSieveVersion: string;
  mainExecutablePath: string;
  pid: number;
  scanned: scanModel;
  drops: dropModel[];
}

export interface ProcessModel {
  mainExecutablePath: string;
  pid: number;
  scanned: scanModel;
  drops: dropModel[];
  // TODO find examples with data in notSuspiciousScans and errors and show them
  //  the same way as legacy does
  blacklisted: blacklistModel[];
}

export interface scanModel {
  total: number;
  skipped: number;
  modified: {
      total: number;
      hooked: number;
      replaced: number;
      detached: number;
      implanted: number;
      other: number;
  };
  errors: number;
}

export interface dropModel {
  md5: string;
  module: string;
  scans: { ep_modified: number; module: string; status: number }[];
}

export interface blacklistModel {
    md5: string;
    module: string;
    scans: {
        mapped_file?: string;
        module_file?: string;
        module: string;
        status: number;
    }[];
}

export enum BearErrorType {
  RELATED_SAMPLES_TIMEOUT = "RELATED_SAMPLES_TIMEOUT",
  DNS_QUERIES_TIMEOUT = "DNS_QUERIES_TIMEOUT",
  JA3_FINGERPRINTS_TIMEOUT = "JA3_FINGERPRINTS_TIMEOUT",
}

export function hasBearError(errors: any[], errorType: BearErrorType, analysisType?: string) {
  return errors && errors.length && errors.some(function (el) {
    return el.bt === errorType && el.at?.value === analysisType;
  });
}
