import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@material-ui/core";

import PageFullWidth from "../../../../../commons/components/layout/pages/PageFullWidth";
import PageHeader from "../../../../../commons/components/layout/pages/PageHeader";
import { useStyles } from "../common/sharedStyles";
import { ParsedError, parseErrorTitle } from "../common/ParsedError";
import { URLMD5ClustersTable } from "./views/URLMD5ClustersTable";
import { beaverNGSelector, fetchReport } from "slices/beaverng";
import { ReportType } from "components/beaver/models/report/reportType";

// TODO - handle when the response is {} (show that the task is still loading)

export function URLMD5ClusterList() {
  const { t } = useTranslation();

  const location = useLocation();
  const show = location.hash ? location.hash.substring(1).toLowerCase() : null;

  const [title, setTitle] = React.useState("URL MD5 Clusters");

  useEffect(() => {
    switch (show) {
      case "cpanel":
        setTitle("CPanel URL MD5 Clusters");
        break;
      case "phishing":
        setTitle("Phishing/Smishing Clusters");
        break;
    }
  }, [show, t]);

  return (
    <>
      <Helmet>
        <title>{["BEAVER", t(title)].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", t(title), "-", t("page.home.description")].join(
            " "
          )}
        />
      </Helmet>
      <PageHeader title={t(title)} />
      <PageFullWidth>
        <URLMD5ClustersListReport show={show} title={title} />
      </PageFullWidth>
    </>
  );
}

export type URLMD5ClustersListReportProps = {
  show?: string;
  title?: string;
  testing?: boolean;
};

export function URLMD5ClustersListReport({
  show,
  title,
  testing,
}: URLMD5ClustersListReportProps) {
  const [retry, setRetry] = useState(0);
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { responseData, reportType, error } = useSelector(beaverNGSelector);

  useEffect(() => {
    switch (show) {
      case "cpanel":
        dispatch(fetchReport(ReportType.CPANEL_URL_MD5_TAG_LIST));
        break;
      case "phishing":
        dispatch(fetchReport(ReportType.PHISHING_URL_MD5_TAG_LIST));
        break;
      default:
        dispatch(fetchReport(ReportType.URL_MD5_TAG_LIST));
    }
  }, [dispatch, retry, show]);

  if (
    !error &&
    (!responseData ||
      !reportType ||
      ![
        ReportType.URL_MD5_TAG_LIST,
        ReportType.CPANEL_URL_MD5_TAG_LIST,
        ReportType.PHISHING_URL_MD5_TAG_LIST,
      ].includes(reportType))
  ) {
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    return (
      <>
        <Box>
          <URLMD5ClustersTable
            testing={testing}
            title={title}
            clustersObj={responseData}
            show={show}
          />
        </Box>
      </>
    );
  }
}
