import React from "react";
import Axios, { Method } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { beaverNGSelector, fetchReport } from "../../../../../slices/beaverng";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import { ReportType } from "../../../models/report/reportType";

import { AddSpamCriteria } from "components/beaver/helpers/ServerProperties";
import { UserProfileProps } from "commons/components/layout/topnav/UserProfile";

export type AddSpamPhishingCriteriaProps = {
  setAdding: Function;
  currentUser: UserProfileProps;
};

export function AddSpamPhishingCriteria({
  setAdding,
  currentUser,
}: AddSpamPhishingCriteriaProps) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { responseData, reportType, error } = useSelector(beaverNGSelector);
  const [type, setType] = React.useState(0);
  const [attribution, setAttribution] = React.useState(0);
  const [criteria, setCriteria] = React.useState("");
  const [addResponse, setAddResponse] = React.useState(null);
  const [postError, setPostError] = React.useState(null);
  const [addAttribution, setAddAttribution] = React.useState(false);
  const [newAttribution, setNewAttribution] = React.useState(null);

  function doAdd() {
    let data = new URLSearchParams();
    data.append("type", Object.keys(responseData.attributions)[type]);
    data.append(
      "attribution",
      addAttribution && newAttribution && newAttribution.trim() !== ""
        ? newAttribution.trim()
        : responseData.attributions[
            Object.keys(responseData.attributions)[type]
          ][attribution]
    );
    criteria.split("\n").forEach((c) => data.append("criterias[]", c.trim()));

    Axios({
      method: AddSpamCriteria().method as Method,
      url: AddSpamCriteria().url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      data: data,
      auth:
        currentUser.server && !currentUser.server.includes("localhost")
          ? {
              username: currentUser ? currentUser.username : null,
              password: currentUser
                ? currentUser.onetime
                  ? window
                      // eslint-disable-next-line no-eval
                      .atob(eval(window.atob(currentUser.onetime)))
                      .substr(13)
                  : null
                : null,
            }
          : null,
    })
      .then((res) => {
        setAddResponse(res.data);
        // refresh table
        dispatch(fetchReport(ReportType.SPAM_PHISHING));
      })
      .catch((err) => {
        // show the post error if applicable
        console.log(err);
        setPostError(err.message);
      });
  }

  if (!error && responseData && reportType === ReportType.SPAM_PHISHING) {
    return (
      <Paper style={{ padding: 15 }}>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          {/*TODO - Refactor this (see AddToPriority) - only need one muialert, just set error or success as needed */}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={
              addResponse &&
              addResponse.success &&
              addResponse.success.length &&
              !(addResponse.error && addResponse.error.length)
            }
            autoHideDuration={6000}
            onClose={() => setAddResponse(null)}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={() => setAddResponse(null)}
              severity="success"
            >
              {addResponse && addResponse.success && addResponse.success.length}
              {" " + t("Criterias added") + ": "}
              {addResponse &&
                addResponse.success &&
                addResponse.success.map((s) => s.searchCriteria).join(", ")}
            </MuiAlert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={
              postError ||
              (addResponse && addResponse.error && addResponse.error.length)
            }
            autoHideDuration={6000}
            onClose={() => {
              setAddResponse({ ...addResponse, error: null });
              setPostError(null);
            }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={() => {
                setAddResponse({ ...addResponse, error: null });
                setPostError(null);
              }}
              severity="error"
            >
              {!!postError && t("An Error Occured") + ": '" + postError + "' "}
              {addResponse && addResponse.error && addResponse.error.length}
              {" " + t("Criterias could not be added") + ": "}
              {addResponse &&
                addResponse.error &&
                addResponse.error.map((s) => s.searchCriteria).join(", ")}
            </MuiAlert>
          </Snackbar>
          <Typography variant="subtitle1">{t("Add a new criteria")}</Typography>
          <FormControl style={{ marginTop: 15 }}>
            <InputLabel shrink>{t("Type")}</InputLabel>
            <Select value={type} onChange={(e) => setType(+e.target.value)}>
              {Object.keys(responseData.attributions).map((a, i) => (
                <MenuItem key={i} value={i}>
                  {a}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!addAttribution && (
            <FormControl style={{ marginTop: 15 }}>
              <InputLabel shrink>{t("Attribution")}</InputLabel>
              <Select
                value={attribution}
                onChange={(e) => setAttribution(+e.target.value)}
              >
                {responseData.attributions[
                  Object.keys(responseData.attributions)[type]
                ].map((a, i) => (
                  <MenuItem key={i} value={i}>
                    {a}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl style={{ marginTop: 10 }}>
            {!addAttribution && (
              <Button color="primary" onClick={() => setAddAttribution(true)}>
                {t("Add a new attribution")}
              </Button>
            )}
            {addAttribution && (
              <>
                <TextField
                  value={newAttribution}
                  onChange={(e) => setNewAttribution("" + e.target.value)}
                  label={t("New attribution")}
                />
                <Button
                  color="primary"
                  onClick={() => setAddAttribution(false)}
                >
                  {t("Choose existing attribution")}
                </Button>
              </>
            )}
          </FormControl>
          <FormControl style={{ marginTop: 15 }}>
            <TextField
              multiline
              rows={4}
              label={
                t("Criteria") +
                " (" +
                t("one per line") +
                ") " +
                t("regex must begin with an asterix (*)")
              }
              value={criteria}
              onChange={(e) => setCriteria("" + e.target.value)}
            />
          </FormControl>
          <FormControl style={{ marginTop: 15 }}>
            <TextField
              disabled
              label={t("Adder")}
              value={
                currentUser.email
                  ? currentUser.email
                  : currentUser.username
                  ? currentUser.username
                  : currentUser.name
              }
            />
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            disabled={
              !criteria ||
              (addAttribution &&
                (!newAttribution || newAttribution.trim() === ""))
            }
            style={{ marginTop: 15 }}
            onClick={() => doAdd()}
          >
            {t("Add")}
          </Button>
          {!criteria && (
            <Typography variant="caption" color="textSecondary">
              {t("Please enter criteria")}
            </Typography>
          )}
          {addAttribution && (!newAttribution || newAttribution.trim() === "") && (
            <Typography variant="caption" color="textSecondary">
              {t("Please enter an attribution")}
            </Typography>
          )}
          <Button color="primary" onClick={() => setAdding(false)}>
            {t("Cancel")}
          </Button>
        </Box>
      </Paper>
    );
  } else {
    return null;
  }
}
